import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store-context";
import {DealCampaign, DealStatus} from "../types";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';
import {Box, IconButton, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {
    Edit as EditIcon,
} from '@mui/icons-material';
import {useHistory} from "react-router-dom";
import {Spinner} from "react-bootstrap";

interface CampaignListProps {
    type: string;
}

export const DealsCampaignList = observer((props: CampaignListProps) => {
    const { push } = useHistory();
    const { dealsCampaignStore } = useStore();
    const [status, setStatus] = useState(DealStatus.DRAFT);
    const [data, setCampaigns] = useState(dealsCampaignStore.campaigns);
    const [isLoading, setIsLoading] = useState(true);
    const columnsWithoutBudgetInformation = useMemo<MRT_ColumnDef<DealCampaign>[]>(
            () => [
                {
                    accessorKey: 'id', //access nested data with dot notation
                    header: 'ID',
                    size: 150,
                },
                {
                    accessorKey: 'type',
                    header: 'Type',
                    size: 150,
                },
                {
                    accessorKey: 'startDate', //normal accessorKey
                    header: 'start',
                    size: 200,
                },
                {
                    accessorKey: 'endDate',
                    header: 'end',
                    size: 150,
                },
            ],
            [],
        );

    const columnsWithBudgetInformation = useMemo<MRT_ColumnDef<DealCampaign>[]>(
        () => [
            {
                accessorKey: 'id', //access nested data with dot notation
                header: 'ID',
                size: 100,
            },
            {
                accessorKey: 'type',
                header: 'Type',
                size: 100,
            },
            {
                accessorKey: 'startDate', //normal accessorKey
                header: 'start',
                size: 100,
            },
            {
                accessorKey: 'endDate',
                header: 'end',
                size: 100,
            },
            {
                accessorKey: 'budget.availableInCents',
                header: 'budget availableInCents',
                size: 100,
            },
            {
                accessorKey: 'budget.totalFundedInCents',
                header: 'budget totalFundedInCents',
                size: 100,
            },
            {
                accessorKey: 'budget.reservedInCents',
                header: 'budget reservedInCents',
                size: 100,
            },
            {
                accessorKey: 'budget.totalPaidOutInCents',
                header: 'budget totalPaidOutInCents',
                size: 100,
            },
        ],
        [],
    );

    const columns = status === 'published' ? columnsWithBudgetInformation : columnsWithoutBudgetInformation;

    const toViewCampaign = (id: string) => {
        if (status === DealStatus.PUBLISHED){
            push(`/deals-campaign-support/new-campaign/view/${id}`);
        }
        if(status === DealStatus.DRAFT){
            push(`/deals-campaign-support/new-campaign/${id}`);
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        if (event.target.value === DealStatus.DRAFT){
            loadCampaigns(true);
            setStatus(DealStatus.DRAFT);
        }else{
            loadCampaigns(false);
            setStatus(DealStatus.PUBLISHED);
        }
    };

    useEffect(() => {
       loadCampaigns(true);
    }, []);

   const loadCampaigns = (isDraft: boolean)  => {
       dealsCampaignStore.refreshCampaigns(isDraft)
            .then((campaigns) => {
                setCampaigns(dealsCampaignStore.campaigns);
                console.log('Fetched campaigns: ', campaigns);
            })
            .catch((e) => {
                console.error('Failed to fetch campaign: ', e);
                // history.push('/not-found');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: true,
        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        toViewCampaign(row.original.id);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Box>
        ),
    });


    return (
        <div>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Type"
                onChange={handleChange}
            >
                <MenuItem value={'draft'}>Draft</MenuItem>
                <MenuItem value={'published'}>Published</MenuItem>
            </Select>
            {isLoading ? ( <div><Spinner animation={"border"} size={"sm"} /> Loading...</div> ) : (
                <MaterialReactTable table={table} />
                )}
        </div>
    );
})
