import { HeaderContentFooterItem } from "../../header-content-footer-item";
import { PrimaryButton } from "../global-components";
import { FullScreenModal } from "../modal";
import { SaveState } from "../campaign-media-form";

export const renderModal = (
  saveState: { state: SaveState; message?: string | undefined; },
  refreshPage: () => void, resetModalRelatedProperties: () => void,
  selectedPropertyPath: { propertyPath: string; value: string; } | undefined
) => {
  switch (true) {
    case saveState.state === SaveState.pending: return <FullScreenModal>
      <HeaderContentFooterItem height="50vh" width="50vw"
        header={<div>Versturen en verwerken</div>}
        content={<div>
          De bestanden worden verwerkt <br />
          Dit kan 10 - 30 seconden duren
        </div>} />
    </FullScreenModal>;

    case saveState.state === SaveState.succeeded: return <FullScreenModal>
      <HeaderContentFooterItem height="50vh" width="50vw"
        header={<div>Succes</div>}
        content={<div>
          De bestanden zijn verwerkt <br />
          Wanneer je op sluiten en verversen klikt wordt de pagina ververst en de actuele gegevens worden geladen
        </div>}
        footer={<PrimaryButton onClick={refreshPage}>Sluiten en verversen</PrimaryButton>} />
    </FullScreenModal>;

    case saveState.state === SaveState.errored: return <FullScreenModal>
      <HeaderContentFooterItem height="50vh" width="50vw"
        header={<div>Er is iets misgegaan</div>}
        content={<div>
          Er is iets misgegaan met het verwerken. ({saveState.message}).
          Je kan het opnieuw proberen eventueel met een ander bestand.
        </div>}
        footer={<PrimaryButton onClick={resetModalRelatedProperties}>Sluiten</PrimaryButton>} />
    </FullScreenModal>;

    case selectedPropertyPath !== undefined: return <FullScreenModal onClick={resetModalRelatedProperties}>
      <HeaderContentFooterItem
        height="80vh"
        header={selectedPropertyPath && selectedPropertyPath!.propertyPath}
        content={<div style={{ textAlign: 'center', maxHeight: '60vh', maxWidth: '1060px', overflow: 'scroll', marginTop: '0.5em' }}>
          <img src={selectedPropertyPath && selectedPropertyPath!.value} alt={selectedPropertyPath && selectedPropertyPath!.propertyPath}></img>
        </div>}
        footer={selectedPropertyPath && selectedPropertyPath!.value} />
    </FullScreenModal>;

    default: return null;
  }
}