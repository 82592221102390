import { BreadCrumbNavigation } from "../components";
import { Row } from "../components/global/global-components";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { DealCampaign, DealType } from "../types";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useStore } from "../stores/store-context";
import { useHistory } from "react-router-dom";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { receiptSchema, tomraSchema, receiptUiSchema, tomraUiSchema } from "../schema/rjsf-schema";
import { widgets } from "../components/rjsf/widgets";
import { fields } from "../components/rjsf/fields";

export const NewDealsPage = observer(() => {
  const { dealsCampaignStore } = useStore();
  const history = useHistory();
  const [dealType, setType] = useState(DealType.TOMRA);
  const [formData, setFormData] = useState(null);
  const [schema, setSchema] = useState(tomraSchema);
  const [uiSchema, setUiSchema] = useState(tomraUiSchema);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === DealType.RECEIPT) {
      setType(event.target.value);
      setSchema(receiptSchema)
      setUiSchema(receiptUiSchema)
    }
    if (event.target.value === DealType.TOMRA) {
      setType(event.target.value);
      setSchema(tomraSchema)
      setUiSchema(tomraUiSchema)
    }
  };

  const storeCashback = async () => {
    if (formData !== null) {
      try {
        await dealsCampaignStore.saveCampaign(formData as DealCampaign);
        history.push(`/deals-campaign-support/`);
      } catch (e) {
        console.error(e);
      }
    }
  }

  return (
    <div className={"page"} style={{ position: 'relative' }}>
      <Row>
        <BreadCrumbNavigation crumbs={[{ label: 'Deals' }, { link: '/deals-campaign-support', label: 'Deals' }, { label: 'New' }]} />
      </Row>
      <InputLabel id="demo-simple-select-label">Campaign Type</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dealType}
        label="Type"
        onChange={handleChange}
      >
        <MenuItem value={DealType.RECEIPT}>Receipt</MenuItem>
        <MenuItem value={DealType.TOMRA}>Tomra</MenuItem>
      </Select>
      <Form
        schema={schema}
        validator={validator}
        uiSchema={uiSchema}
        widgets={widgets}
        fields={fields}
        onSubmit={storeCashback}
        onChange={(e) => {
          setFormData(e.formData);
        }}
      />
    </div>
  )
});
