import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
    CashbackCampaignType,
    DraftCashbackCampaign
} from "../../types/cashback-campaign";
import { ValidationError } from "joi";
import { DateTime } from "luxon";
import { FormMetaPart } from "./form/form-meta-part";
import { FormReceiptPart } from "./form/form-receipt-part";
import { FormTokenPart } from "./form/form-token-part";
import { FormDesignPart } from "./form/form-design-part";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { useStore } from "../../stores/store-context";
import { draftCampaignSchema } from "../../validation/validate-draft-cashback-campaign";
import {
    CashbackCampaignTypeSwitchComponent,
    Header2,
    PrimaryButton,
    Section,
    SectionContainer
} from "../global/global-components";
import { useHistory } from "react-router-dom";
import { SaveButtonIconPlus } from "../icons";

const CreateFormHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const CreateCashbackForm = observer(() => {
    const PAGE_TITLE = 'Nieuwe cashback campagne';

    const [validationErrors, setValidationErrors] = useState<ValidationError>();
    const [campaignData, setCampaignData] = useState<Partial<DraftCashbackCampaign>>({
        startDate: DateTime.local().toISODate(),
        endDate: DateTime.local().toISODate(),
        suspenseAccountCode: 'CB1',
        senderIban: 'NL30ABNA0811086666',
        remittanceText: 'Tikkie $ID',
        type: CashbackCampaignType.receipt,
        termsDescription: 'Als je meedoet aan deze actie, ga je akkoord met de actievoorwaarden.',
        termsUrl: 'https://',
        termsButtonText: 'Actievoorwaarden',
        faqButtonText: "Vragen & antwoorden",
        faqDescription: "Meer weten over hoe Tikkie cashbacks verwerkt?",
        faqUrl: "https://tikkie.me/vraag-en-antwoord/nl/",
        nextButtonText: 'Akkoord'
    });
    const [isSaving, setIsSaving] = useState(false);
    const { cashbackCampaignStore } = useStore();
    const history = useHistory();

    const validatePartialFormData = () => {
        const validationResults = draftCampaignSchema.validate({
            ...campaignData,
        }, { abortEarly: false });
        setValidationErrors(validationResults.error);
    }

    const updateCampaignData = (data: Partial<DraftCashbackCampaign>) => {
        const newData = { ...campaignData, ...data };
        setCampaignData(newData);
    }

    const setCampaignType = (type: CashbackCampaignType) => {
        updateCampaignData({
            type: type
        })
    }

    const renderTypeFormPart = () => {
        switch (campaignData.type) {
            case CashbackCampaignType.receipt:
                return <FormReceiptPart formData={campaignData} updateForm={updateCampaignData} validationErrors={validationErrors} />
            case CashbackCampaignType.token:
                return <FormTokenPart />
            default:
                return;
        }
    }

    const storeCashback = async () => {
        setIsSaving(true);
        try {
            const result = await cashbackCampaignStore.saveCampaign(campaignData as DraftCashbackCampaign);
            history.push(`/cashback-campaign-support/cashback-campaign/${result.id}`);
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(validatePartialFormData, [campaignData]);

    const renderSaveIcon = () => {
        if (isSaving) {
            return <Spinner animation="border" role="status" size={"sm"} style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />
        } else {
            return <SaveButtonIconPlus style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />
        }
    }

    const renderControls = () => {
        return (
            <div style={{ paddingTop: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: '20px' }}>
                <PrimaryButton disabled={isSaving || validationErrors !== undefined} onClick={storeCashback}>{renderSaveIcon()} Opslaan</PrimaryButton>
            </div>
        )
    }

    return (
        <div>
            <CreateFormHeader>
                <div>
                    <h2 className="page-title">{PAGE_TITLE}</h2>
                </div>
                {renderControls()}
            </CreateFormHeader>

            <FormMetaPart formData={campaignData} validationErrors={validationErrors} updateForm={updateCampaignData} />
            <Header2>Type</Header2>
            <SectionContainer>
                <Section>
                    <CashbackCampaignTypeSwitchComponent onChange={setCampaignType} />
                </Section>
            </SectionContainer>
            {renderTypeFormPart()}
            <FormDesignPart
                formData={campaignData}
                updateForm={updateCampaignData}
                validationErrors={validationErrors} />
            <div className={'float-right'}>
                {renderControls()}
            </div>
        </div>
    )
});
