import styled from "styled-components";

interface HeaderContentFooterItemProps {
  height: string;
  width?: string;
  header?: JSX.Element | JSX.Element[] | string
  content: JSX.Element | JSX.Element[] | string
  footer?: JSX.Element | JSX.Element[] | string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const Header = styled.div`
  flex-grow: 0;
  text-align: center;
  padding: 0.5em;
`;
const Footer = styled.div`
  flex-grow: 0;
  text-align: center;
  padding: 0.5em;
`;
const Content = styled.div`
  flex-basis: 1;
  text-align: center;
  padding: 0.5em;
`;



export const HeaderContentFooterItem = (props: HeaderContentFooterItemProps) => {
  const { height, header, content, footer, width } = props;
  return <Container style={{ height, width }}>
    {header !== undefined && <Header>{header}</Header>}
    <Content>{content}</Content>
    {footer !== undefined && <Footer>{footer}</Footer>}
  </Container>
}