import fetch, {Response} from 'node-fetch'
import {Configuration, DraftAdvertisingCampaign, Lookup} from '../types'

export class UnAuthorizedError extends Error {
    constructor(message: string, public status: number) {
        super(message)
    }
}

export const createAdvertisingApiService = (configuration: Configuration) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { bearerTokenKey, baseUrlAdvertisingService } = configuration

    const getHeaders = () => ({
        'Authorization': `Bearer ${localStorage.getItem(bearerTokenKey)}`,
    });

    const baseFetchOptions = () => ({ method: 'GET', headers: getHeaders() });

    const multipartFormFetchOptions = (formData: FormData) => ({
        ...baseFetchOptions(),
        method: 'POST',
        body: formData
    })

    const deleteFetchOptions = () => ({
        ...baseFetchOptions(),
        method: 'DELETE',
    });

    const postFetchOptions = (payload: { campaign: DraftAdvertisingCampaign, userToken: string }) => ({
        ...baseFetchOptions(),
        method: 'POST',
        body: JSON.stringify(payload),
    });

    const checkUnAuthorized = (response: Response) => {
        if (response.status === 403) {
            throw new UnAuthorizedError('UnAuthorized', response.status)
        }
        return response as Response;
    };

    return {
        getCampaigns: async () => {
            return fetch(`${baseUrlAdvertisingService}/campaigns`, baseFetchOptions())
                .then(checkUnAuthorized)
                .then(response => {
                    return response.json()
                })
        },
        getDraftCampaign: async (id: string) => {
            return fetch(`${baseUrlAdvertisingService}/campaigns/${id}`, baseFetchOptions())
                .then(checkUnAuthorized)
                .then(response => response.json())
        },

        postCampaign: async (campaign: DraftAdvertisingCampaign, images: Lookup<File>) => {

            const formData = new FormData();
            Object.keys(images).forEach(key => {
                formData.append(key, images[key]);
            })
            formData.append('campaign', JSON.stringify(campaign))
            const options = multipartFormFetchOptions(formData);
            return fetch(`${baseUrlAdvertisingService}/campaigns`, options as any)
                .then(checkUnAuthorized)
                .then(response => response.json())
        },

        deleteCampaign: async (campaign: DraftAdvertisingCampaign) => {
            return fetch(`${baseUrlAdvertisingService}/campaign/${campaign.campaignId}`, deleteFetchOptions())
                .then(response => response.json())
        },

        sendPreview: async (payload: { campaign: DraftAdvertisingCampaign, userToken: string }) => {
            return fetch(`${baseUrlAdvertisingService}/send-campaign-preview`, postFetchOptions(payload))
                .then(response => response.json())
        }
    };
}
