import { ReactNode } from "react";
import styled from "styled-components";

export const TabBox = styled.div`
  flex-direction: row;
  display: flex;
`;

interface StatusTabProps {
  active: boolean;
  width?: number;
  children?: ReactNode;
  onClick?: () => void
}

export const StatusTab = styled.a<StatusTabProps>`
  display: flex;
  cursor: pointer;
  width: ${(props) => props.width ? `${props.width}%` : ''};
  align-items: center;
  margin-right: 24px;
  margin-bottom: 24px;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  border-bottom: 3px solid rgba(0,0,0,0);
  color: ${(props) => props.active === true ? '#7076d2' : '#bebebf'};
  border-bottom-color: ${(props) => props.active === true ? '#7076d2' : 'rgba(0,0,0,0)'};
  color: ${(props) => props.active === true ? '#7076d2' : '#bebebf'};
  text-decoration: none;
  padding-bottom: 3px;
  :active,
  :focus,
  :hover {
    color: #7076d2;
    text-decoration: none;
    border-bottom: 3px solid #7076d2;
  }
`;