import React from "react";
import {BreadCrumbNavigation} from "../components";
import {Row} from "../components/global/global-components";
import {observer} from "mobx-react-lite";
import {EditCashbackForm} from "../components/cashback-campaign/edit-cashback-form";
import { useParams } from "react-router-dom";
export const EditCashbackPage = observer(() => {
    const { campaignId } = useParams<{ campaignId: string }>()
    return (
        <div className={"page"}>
            <Row>
                <BreadCrumbNavigation crumbs={[{ label: 'Campagnes' }, { link: '/cashback-campaign-support', label: 'Cashbacks' }, { label: campaignId }]} />
            </Row>

            <EditCashbackForm cashbackCampaignId={campaignId}/>
        </div>
    )
});
