import styled from 'styled-components';
import { BaseModalBackground } from "styled-react-modal";

export * from './sections';
export * from './forms';

export const ClearTextButton = styled.button`
  border: 0px;
  background-color: transparent;
  color: black;
`

export const Button = styled.button`
  height: 40px;
  width: auto;
  padding: 0px 24px 0px 12px;
  text-align: center;
  border-radius: 50px;
  border: solid 2px #40c4b8;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: white;
  background-color: #40c4b8;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.5);

  :disabled {
    opacity: 0.2;
    border: 2px solid transparent;
    box-shadow: none;
    cursor: default;
  }

  :hover:enabled {
    box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.8);
  }
`

export const PrimaryButton = styled(Button)`
  color: white;
  background-color: #40c4b8;
  border: solid 2px #40c4b8;
`;

export const WarningButton = styled(Button)`
  color: white;
  background-color: #F1AB47;
  border: solid 2px #F1AB47;

  box-shadow: 2px 2px 14px 0 rgba(241, 171, 71, 0.5);

  :hover:enabled {
    box-shadow: 2px 2px 14px 0 rgba(241, 171, 71, 0.8);
  }
`;

// styles above are used
// styles below are candidates for re-use

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const Title = styled.div`
  margin-top: 40px;
  font-family: MuseoSansRounded700;
  font-size: 30px;
  color: #57565b;
`;

export const CaptionSmall = styled.span`
  font-family: MuseoSansRounded700;
  font-size: 14px;
  color: #bebebf;
`;

interface WithMarginProps { margin?: string }
interface WithWidthProps { width: string }

export const Header2 = styled.div<WithMarginProps>`
  && {
    font-family: MuseoSansRounded700;
    font-size: 22px;
    color: #57565b;
    margin: ${props => (props.margin ? props.margin : '50px auto 16px auto')};
  }
`;

export const Header3 = styled.div<WithMarginProps>`
  && {
    font-family: MuseoSansRounded700;
    font-size: 18px;
    color: #57565b;
    margin: ${props => (props.margin ? props.margin : '30px auto 16px auto')};
  }
`;

export const HorizontalLine = styled.div<WithMarginProps>`
  border: 1px solid #D5D5D5;
  border-bottom: 0;
  margin: ${props => (props.margin ? props.margin : '24px 0')};
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
`;

interface WithColorProps { customColor?: string }
export const SubTitle = styled.div<WithColorProps>`
  font-size: 18px;
  line-height: 36px;
  color: ${props => props.customColor ? props.customColor : "#39A4A1"};
`;

export const Count = styled.div`
  font-family: MuseoSansRounded700;
  font-size: 12px;
  border-radius: 50px;
  padding: 2px 8px;
  background-color: #7076d2;
  color: #ffffff;
  margin-left: 8px;
`;

export const OrganisationItem = styled.div`
  height: 72px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
  justify-content: 'space-between';
`;

export const InputBlock = styled(Row) <WithWidthProps>`
  align-items: center;
  width: ${props => (props.width ? `${props.width}%` : '')};
`;

export const Column = styled.div`
  display: inline-block;
  margin-bottom: 8px;
  max-height: 200px;
  flex-grow: 1;
`;

interface ExtendableTextProps { extended: boolean }

export const Text = styled.div<ExtendableTextProps>`
  font-family: MuseoSansRounded700;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bebebf;
  align-self: flex-start;
  width: ${props => (props.extended ? '340px' : '160px;')};
  min-width: 160px;
`;

export const TextLink = styled.div`
  font-family: MuseoSansRounded700;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #6ab4ac;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 16px;
`;

export const ActiveLink = styled.div`
  font-family: MuseoSansRounded700;
  font-size: 16px;
  margin-right: 16px;
  color: #7076d2;
  :active,
  :focus,
  :hover {
    border-bottom: 3px solid #7076d2;
  }
`;

export const DeleteImageIconContainer = styled.div`
 position: 'absolute' ;
 background-color: '#FC9' !important;
 width: '2em' !important;
 height: '2em' !important;
 background-color: '#F9C' !important;
 bottom: '32px' !important;
 right: '36px' !important;
`;

// re-enable later:

// const ContentWrapper = styled.div`
//   border-radius: 8px;
//   margin: ${props => (props.margin ? `${props.margin}px` : '32px')};
//   background-color: #f5f6fa;
//   display: flex;
//   padding: 16px;
//   flex-direction: column;
//   align-items: center;
//   min-width: 650px;
//   min-height: 500px;
// `;

// const Image = styled.img`
//   max-height: 500px;
//   max-width: 650px;
//   margin-top: 8px;
// `;

export const Error = styled.div`
  background-color: #ed5565;
  color: #ffffff;
  font-size: 14px;
  font-family: MuseoSansRounded700;
  padding: 16px 32px;
  border-radius: 8px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;

export const SpecialModalBackground = styled(BaseModalBackground)`
  z-index: 325;
`
