import { AdvertisingCampaignType } from '../../types';

import { ReactComponent as IconCampaignTypeApp } from '../../images/icon-campaign-type-app.svg';
import { ReactComponent as IconCampaignTypeWeb } from '../../images/icon-campaign-type-web.svg';
import React from "react";

export { ReactComponent as ButtonIconPlus } from '../../images/button-icon-plus.svg';
export { ReactComponent as SaveButtonIconPlus } from '../../images/save-icon.svg';
export { ReactComponent as ImageButtonIcon } from '../../images/icon-image.svg';
export { ReactComponent as PublishButtonIconPlus } from '../../images/publish-icon.svg';
export { ReactComponent as TrashIcon } from '../../images/icon-trash.svg';
export { ReactComponent as TrashIconWithoutBorder } from '../../images/icon-trash-without-border.svg';
export { ReactComponent as GotoIcon } from '../../images/goto.svg';
export { ReactComponent as EditIconV2 } from '../../images/icon-edit-v2.svg';
export { ReactComponent as HorizontalRuleLeftActive } from "../../images/horizontal-rule-left-active.svg";
export { ReactComponent as HorizontalRuleRightActive } from "../../images/horizontal-rule-right-active.svg";

interface IconCampaignTypeProps { type: AdvertisingCampaignType }
export const IconCampaignType = ({ type }: IconCampaignTypeProps) => type === 'app' ? <IconCampaignTypeApp /> : <IconCampaignTypeWeb />;
