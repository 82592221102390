import {action, makeObservable, observable} from "mobx";
import {RootStore} from "./root-store";

export class UserStore {

    @observable campaignRoles: string[] = [];
    constructor(private rootStore: RootStore) {
        makeObservable(this);
    }

    @action setCampaignRoles(campaignRoles: string[]) {
        this.campaignRoles = campaignRoles;
    }

    @action clear() {
        this.campaignRoles = [];
    }
}
