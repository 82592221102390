import { BreadCrumbNavigation, Show } from "../components";
import {
  ClearTextButton, InputBoxBackgroundImage,
  NumberInputBox,
  PrimaryButton,
  Row,
  WarningButton
} from "../components/global/global-components";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { DealCampaign } from "../types";
import { useStore } from "../stores/store-context";
// @ts-ignore
import { Spinner } from "react-bootstrap";
import { FullScreenModal } from "../components/global/modal";
import { ModalCampaignTitle, ModalControls, ModalText } from "../components/modal/modal";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { receiptSchema, receiptUiSchema, tomraSchema, tomraUiSchema } from "../schema/rjsf-schema";
import { widgets } from "../components/rjsf/widgets";
import {PublishButtonIconPlus} from "../components/icons";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";

export const ViewPublishedPage = observer(() => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { dealsCampaignStore, userStore } = useStore();
  const [campaign, setCampaign] = useState({} as DealCampaign);
  const [isLoading, setIsLoading] = useState(true);
  const [showUpdateBudgetConfirmation, setShowUpdateBudgetModal] = useState(false);
  const [isUpdatingBudget, setIsUpdatingBudget] = useState(false);
  const [amountInCents, setAmountInCents] = useState(0);
  const [schema, setSchema] = useState(tomraSchema);
  const [uiSchema, setUiSchema] = useState(tomraUiSchema);
  const isApprover = userStore.campaignRoles.includes('approver');
  const supportUserId = localStorage.getItem("email");
  const [isRevalidating, setIsRevalidating] = useState(false);
  const [showRevalidationBasedOnCampaignStartDate, setShowRevalidationBasedOnCampaignStartDate] = React.useState(false);
  const [showRevalidationConfirmation, setShowRevalidation] = useState(false);
  const today = new Date();
  const todayDate = today.toISOString().substring(0, 10);
  const [selectedDate, setSelectedDate] = useState(todayDate);

  useEffect(() => {
    dealsCampaignStore.getPublishedCampaign(campaignId)
      .then((campaign) => {
        if (campaign === undefined) {
          throw new Error('something errored');
        }
        if (campaign.type === 'receipt') {
          setSchema(receiptSchema);
          setUiSchema(receiptUiSchema)
        }

        if (campaign.type === 'tomra') {
          setSchema(tomraSchema);
          setUiSchema(tomraUiSchema)
        }

        setCampaign(campaign);
        setShowRevalidationBasedOnCampaignStartDate(showRevalidationButton(campaign));

      })
      .catch((e) => {
        console.error('Failed to fetch campaign: ', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [])

  const renderUpdateBudgetModal = () => {
    if (showUpdateBudgetConfirmation) {
      return (
        <FullScreenModal>
          <ModalText><ModalCampaignTitle>{campaign.campaignData.campaignName}</ModalCampaignTitle> Budget update</ModalText>

          <Show when={!isUpdatingBudget}>

            Amount in cents :

            <NumberInputBox customStyle={{ backgroundPosition: "center left 7px", paddingLeft: "55px", flexGrow: 1 }}
              backgroundImage={InputBoxBackgroundImage.euroBlueSquareBackgroundImage}
              value={amountInCents}
              onChange={newAmount => {
                const amountToBeUpdated = newAmount === undefined ? 0 : newAmount
                setAmountInCents(amountToBeUpdated);
              }}
            />
            <ModalControls>
              <WarningButton onClick={() => updateBudget()}><span style={{ paddingLeft: '12px' }}>Update</span></WarningButton>
              <ClearTextButton onClick={() => { setShowUpdateBudgetModal(false) }}>Cancel</ClearTextButton>
            </ModalControls>
          </Show>
          <Show when={isUpdatingBudget}>
            <div>updating....</div>
          </Show>
        </FullScreenModal>
      )
    }
  }

  const showUpdateBudgetModal = () => {
    setShowUpdateBudgetModal(true);
  }

  const returnBudgetInEuro =  (amount:number | undefined ): string => {
      return((amount ?? 0) / 100).toFixed(2);
  }

  const updateBudget = () => {
    setIsUpdatingBudget(true);
    dealsCampaignStore.updateBudget(campaign.id, amountInCents)
      .then((response) => {
      })
      .catch((error) => console.error(`error`, error)
      )
      .finally(() => {
        setShowUpdateBudgetModal(false);
        setIsUpdatingBudget(false)
      });
  }

  const handleSelectedDateChange = (event: SelectChangeEvent) => {
        setSelectedDate(event.target.value);
  };

  const showRevalidationButton = (campaign:DealCampaign) => {
      const campaignStartDate = new Date(campaign.startDate);
      const isTodayWithinTheFirstThreeDaysOfTheCampaign = +today <= campaignStartDate.setDate(campaignStartDate.getDate() + 3);
      return isTodayWithinTheFirstThreeDaysOfTheCampaign;
  }

  const renderReValidateRedemptions = () => {
        if (showRevalidationConfirmation) {
            const oneDayAgo = new Date(today.setDate(today.getDate() - 1)).toISOString().substring(0, 10);
            const twoDaysAgo = new Date(today.setDate(today.getDate() - 1)).toISOString().substring(0, 10);

            return (
                <FullScreenModal>
                    <ModalText><ModalCampaignTitle>{campaign.campaignData.campaignName}</ModalCampaignTitle>
                        <br/>This will re-validate the redemptions for the selected date</ModalText>
                    <Show when={!isRevalidating }>
                        <ModalControls>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDate}
                                label="Date"
                                onChange={handleSelectedDateChange}
                            >
                                <MenuItem value={twoDaysAgo}>{twoDaysAgo}</MenuItem>
                                <MenuItem value={oneDayAgo}>{oneDayAgo}</MenuItem>
                                <MenuItem value={todayDate}>{todayDate}</MenuItem>
                            </Select>
                            <WarningButton onClick={() => revalidateRedemptions()}><span style={{ paddingLeft: '12px' }}>Publish</span></WarningButton>
                            <ClearTextButton onClick={() => { setShowRevalidation(false) }}>Cancel</ClearTextButton>
                        </ModalControls>
                    </Show>
                    <Show when={isRevalidating}>
                        <div>In progress</div>
                    </Show>
                </FullScreenModal>
            )
        }
    }
    const showRevalidationModal = () => {
        setShowRevalidation(true);
    }

    const revalidateRedemptions = () => {
        setIsRevalidating(true);
        dealsCampaignStore.reValidateRedemptions(campaign.id, selectedDate, supportUserId ?? 'unknown')
            .then((response) => {
                console.log(`revalidated`, response);
            })
            .catch((error) => console.log(`error`, error)
            )
            .finally(() => {
                setShowRevalidation(false)
                setIsRevalidating(false);
                console.log(`publishing done`)
            });
    }


    return (
    <div className={"page"} style={{ position: 'relative' }}>
      <Row>
        <BreadCrumbNavigation crumbs={[{ label: 'Deals' }, { link: '/deals-campaign-support', label: 'Deals' }, { label: 'New' }]} />
      </Row>

      {isLoading ? (<div><Spinner animation={"border"} size={"sm"} /> Loading...</div>) : (
          <>
          <div style={{padding: '20px', color: '#dc3545'}}>Totaal budget:
              €{returnBudgetInEuro(campaign.budget?.totalFundedInCents)}</div>
          <div style={{padding: '20px', color: '#dc3545'}}>Gereserveerd budget:
              €{returnBudgetInEuro(campaign.budget?.reservedInCents)}</div>
          <div style={{padding: '20px', color: '#dc3545'}}>Uitbetaald:
              €{returnBudgetInEuro(campaign.budget?.totalPaidOutInCents)}</div>
          <div style={{padding: '20px', color: '#dc3545'}}>Beschikbaar budget:
              €{returnBudgetInEuro(campaign.budget?.availableInCents)}</div>


          <PrimaryButton  disabled={!isApprover} onClick={showUpdateBudgetModal}> Add budget</PrimaryButton>
              <WarningButton disabled={!isApprover || !showRevalidationBasedOnCampaignStartDate || campaign.type!=='receipt'}
                             style={{marginRight: '16px'}}
                             onClick={showRevalidationModal}>
                  {<PublishButtonIconPlus style={{
                      verticalAlign: 'text-bottom',
                      marginRight: '8px'
                  }}/>} Revalidate
              </WarningButton>

              <Form
              formData={campaign}
              readonly
              widgets={widgets}
              uiSchema={uiSchema}
              schema={schema}
              validator={validator}
          />
          <div className={'float-right'}>
              {renderUpdateBudgetModal()}
          </div>
          <div className={'float-right'}>
              {renderReValidateRedemptions()}
          </div>
          </>
          )}
          </div>
      )
      });
