import React, { CSSProperties } from "react";
import { Lookup } from "../../types";
import { TrashIcon } from "../icons";

interface DeleteImageIconProps {
  images: Lookup<File>;
  property: string;
  onlineUrl?: string;
  disabled?: boolean;
  removeImage: (property: string) => void;
}

const deleteImageStyle: CSSProperties = {
  position: 'absolute',
  width: '46px',
  height: '46px',
  bottom: '32px',
  right: '36px',
}


export const DeleteImageIcon = (props: DeleteImageIconProps) => {
  const { images, property, onlineUrl, disabled, removeImage } = props;
  const hasImage = (onlineUrl !== undefined && onlineUrl !== '')
    || images[property] !== undefined;
  return hasImage && (disabled !== true)
    ? <span style={deleteImageStyle} onClick={() => removeImage(property)}><TrashIcon /></span>
    : null;
}
