import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store-context";
import {
    CashbackCampaignListHeader,
    CashbackCampaignListItem,
    CashbackCampaignListItemDates, CashbackCampaignListItemIcons,
    CashbackCampaignListItemName, CashbackCampaignListItemStatus,
    CashbackCampaignListTypeItem, SortButtons
} from './cashback-campaign-list.styles';
import { useHistory } from "react-router-dom";
import { CashbackCampaign, SortableTypesCashbackCampaign } from "../types/cashback-campaign";
import { EditIconV2, GotoIcon, TrashIconWithoutBorder } from "./icons";
import { CashbackCampaignStatus } from "./global/forms";
import { getCampaignTypeDescription } from "../services/cashback-campaign-service";

interface CampaignListProps {
    type: string;
}

export const CashbackCampaignList = observer((props: CampaignListProps) => {
    const { cashbackCampaignStore } = useStore();
    const { push } = useHistory();

    const toEditCampaign = (campaignId: string) => () => push(`cashback-campaign-support/cashback-campaign/${campaignId}`);
    const setSortField = (field: SortableTypesCashbackCampaign) => () => cashbackCampaignStore.setSortField(field);
    const setSortAscending = (sortAscending: boolean) => cashbackCampaignStore.setSortAscending(sortAscending);

    const toCampaignListItem = (campaign: CashbackCampaign) => {

        return <CashbackCampaignListItem key={campaign.id} onClick={toEditCampaign(campaign.id)} >
            <CashbackCampaignListItemName>
                <div className="campaign-list-item-name">{campaign.id}</div>
            </CashbackCampaignListItemName>
            <CashbackCampaignListTypeItem>
                <div className="campaign-list-item-name">{getCampaignTypeDescription(campaign.type)}</div>
            </CashbackCampaignListTypeItem>
            <CashbackCampaignListItemDates>
                <div className="campaign-list-item-name">{campaign.startDate} <span className="campaign-list-item-dot">•</span> {campaign.endDate}</div>
            </CashbackCampaignListItemDates>
            <CashbackCampaignListItemStatus>
                <CashbackCampaignStatus>Concept</CashbackCampaignStatus>
            </CashbackCampaignListItemStatus>
            <CashbackCampaignListItemIcons>
                <GotoIcon /> <EditIconV2 /> <TrashIconWithoutBorder />
            </CashbackCampaignListItemIcons>
        </CashbackCampaignListItem>
    }

    return <div>
        <CashbackCampaignListHeader>
            <div className="campaign-list-header-campaign-id">Campagne</div>
            <div className="campaign-list-header-type">Type<SortButtons setSortAscending={setSortAscending} setSortField={setSortField("type")} /></div>
            <div className="campaign-list-header-duration">Looptijd<SortButtons setSortAscending={setSortAscending} setSortField={setSortField("startDate")} /></div>
            <div className="campaign-list-header-status">Status<SortButtons setSortAscending={setSortAscending} setSortField={setSortField("status")} /> </div>
            <div />
        </CashbackCampaignListHeader>
        {
            cashbackCampaignStore.sortedCampaigns.map(toCampaignListItem)
        }
    </div>

})
