import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store-context";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';
import {Box, IconButton, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {
    Edit as EditIcon,
} from '@mui/icons-material';
import {useHistory} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {InputBox} from "./global/forms";
import {Redemption} from "../types/redemption-requets";
import {Column, PrimaryButton, Row} from "./global/global-components";

export const RedemptionRequestsList = observer(() => {
    const {push} = useHistory();
    const {redemptionRequestStore } = useStore();
    const [searchTerm, setSearchTerm] = useState('phone');
    const [searchValue, setSearchValue] = useState('');
    const [data, setRedemptions] = useState(redemptionRequestStore.redemptionRequests);
    const [isLoading, setIsLoading] = useState(false);

    const columns = useMemo<MRT_ColumnDef<Redemption>[]>(
        () => [
            {
                accessorKey: 'id', //access nested data with dot notation
                header: 'ID',
                size: 100,
            },
            {
                accessorKey: 'campaignId', //access nested data with dot notation
                header: 'campaign',
                size: 100,
            },
            {
                accessorKey: 'status', //access nested data with dot notation
                header: 'Status',
                size: 100,
            },
            {
                accessorKey: 'lastEventDate', //access nested data with dot notation
                header: 'date',
                size: 100,
            },
            {
                accessorKey: 'receiver.telephone', //access nested data with dot notation
                header: 'phone',
                size: 100,
            },
            {
                accessorKey: 'receiver.iban', //access nested data with dot notation
                header: 'iban',
                size: 100,
            },

        ],
        [],
    );


    const toView = (id: string) => {
        push(`/redemption-requests/view/${id}`);
    }

    const handleSearchTermChange = (event: SelectChangeEvent) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
    }, []);

   const loadData = ()  => {
       redemptionRequestStore.getRedemptionRequests(searchTerm, searchValue)
            .then((redemptions) => {
                setRedemptions(redemptionRequestStore.redemptionRequests);
                console.log('Fetched data: ', redemptions);
            })
            .catch((e) => {
                console.error('Failed to fetch campaign: ', e);
                // history.push('/not-found');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: true,
        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        toView(row.original.id);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Box>
        ),
    });


    return (
        <div>
            <Row style={{marginBottom: '40px'}}>
                <Column style={{textAlign: 'left', maxWidth: '150px'}}>
                    <Select
                        value={searchTerm}
                        label="by"
                        onChange={handleSearchTermChange}
                    >
                        <MenuItem value={'phone'}>Phone</MenuItem>
                        <MenuItem value={'iban'}>Iban</MenuItem>
                        <MenuItem value={'campaign'}>Campaign</MenuItem>
                        <MenuItem value={'id'}>Id</MenuItem>
                    </Select>
                </Column>
                <Column style={{textAlign: 'left', paddingRight: '50px'}}>
                    <InputBox value={searchValue} onChange={newValue => {
                        setSearchValue(newValue);
                    }}/>
                </Column>
                <Column style={{textAlign: 'left'}}>
                    <PrimaryButton onClick={loadData}>Search</PrimaryButton>
                </Column>
            </Row>

            {isLoading ? (<div><Spinner animation={"border"} size={"sm"}/> Loading...</div>) : (
                <MaterialReactTable table={table}/>
            )}
        </div>
    );
})
