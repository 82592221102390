import React from "react"
import { CenteredSection } from "./centered-section";
import styled from "styled-components";

interface ModalProps {
    children: React.ReactNode,
    onClick?: () => void,
}

export const FullScreenModal = (props: ModalProps) => {
    const { onClick } = props;
    const ViewHeightDiv = styled.div`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `

    const OverlayDiv = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);
        transition-property: background-color;
        transition-duration: 2s;
    `;

    return (
        <OverlayDiv onClick={() => onClick ? onClick() : () => void null} >
            <ViewHeightDiv>
                <CenteredSection>
                    {props.children}
                </CenteredSection>
            </ViewHeightDiv>
        </OverlayDiv>
    )
}