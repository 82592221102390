import { CampaignStatus } from "./campaign-status";

export enum AdvertisingCardSize {
    small = 'small',
    default = 'default',
}
export type IsoDateString = string;

export enum AdvertisingCampaignType {
    app = 'app',
    web = 'web'
}
export enum AdvertisingCampaignCreationType {
    single = 'single',
    campaignSet = 'campaign-set',
}

export interface DraftAdvertisingCampaign {
    campaignId: string;
    campaignName: string;
    brandName: string;
    active: boolean;
    campaignType: AdvertisingCampaignType;
    startDate: IsoDateString;
    status: CampaignStatus;
    endDate: IsoDateString;
    backgroundColor?: string;
    clickOutUrl?: string;
    imageUrl?: ImageUrl;
    logoUrl?: string;
    textColor?: string;
    language?: {
        en?: LanguageOptions;
        nl?: LanguageOptions;
    };
    cardSize?: AdvertisingCardSize,
    imageBackgroundColor?: string;
    imagePlaceholder?: string;
    creationType?: AdvertisingCampaignCreationType;
    cohort?: string;
    impressions?: CampaignImpressions;
    imageUrlNL?: string;
    imageUrlEN?: string;
    gifUrl?: string;
    campaignContent?: Object;
}

export interface AdvertisingCampaign {
    campaignId: string;
    campaignName: string;
    brandName: string;
    active: boolean;
    campaignType: AdvertisingCampaignType;
    startDate: IsoDateString;
    endDate: IsoDateString;
    backgroundColor: string;
    clickOutUrl: string;
    imageUrl: ImageUrl;
    logoUrl?: string;
    textColor: string;
    language: {
        en: LanguageOptions;
        nl: LanguageOptions;
    };
    cardSize?: AdvertisingCardSize,
    imageBackgroundColor?: string;
    imagePlaceholder?: string;
    status?: CampaignStatus;
    creationType?: AdvertisingCampaignCreationType;
    cohort?: string;
    impressions?: CampaignImpressions;
    imageUrlNL?: string;
    imageUrlEN?: string;
    gifUrl?: string;
    campaignContent?: Object;
}

interface ImageUrl {
    large: string;
    medium: string;
    small: string;
}

interface LanguageOptions {
    title: string;
    subTitle?: string;
    button?: string;
    imageUrl?: ImageUrl;
}

interface CampaignImpressions {
    maximumTotalImpressions?: number;
    maximumUniqueImpressions?: number;
}
