import { observer } from 'mobx-react-lite';

import React from 'react';
import {CashbackCampaignList, Show} from '../components';
import { useStore } from '../stores/store-context';
import {Column, InputBox, InputBoxBackgroundImage, PrimaryButton, Row} from '../components/global/global-components';
import { useHistory } from 'react-router-dom';
import {ButtonIconPlus} from '../components/icons';

export const CashbackOverviewPage = observer(() => {
    const type = 'cashback-campaign';
    const { cashbackCampaignStore } = useStore();
    const { push } = useHistory();

    const toNewCampaign = () => {
        push('cashback-campaign-support/cashback-campaign');
    }

    cashbackCampaignStore.refreshCampaigns();

    return <div className="page">
        <h2 className="pageLabel">Cashbacks</h2>
        <Row style={{marginBottom: '40px'}}>
            <Column style={{textAlign: 'left'}}>
                <InputBox
                    disabled={false}
                    placeholder="Zoeken op cashbacks"
                    value={cashbackCampaignStore.campaignIdFilterString}
                    onChange={value => cashbackCampaignStore.setCampaignIdFilterString(value)}
                    backgroundImage={InputBoxBackgroundImage.search}
                    customStyle={{borderRadius: '50px'}}
                />
            </Column>
            <Column style={{ textAlign: 'right' }}>
                <PrimaryButton onClick={toNewCampaign}><ButtonIconPlus style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />Nieuw</PrimaryButton>
            </Column>
        </Row>
        <CashbackCampaignList type={type} />
        <Show when={cashbackCampaignStore.apiServiceError !== undefined}>
            {cashbackCampaignStore.apiServiceError}
        </Show>
    </div>
});
