import styled from "styled-components";
import { ValidationErrorItem } from "joi";
import { WarningButton } from "../../global/global-components";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { PublishButtonIconPlus } from "../../icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { DraftCashbackCampaign } from "../../../types/cashback-campaign";
import { campaignSchema } from "../../../validation/validate-cashback-campaign";

const PublishModule = styled.div`
    .module {
        padding: 1em;
        border-radius 6px;
        margin-bottom: 1em;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .module ul {
        margin-top: 0.5em;
        list-style: none;
        padding-left: 1em;
    }

    .module-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
    }

    .warning {
        background-color: #FDF8E4;
        border: 1px solid #F6F4CC;
    }

    .success {
        background-color: #DEF0D8;
        border: 1px solid #D4E8CA;
    }
    .module .messages {
        flex-grow: 1;
    }
    .module .publish-button {
        flex-grow: 0;
        text-align: right;
    }
`;

interface PublishRequirementsProps {
    cashbackCampaign: DraftCashbackCampaign,
    onClickPublish: () => void;
    isPublishing: boolean;
    formIsDirty: boolean;
}

export const PublishRequirements = ({ cashbackCampaign, onClickPublish, isPublishing, formIsDirty }: PublishRequirementsProps) => {

    const [errors, setErrors] = useState<ValidationErrorItem[]>();
    useEffect(() => {
        const results = campaignSchema.validate(cashbackCampaign, { abortEarly: false });
        setErrors(results.error?.details);
    }, [cashbackCampaign])

    const renderPublishIcon = () => {
        if (isPublishing) {
            return <Spinner animation="border" role="status" size={"sm"} style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />
        } else {
            return <PublishButtonIconPlus style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />
        }
    }

    const hasErrors = errors !== undefined
    const canPublish = !hasErrors && !formIsDirty;

    return (
        <PublishModule>
            <div className={`module ${errors ? 'warning' : 'success'}`}>
                <div className="messages">
                    {
                        formIsDirty &&
                        <div>De campaign moet eerst worden opgeslagen voordat deze gepubliceerd kan worden</div>
                    }
                    {
                        hasErrors &&
                        <div>
                            <p>Om te publishen dienen de volgende problemen opgelost te worden:</p>
                            <ul>
                                {errors!.map(error => <li key={error.message}><FontAwesomeIcon icon={faXmark} color={'red'} /> {error.message}</li>)}
                            </ul>
                        </div>
                    }
                    {
                        canPublish &&
                        <div className={'module-body'}>
                            <FontAwesomeIcon icon={faCheckCircle} size={'2x'} color={'green'} /><span>De campagne is klaar om gepublished te worden!</span>
                        </div>
                    }
                </div>
                <div className="publish-button">
                    <WarningButton disabled={!canPublish} onClick={onClickPublish}>{renderPublishIcon()} Publish ...</WarningButton>
                </div>


            </div>
        </PublishModule>
    )
}
