import { useRef, useState } from "react";
import { PrimaryButton, UploadComponentElement } from "./global-components";

interface SingleFileUploadProps {
  title: string;
  doCommit: (sourceIdentifier: string, file: File) => void;
  hintText?: string;
  disabled: boolean;
  sourceIdentifier: string;
}

interface FileData { backgroundData?: string, file?: File };

export const SingleFileUpload = ({ title, hintText, disabled, doCommit, sourceIdentifier }: SingleFileUploadProps) => {
  const inputFile = useRef<HTMLInputElement>(null)
  const [fileData, setFileData] = useState<FileData>({});

  const onChange = (file?: File) => {
    if (file !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileData({
          backgroundData: `url(${e.target?.result})`,
          file,
        });
      }
      reader.readAsDataURL(file);
    } else {
      setFileData({});
    }
  }

  const clickHandler = () => {
    if (inputFile && inputFile.current && (disabled !== true)) {
      inputFile.current.click();
      setFileData({});
    }
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) {
      return;
    }
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file) {
            onChange(file);
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        onChange(event.dataTransfer.files[i]);
      }
    }
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // default/further event handling has to be prevented, otherwise onDrop can't be captured
    event.stopPropagation();
    event.preventDefault();
  }

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    event.preventDefault();
    onChange(event.target?.files?.[0]);
  }

  return <UploadComponentElement onDrop={onDrop} onDragOver={onDragOver}>
    <div className="upload-image-title" >{title}</div>
    {
      (fileData.backgroundData !== undefined)
        ? <>
          <div onClick={clickHandler} style={{ backgroundImage: fileData.backgroundData, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '480px', width: '240px' }}></div>
          <PrimaryButton onClick={() => doCommit(sourceIdentifier, fileData.file!)} disabled={disabled}>Upload dit bestand</PrimaryButton>
        </>
        : <>
          <div className="upload-image-hint">{hintText ?? ".PNG bestand"}</div>
          <PrimaryButton onClick={clickHandler} disabled={disabled}>Selecteer bestand</PrimaryButton>
        </>
    }
    <input disabled={disabled} accept=".png, .jpg, .jpeg" onChange={onFileInputChange} multiple={false} type="file" ref={inputFile} style={{ display: "none" }} />
  </UploadComponentElement >
};