import { RouterStore } from 'mobx-react-router';
import { getConfiguration } from '../configuration';
import { createAdvertisingApiService } from '../services/advertising-api-service';
// eslint-disable-next-line import/no-cycle
import { AdvertisingCampaignStore } from './advertising-campaign-store';
import {CashbackCampaignStore} from "./cashback-campaign-store";
import {createCashbackApiService} from "../services/cashback-api-service";
import {UserStore} from "./user-store";
import {DealsCampaignStore} from "./deals-campaign-store";
import {createDealsApiService} from "../services/deals-api-service";
import {createRedemptionRequestsApiService} from "../services/redemption-requests-api-service";
import {RedemptionRequestStore} from "./redemption-request-store";

export class RootStore {
    readonly routerStore: RouterStore;
    readonly advertisingCampaignStore: AdvertisingCampaignStore;
    readonly cashbackCampaignStore: CashbackCampaignStore;
    readonly dealsCampaignStore: DealsCampaignStore;
    readonly redemptionRequestStore: RedemptionRequestStore;
    readonly configuration = getConfiguration();
    readonly userStore: UserStore;
    readonly advertisingApiService = createAdvertisingApiService(this.configuration)
    readonly cashbackApiService = createCashbackApiService(this.configuration)
    readonly dealsApiService = createDealsApiService(this.configuration)
    readonly redemptionApiService = createRedemptionRequestsApiService(this.configuration)

    constructor() {
        this.routerStore = new RouterStore();
        this.userStore = new UserStore(this);
        this.advertisingCampaignStore = new AdvertisingCampaignStore(this);
        this.cashbackCampaignStore = new CashbackCampaignStore(this);
        this.dealsCampaignStore = new DealsCampaignStore(this);
        this.redemptionRequestStore = new RedemptionRequestStore(this);

    }
}
