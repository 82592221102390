import {CashbackCampaign, CashbackCampaignType, SortableTypesCashbackCampaign} from "../types/cashback-campaign";

export const getCampaignTypeDescription = (type: CashbackCampaignType) => {
    switch(type) {
        case CashbackCampaignType.receipt: return 'Aankoopbon';
        case CashbackCampaignType.barcode: return 'Barcode';
        case CashbackCampaignType.token: return 'Token';
        default: return 'Unknown';
    }
}

export const sortCampaigns = (field: SortableTypesCashbackCampaign, campaigns: CashbackCampaign[]) => {
    const sortedCampaigns = campaigns;
    if(campaigns && campaigns[0] && campaigns[0][field] === undefined) return sortedCampaigns;
    if(field === "type") {
        sortedCampaigns.sort(
            (a, b) => getCampaignTypeDescription(b[field]).localeCompare(getCampaignTypeDescription(a[field]), 'nl'))
    } else if(typeof field === "string") {
        sortedCampaigns.sort(
            (a, b) => b[field].localeCompare(a[field], 'nl'));
    } else {
        sortedCampaigns.sort(
            (a, b) => b[field] - a[field]);
    }
    return sortedCampaigns;
}
