import {observer} from 'mobx-react-lite';
import React from 'react';
import {CashbackCampaignList, Show} from '../components';
import {useStore} from '../stores/store-context';
import {Column, InputBox, InputBoxBackgroundImage, PrimaryButton, Row} from '../components/global/global-components';
import {useHistory} from 'react-router-dom';
import {ButtonIconPlus} from '../components/icons';
import {DealsCampaignList} from "../components/deals-list";

export const DealsOverviewPage = observer(() => {
    const type = 'cashback-campaign';
    const { dealsCampaignStore } = useStore();
    const { push } = useHistory();

    const toNewCampaign = () => {
        push('deals-campaign-support/new-campaign');
    }

    return <div className="page">
        <h2 className="pageLabel">Deals</h2>
        <Row style={{marginBottom: '40px'}}>
            <Column style={{ textAlign: 'right' }}>
                <PrimaryButton onClick={toNewCampaign}><ButtonIconPlus style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />New</PrimaryButton>
            </Column>
        </Row>
        <DealsCampaignList type={type} />
        <Show when={dealsCampaignStore.apiServiceError !== undefined}>
            {dealsCampaignStore.apiServiceError}
        </Show>
    </div>
});
