import {
    AdvertisingCampaign, AdvertisingCardSize,
    AdvertisingCampaignType,
    CampaignWorkflowStatus,
    DraftAdvertisingCampaign,
    IsoDateString
} from "../types";
import { DateTime } from 'luxon';

const spaceRexExp = /\s/g;
const notLetterChar = /[^0-9a-z ]/gi
const startDateRegExp = /^\d\d\d\d-\d\d-\d\d/;
export const createNormalizedId = (campaignName: string, type: string, startDate: IsoDateString) => {
    if (!startDate.match(startDateRegExp)) {
        throw new Error(`${startDate} does not math pattern dddd-dd-dd`);
    }
    return [
        campaignName.replace(notLetterChar, '').trim().replace(spaceRexExp, '-'),
        type,
        startDate.substr(0, 10)
    ].join('-').toLowerCase()
}

export const asCampaignType = (s: string) => {
    if (s === 'app' || s === 'web') {
        return s as AdvertisingCampaignType;
    }
    throw new Error(`${s} is not a campaignType ('app' | 'web')`);
}

export const createNewCampaign = (campaignType: string, type: string, campaignName: string, startDate: IsoDateString) => {
    const campaignId = createNormalizedId(campaignName, type, startDate);

    const newCampaign: Partial<AdvertisingCampaign> = {
        campaignId,
        campaignType: asCampaignType(campaignType),
        startDate: startDate.substr(0, 10),
        campaignName,
        active: false,
    };
    return newCampaign;
}

export const getCampaignTypeDescription = (type: AdvertisingCampaignType, size: AdvertisingCardSize) => {
    if (type === AdvertisingCampaignType.app) {
        // TODO: card size small not used for now but probably will be used in the future
        if (size === AdvertisingCardSize.small) {
            return 'In-app (small)';
        } else if (size === AdvertisingCardSize.default) {
            return 'Personal overview';
        }
    }
    // TODO: web only is thank you page for now, this could change in the future
    if (type === AdvertisingCampaignType.web) {
        return 'Thank you page';
    }
    return 'Onbekend campaign type';
}

export const toFriendlyCampaignDate = (dateString?: string) => {
    if (dateString !== undefined) {
        const dateTime = DateTime.fromISO(dateString, { zone: 'Europe/Amsterdam' });
        return `${dateTime.toFormat('d')} ${dateTime.toFormat('LLL')} '${dateTime.toFormat('yy')}`;
    }
    return '?'
}

export const isInThePast = (dateString: string) => DateTime.fromISO(dateString, { zone: 'Europe/Amsterdam' }) < DateTime.local();

export const isInTheFuture = (dateString: string) => DateTime.fromISO(dateString, { zone: 'Europe/Amsterdam' }) > DateTime.local()

export const getConsolidatedStatus = (campaign: DraftAdvertisingCampaign) => {

    switch (true) {
        case campaign.endDate === undefined: return CampaignWorkflowStatus.draft;
        case isInThePast(campaign.endDate!): return CampaignWorkflowStatus.expired;
        case !campaign.active: return CampaignWorkflowStatus.draft;

        // active and an end date in the future
        case isInThePast(campaign.startDate): return CampaignWorkflowStatus.active;
        case isInTheFuture(campaign.startDate): return CampaignWorkflowStatus.approved;

        default: return CampaignWorkflowStatus.draft;
    }
}

export type CampaignTypeSizeOption = { type: AdvertisingCampaignType, size: AdvertisingCardSize }

export const campaignTypeOptions = [
    { type: AdvertisingCampaignType.app, size: AdvertisingCardSize.default } as CampaignTypeSizeOption,
    { type: AdvertisingCampaignType.web, size: AdvertisingCardSize.default } as CampaignTypeSizeOption,
].map(ct => ({ label: getCampaignTypeDescription(ct.type, ct.size), value: ct }))

