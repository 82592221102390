import React, { useEffect, useState } from "react";
import { BreadCrumbNavigation } from "../components";
import { Row } from "../components/global/global-components";
import { observer } from "mobx-react-lite";
import { CampaignMediaForm } from "../components/global/campaign-media-form";
import { CampaignMediaConfiguration } from "../types";
import { useStore } from "../stores/store-context";
import { useParams, useHistory } from "react-router-dom";


export const UploadCashbackImagesPage = observer(() => {
  const history = useHistory();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { cashbackCampaignStore } = useStore();
  const [campaign, setCampaign] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    cashbackCampaignStore.getCampaign(campaignId)
      .then((fetchedCampaign) => {
        setCampaign(fetchedCampaign);
        console.log('Fetched campaign: ', fetchedCampaign);
      })
      .catch((e) => {
        console.error('Failed to fetch campaign: ', e);
        history.push('/not-found');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [campaignId, cashbackCampaignStore, history])



  const campaignConfiguration: CampaignMediaConfiguration = {
    imageMedia: [{
      sourceIdentifier: 'productImage',
      title: 'Overview card afbeelding',
      description: 'upload afmetingen 1200px × 1237px',
      affectedPropertyPaths: [
        'productImage.small',
        'productImage.medium',
        'productImage.large',]
    }, {
      sourceIdentifier: 'productImageLanding',
      title: 'Landing page afbeelding',
      description: 'upload afmetingen 1200px × 1237px',
      affectedPropertyPaths: [
        'productImageLanding.small',
        'productImageLanding.medium',
        'productImageLanding.large',
      ]
    }]
  }

  return (
    <div className={"page"}>
      <Row>
        <h1>{campaign?.id}</h1>
      </Row>
      <Row>
        <BreadCrumbNavigation crumbs={[
          { label: 'Campagnes' },
          { link: '/cashback-campaign-support', label: 'Cashbacks' },
          { link: `/cashback-campaign-support/cashback-campaign/${campaignId}`, label: campaignId }]} />
      </Row>
      {
        campaign ? <CampaignMediaForm campaign={campaign} campaignConfiguration={campaignConfiguration} /> : null
      }

    </div>
  )
});
