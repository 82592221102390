import {CashbackDesignPart} from "../../../types/cashback-campaign";
import {ValidationError} from "joi";
import {
    ColorInputBox,
    FieldLabel,
    DropDown,
    FieldValue,
    Header2,
    InputBox,
    ValidationErrorIndication
} from "../../global/global-components";
import {Section, SectionContainer} from "../../global/sections";

interface FormDesignPartProps {
    formData: Partial<CashbackDesignPart>;
    validationErrors?: ValidationError;
    updateForm: (part: Partial<CashbackDesignPart>) => void;
}

export const FormDesignPart = ({formData, validationErrors, updateForm}: FormDesignPartProps) => {
    const campaignTextColorOptions = [ 
        {label: "Donker", value: "515161"},
        {label: "Wit", value: "FFFFFF"}
    ];
    const selectedTextColorOption = campaignTextColorOptions.findIndex(option => option.value === formData.textColorHex);
    

    return (
        <div>
            <Header2>Campagne ontwerp</Header2>
            <SectionContainer>
                <Section wrap="wrap" gap="37px">
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Titel op card</div>
                        <div className="description">Card</div>
                    </FieldLabel>
                    <FieldValue widthProportions="30-70">
                        <div>
                            <InputBox customStyle={{backgroundPosition: "center left 15px"}}
                                      value={formData.cardDescription} onChange={(value) => updateForm({cardDescription: value})}/>
                            <ValidationErrorIndication errorKey="cardDescription" validationErrors={validationErrors}/>
                        </div>
                    </FieldValue>
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Titel op landing</div>
                        <div className="description">Landing</div>
                    </FieldLabel>
                    <FieldValue widthProportions="30-70">
                        <div>
                            <InputBox customStyle={{backgroundPosition: "center left 15px"}}
                                      value={formData.title} onChange={(value) => updateForm({title: value})}/>
                            <ValidationErrorIndication errorKey="title" validationErrors={validationErrors}/>
                        </div>
                    </FieldValue>
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Achtergrondkleur van kaart en landing</div>
                    </FieldLabel>
                    <FieldValue widthProportions="30-70">
                        <div>
                            <ColorInputBox placeholder="E9E9E9" value={formData.cardColorHex || ''} onChange={value => updateForm({cardColorHex: value})} />
                            <ValidationErrorIndication errorKey="cardColorHex" validationErrors={validationErrors}/>
                        </div>
                    </FieldValue>
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Kleur van cirkel</div>
                        <div className="description">Achtergrondkleur cirkel</div>
                    </FieldLabel>
                    <FieldValue widthProportions="30-70">
                        <div>
                            <ColorInputBox placeholder="E9E9E9" value={formData.cardColorCircleHex || ''} onChange={(value) => updateForm({cardColorCircleHex: value})} />
                            <ValidationErrorIndication errorKey="cardColorCircleHex" validationErrors={validationErrors}/>
                        </div>
                    </FieldValue>
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Kleur van tekst</div>
                    </FieldLabel>
                    <FieldValue>
                        <div>
                            <DropDown 
                            selectedIndex={selectedTextColorOption}
                            optionValues={campaignTextColorOptions} 
                            onChange={ (textColorHexValue: string) => {
                                        updateForm({textColorHex: textColorHexValue})
                                    }
                                } />
                            <ValidationErrorIndication errorKey="campaignType" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
            <SectionContainer>
                <Section wrap="wrap" gap="37px">
                    <FieldLabel>
                        <div className="title">T&C omschrijving</div>
                        <div className="description">De omschrijving van de voorwaarden</div>
                    </FieldLabel>
                    <FieldValue>
                        <div>
                            <InputBox placeholder="Click out URL" value={formData.termsDescription || ''} onChange={(value) => updateForm({termsDescription: value})} />
                            <ValidationErrorIndication errorKey="termsDescription" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                    <FieldLabel>
                        <div className="title">URL</div>
                        <div className="description">Https link naar T&C van merk</div>
                    </FieldLabel>
                    <FieldValue>
                        <div>
                            <InputBox placeholder="Click out URL" value={formData.termsUrl || ''} onChange={(value) => updateForm({termsUrl: value.trim()})} />
                            <ValidationErrorIndication errorKey="termsUrl" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
        </div>
    );
}
