import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import { UnAuthorizedError } from '../services/advertising-api-service';
import { createNormalizedId, getConsolidatedStatus } from '../services/advertising-campaign-service';
import { CampaignWorkflowStatus, DraftAdvertisingCampaign, Lookup } from '../types';
import { validateAdvertisingCampaign } from '../validation/validate-advertising-campaign';
// eslint-disable-next-line import/no-cycle
import { RootStore } from './root-store';

export class AdvertisingCampaignStore {

    @action async saveCampaign(updatedCampaign: DraftAdvertisingCampaign, images: Lookup<File>) {
        const validation = validateAdvertisingCampaign(updatedCampaign);
        if (!validation.error) {

            updatedCampaign.campaignId = updatedCampaign.campaignId || createNormalizedId(updatedCampaign.campaignName, updatedCampaign.campaignType, updatedCampaign.startDate)

            const { campaign } = await this.rootStore.advertisingApiService
                .postCampaign(updatedCampaign, images)
                .catch(this.errorHandler);

            if (campaign !== undefined) {
                const index = this.campaigns.findIndex(this.byId(campaign.campaignId));
                if (index === -1) {
                    this.campaigns.push(campaign);
                } else {
                    this.campaigns.splice(index, 1, campaign);
                }
                return { savedCampaign: campaign }
            } else {
                return { error: 'Error saving the campaign' }
            }

        } else {
            return { error: validation.error }
        }

    }

    isUnique(id: string) {
        const toId = (campaign: DraftAdvertisingCampaign) => campaign.campaignId;
        return !this.campaigns.map(toId).includes(id);
    }

    @observable readonly campaigns: DraftAdvertisingCampaign[] = [];
    @observable apiServiceError: string | undefined;
    @observable userIsAuthorized: boolean = true;
    @observable campaignStatusFilter: CampaignWorkflowStatus = CampaignWorkflowStatus.draft;

    constructor(private rootStore: RootStore) {
        makeObservable(this)
        this.getCampaigns();
    }

    @action private readonly errorHandler = (error: Error) => {
        if (error instanceof UnAuthorizedError) {
            this.userIsAuthorized = false;
        } else {
            this.apiServiceError = error.message;
        }
        return { error };
    }

    @action setUserIsAuthorized(isAuthorized: boolean) {
        this.userIsAuthorized = isAuthorized;
    }

    @action private setCampaigns = (campaigns: DraftAdvertisingCampaign[]) => this.campaigns.splice(0, this.campaigns.length, ...campaigns);

    private getCampaigns = () => this.rootStore.advertisingApiService
        .getCampaigns()
        .then(campaigns => {
            this.setCampaigns(campaigns.campaigns);
        })
        .catch(this.errorHandler);

    sendPreview = (campaign: DraftAdvertisingCampaign, userToken: string) => this.rootStore.advertisingApiService
        .sendPreview({ campaign, userToken })
        .catch(this.errorHandler);

    getCampaign(campaignId: string) {
        const byId = (id: string) => (campaign: DraftAdvertisingCampaign) => campaign.campaignId === id;
        return this.campaigns.find(byId(campaignId));
    }

    @action refreshCampaigns = () => this.getCampaigns();

    @action readonly removeCampaign = (campaign: DraftAdvertisingCampaign) => {
        this.rootStore.advertisingApiService
            .deleteCampaign(campaign)
            .then(() => runInAction(() => {
                const index = this.campaigns.findIndex(c => c.campaignId === campaign.campaignId);
                if (index > -1) {
                    this.campaigns.splice(index, 1);
                }
            }))
            .catch(this.errorHandler)
    }

    @action setCampaignStatusFilter(status: CampaignWorkflowStatus) {
        this.campaignStatusFilter = status;
    }

    private byStatus = (status: CampaignWorkflowStatus) => (campaign: DraftAdvertisingCampaign) => getConsolidatedStatus(campaign) === status;
    public readonly byId = (id: string) => (campaign: DraftAdvertisingCampaign) => campaign.campaignId === id;

    @computed get filteredCampaigns() { return this.campaigns.filter(this.byStatus(this.campaignStatusFilter)); }

}
