import {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import styled from "styled-components";

const ItemTypes = {
    MERCHANT: 'merchant'
}

const MerchantImage = styled.img`
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
`

interface MerchantBadgeProps {
    width: string;
    height: string;
}
const MerchantBadge = styled.div<MerchantBadgeProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 50%;
    border: 1px solid #EFEFEF;
    mix-blend-mode: multiply;
`

interface SingleMerchantBadgeProps {
    imageUrl: string;
    index: number;
    onClick: () => void;
    onDropHandler?: (source: number, target: number) => void;
}
export const SingleMerchantBadge = ({imageUrl, index, onClick, onDropHandler}: SingleMerchantBadgeProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop<SingleMerchantBadgeProps, void>({
        accept: ItemTypes.MERCHANT,
        collect(monitor) {
            return ({
                isOver: monitor.isOver()
            })
        },
        hover(item: SingleMerchantBadgeProps, monitor) {
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return
            }
            if (onDropHandler) {
                onDropHandler(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }
        }
    });

    const [, drag] = useDrag({
        type: ItemTypes.MERCHANT,
        collect(monitor) {
            return ({
                isDragging: monitor.isDragging()
            })
        },
        item: () => {
            return { index }
        },

    })

    drag(drop(ref))

    return (
        <MerchantBadge ref={ref} onClick={onClick} height='60px' width='60px'>
            <MerchantImage src={imageUrl} />
        </MerchantBadge>
    );
};