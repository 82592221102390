import {
    FieldLabel,
    FieldValue,
    Header2,
    InputBoxElement,
    Section,
    SectionContainer,
    ValidationErrorIndication
} from "../../global/global-components";
import React from "react";

export const FormTokenPart = () => {
    return <div>TOKENS</div>
    // return
    // <div>
    //     <Header2>Transacties</Header2>
    //     <SectionContainer>
    //         <Section wrap="wrap" gap="37px">
    //             <FieldLabel>
    //                 <div className="title">Remittance text</div>
    //                 <div className="description">De text bij de overboeking</div>
    //             </FieldLabel>
    //             <FieldValue>
    //                 <div>
    //                     <InputBoxElement placeholder="Vul hier de (werk)titel in van de advertentie" value={formData.remittanceText || ""} onChange={(event) => updateForm({remittanceText: event.target.value})} />
    //                     <ValidationErrorIndication errorKey="remittanceText" validationErrors={validationErrors}/>
    //                 </div>
    //             </FieldValue>
    //             <FieldLabel>
    //                 <div className="title">Verzender IBAN</div>
    //                 <div className="description">De IBAN van de verzender</div>
    //             </FieldLabel>
    //             <FieldValue>
    //                 <div>
    //                     <InputBoxElement placeholder="Vul hier de (werk)titel in van de advertentie" value={formData.senderIban || ""} onChange={(event) => updateForm({senderIban: event.target.value})} />
    //                     <ValidationErrorIndication errorKey="senderIban" validationErrors={validationErrors}/>
    //                 </div>
    //             </FieldValue>
    //             <FieldLabel>
    //                 <div className="title">Suspense account code</div>
    //             </FieldLabel>
    //             <FieldValue>
    //                 <div>
    //                     <InputBoxElement placeholder="Vul hier de (werk)titel in van de advertentie" value={formData.suspenseAccountCode || ""} onChange={(event) => updateForm({suspenseAccountCode: event.target.value})} />
    //                     <ValidationErrorIndication errorKey="suspenseAccountCode" validationErrors={validationErrors}/>
    //                 </div>
    //             </FieldValue>
    //             <FieldLabel>
    //                 <div className="title">Billing IBAN</div>
    //             </FieldLabel>
    //             <FieldValue>
    //                 <div>
    //                     <InputBoxElement placeholder="Vul hier de (werk)titel in van de advertentie" value={formData.billingIban || ""} onChange={(event) => updateForm({billingIban: event.target.value})} />
    //                     <ValidationErrorIndication errorKey="billingIban" validationErrors={validationErrors}/>
    //                 </div>
    //             </FieldValue>
    //         </Section>
    //     </SectionContainer>
    // </div>
}
