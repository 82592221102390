import { useStore } from "../../stores/store-context";
import { RedemptionValidation } from "../../types/redemption-requets";
import { Section } from "../global/sections";

interface Props {
  details?: RedemptionValidation["details"]
}

export function ViewRedemptionValidationDetails({ details }: Props) {
  const { redemptionRequestStore } = useStore();

  const openImage = async (imagPath: string): Promise<void> => {
    const imageUrl = await redemptionRequestStore.getImageUrl(imagPath);
    window.open(imageUrl, '_blank');
  }

  const openDetailsFromS3 = async (): Promise<void> => {
    if (!details?.fromS3?.fileName || !details.fromS3.bucketName) {
      return
    }

    const url = await redemptionRequestStore.getValidationDetails(details.fromS3.bucketName, details.fromS3.fileName)
    window.open(url, '_blank');
  }

  const buildDetailsFromS3 = () => {
    if (!details?.fromS3?.fileName || !details.fromS3.bucketName) {
      return
    }

    return (<Section wrap="wrap" style={{ display: "block" }} >
      <div className="title">Details</div>
      <div className="description">
        <button style={{ marginTop: "10px" }} onClick={openDetailsFromS3}>Open Details</button>
      </div>
    </Section>)
  }

  const buildUploadedImages = () => {
    if (!details?.uploadedImages?.length) {
      return
    }

    const buildEachImage = () => {
      return details?.uploadedImages?.map(image => {
        return (<div style={{ marginTop: "10px" }}><button onClick={() => openImage(image)}>view image</button> </div>)
      })
    }

    return (<Section wrap="wrap" style={{ display: "block" }}>
      <div className="title">Receipt Images</div>
      <div className="description">{buildEachImage()}</div>
    </Section>)
  }

  // TODO: remove this when the old details are all moved to S3 or deprecated
  const buildTomraInfos = () => {
    if (!details?.tomraConsumerSession) {
      return
    }

    return (<Section wrap="wrap" style={{ display: "block" }}>
      <div className="title">Tomra Info</div>
      <div className="description" style={{ marginTop: "10px" }}>
        <pre style={{ height: "300px", maxWidth: "50rem" }}>{JSON.stringify(details.tomraConsumerSession, null, 2)}</pre>
      </div>
    </Section>)
  }

  // TODO: remove this when the old details are all moved to S3 or deprecated
  const buildReceiptInfos = () => {
    if (!details?.receiptInfo) {
      return
    }

    return (<Section wrap="wrap" style={{ display: "block" }}>
      <div className="title">Receipt Info</div>
      <div className="description" style={{ marginTop: "10px" }}>
        <pre style={{ height: "300px", maxWidth: "50rem" }}>{JSON.stringify(details.receiptInfo, null, 2)}</pre>
      </div>
    </Section>)
  }

  return (
    <div>
      {buildUploadedImages()}
      {buildDetailsFromS3()}
      {buildTomraInfos()}
      {buildReceiptInfos()}
    </div>

  )
}
