import { BreadCrumbNavigation, Show } from "../components";
import { ClearTextButton, Row, WarningButton } from "../components/global/global-components";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {DealCampaign, DealStatus, DealType} from "../types";
import { useStore } from "../stores/store-context";
import { Spinner } from "react-bootstrap";
import { PublishButtonIconPlus } from "../components/icons";
import { FullScreenModal } from "../components/global/modal";
import { ModalCampaignTitle, ModalControls, ModalText } from "../components/modal/modal";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { receiptSchema, tomraSchema, receiptUiSchema, tomraUiSchema } from "../schema/rjsf-schema";
import { widgets } from "../components/rjsf/widgets";
import { fields } from "../components/rjsf/fields";

export const EditDealsPage = observer(() => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { dealsCampaignStore, userStore } = useStore();
  const isApprover = userStore.campaignRoles.includes('approver');
  const [campaign, setCampaign] = React.useState({} as DealCampaign);
  const [isLoading, setIsLoading] = useState(true);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [schema, setSchema] = React.useState(tomraSchema);
  const [uiSchema, setUiSchema] = React.useState(tomraUiSchema);

  useEffect(() => {
    dealsCampaignStore.getCampaign(campaignId)
      .then((campaign) => {
        if (campaign === undefined) {
          throw new Error('something errored');
        }

        if (campaign.type === 'receipt') {
          setSchema(receiptSchema);
          setUiSchema(receiptUiSchema);
        }

        if (campaign.type === 'tomra') {
          setSchema(tomraSchema);
          setUiSchema(tomraUiSchema);
        }
          setCampaign(campaign);
          console.log('Fetched campaign: ', campaign);
      })
      .catch((e) => {
        console.error('Failed to fetch campaign: ', e);
        // history.push('/not-found');
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, [])


  const storeCashback = async () => {
    try {
      await dealsCampaignStore.saveCampaign(campaign);
      alert('update was successful');
    } catch (e) {
      console.log(e);
    }
  }

  const renderPublishConfirmation = () => {
    if (showPublishConfirmation) {
      return (
        <FullScreenModal>
          <ModalText><ModalCampaignTitle>{campaign.campaignData.campaignName}</ModalCampaignTitle> definitief op actief zetten?</ModalText>
          <Show when={!isPublishing}>
            <ModalControls>
              <WarningButton onClick={() => publishCampaign()}><span style={{ paddingLeft: '12px' }}>Publish</span></WarningButton>
              <ClearTextButton onClick={() => { setShowPublishConfirmation(false) }}>Cancel</ClearTextButton>
            </ModalControls>
          </Show>
          <Show when={isPublishing}>
            <div>Bezig met publiceren, dit kan even duren</div>
          </Show>
        </FullScreenModal>
      )
    }
  }


  const showPublishModal = () => {
    setShowPublishConfirmation(true);
  }


  const publishCampaign = () => {
    setIsPublishing(true);
    dealsCampaignStore.publishCampaign(campaign.id, campaign.type)
      .then((response) => {
        console.log(`published`, response);
      })
      .catch((error) => console.log(`error`, error)
      )
      .finally(() => {
        setShowPublishConfirmation(false)
        setIsPublishing(false);
        console.log(`publishing done`)
      });
  }


  return (
    <div className={"page"} style={{ position: 'relative' }}>
      <Row>
        <BreadCrumbNavigation crumbs={[{ label: 'Deals' }, { link: '/deals-campaign-support', label: 'Deals' }, { label: 'New' }]} />
      </Row>
      {isLoading ? (<div><Spinner animation={"border"} size={"sm"} /> Loading...</div>) : (

        <>
          <Form
            formData={campaign}
            schema={schema}
            validator={validator}
            onSubmit={storeCashback}
            uiSchema={uiSchema}
            widgets={widgets}
            fields={fields}
            onChange={(e) => {
              setCampaign(e.formData);
            }}
          />
          <div style={{
            paddingTop: '40px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            gap: '20px'
          }}>
            <WarningButton disabled={!isApprover}
              style={{ marginRight: '16px' }}
              onClick={showPublishModal}>
              {<PublishButtonIconPlus style={{
                verticalAlign: 'text-bottom',
                marginRight: '8px'
              }} />} {campaign.active ? "Aanpassingen publiceren" : "Publiceren"}
            </WarningButton>
            {renderPublishConfirmation()}
          </div>
        </>
      )}
    </div>
  )
});
