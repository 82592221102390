import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { Button } from "react-bootstrap";
import { Column, Row } from "../components/global/global-components"
import { useStore } from '../stores/store-context';
import { Link } from "react-router-dom";
import { Show } from "../components";

export const UserIsNotAuthorized = observer(() => {

    const { configuration, advertisingCampaignStore, cashbackCampaignStore } = useStore();
    const [hasTokenCommitted, setHasTokenCommitted] = useState(false)
    const [newToken, setNewToken] = useState(localStorage.getItem(configuration.bearerTokenKey) || '');
    const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewToken(event.target.value);
    }

    const commitNewTokenToLocalStorage = async () => {
        localStorage.setItem(configuration.bearerTokenKey, newToken);
        await advertisingCampaignStore.refreshCampaigns();
        await cashbackCampaignStore.refreshCampaigns();
        await advertisingCampaignStore.setUserIsAuthorized(true);
        setHasTokenCommitted(true);
    }


    return <div className="page">
        <h2>Niet geauthoriseerd</h2>
        <Row><Column>You are logged out or your login has expired, please login in again</Column></Row>
        {
            configuration.isLocal
                ? <><Row><Column>
                    <textarea onChange={textAreaChange} value={newToken} rows={5} cols={80}></textarea>
                    <Button onClick={commitNewTokenToLocalStorage}>Update</Button>
                </Column></Row>
                    <Show when={hasTokenCommitted}>
                        <Row>
                            <Column>
                                <Link to="/advertising-campaign-support">Advertising campaigns</Link>
                                <Link to="/cashback-campaign-support">Cashback campaigns</Link>
                            </Column>
                        </Row>
                    </Show></>
                : null
        }
    </div >
});
