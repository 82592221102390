import { FieldLabel, FieldValue, Section, SectionContainer } from "../../global/sections";
import {
    CalendarComponent,
    InputBoxBackgroundImage,
    InputBoxElement, NumberInputBox,
    ValidationErrorIndication
} from "../../global/forms";
import { DateTime } from "luxon";
import { CashbackMetaPart } from "../../../types/cashback-campaign";
import { ValidationError } from "joi";
import { Header2, WarningButton } from "../../global/global-components";

interface FormMetaPartProps {
    formData: Partial<CashbackMetaPart>;
    validationErrors?: ValidationError;
    updateForm: (part: Partial<CashbackMetaPart>) => void;
}


export const FormMetaPart = ({ formData, validationErrors, updateForm }: FormMetaPartProps) => {
    const amountInEuros = (value: number | undefined) => Number.isNaN(value) ? undefined : value! / 100;
    const setTresholdAmountInCents = (value: number | undefined) => updateForm({ thresholdAmount: value === undefined ? undefined : value * 100 });
    const setTresholdAlarmAmountInCents = (value: number | undefined) => updateForm({ thresholdAlarmAmount: value === undefined ? undefined : value * 100 });
    const setDefaultTresholdAlarmAmount = () => {
        if (!formData.thresholdAlarmAmount) {
            updateForm({ thresholdAlarmAmount: formData.thresholdAmount ? formData.thresholdAmount * 0.8 : undefined })
        }
    }
    return (
        <div>
            <SectionContainer>
                <Section wrap="wrap" gap="37px">
                    <FieldLabel>
                        <div className="title">Organisation ID</div>
                        <div className="description">Alleen nummers</div>
                    </FieldLabel>
                    <FieldValue>
                        <div>
                            <InputBoxElement placeholder="Vul hier het organisatie ID in van de campagne" value={formData?.organisationId || ""} onChange={(event) => updateForm({ organisationId: event.target.value })} />
                            <ValidationErrorIndication errorKey="organisationId" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                    <FieldLabel>
                        <div className="title">Merk naam</div>
                        <div className="description">Maximaal 50 karakters</div>
                    </FieldLabel>
                    <FieldValue>
                        <div>
                            <InputBoxElement placeholder="Vul hier de merknaam in van de campagne" value={formData?.brandName || ""} onChange={(event) => updateForm({ brandName: event.target.value })} />
                            <ValidationErrorIndication errorKey="brandName" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                    <FieldLabel>
                        <div className="title">Campagne titel</div>
                        <div className="description">Maximaal 50 karakters</div>
                    </FieldLabel>
                    <FieldValue>
                        <div>
                            <InputBoxElement placeholder="Vul hier de titel in van de campagne" value={formData?.campaignName || ""} onChange={(event) => updateForm({ campaignName: event.target.value })} />
                            <ValidationErrorIndication errorKey="campaignName" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>

            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Startdatum*</div>
                        <div className="description">Verplicht invulveld</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <CalendarComponent value={formData.startDate} onChange={(date) => updateForm({ startDate: DateTime.fromISO(date).toISODate() })} />
                            <ValidationErrorIndication errorKey="startDate" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Einddatum</div>
                        <div className="description">Verplicht invulveld</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <CalendarComponent value={formData.endDate} onChange={(date) => updateForm({ endDate: DateTime.fromISO(date).toISODate() })} />
                            <ValidationErrorIndication errorKey="endDate" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Zichtbaar in-app vanaf</div>
                        <div className="description">Campagne zichtbaar op deals pagina</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <CalendarComponent value={formData.startViewDate} onChange={(date) => updateForm({ startViewDate: DateTime.fromISO(date).toISODate() })} />
                            <ValidationErrorIndication errorKey="startViewDate" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Zichtbaar in-app tot</div>
                        <div className="description">Campagne zichtbaar op deals pagina</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <CalendarComponent value={formData.endViewDate} onChange={(date) => updateForm({ endViewDate: DateTime.fromISO(date).toISODate() })} />
                            <ValidationErrorIndication errorKey="endViewDate" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
            {
                (formData.startViewDate || formData.endViewDate) &&
                <SectionContainer>
                    <Section>
                        <FieldLabel widthProportions="50-50">
                            <div className="title">Verwijder van deals pagina</div>
                            <div className="description">De campagne niet langer laten zien op de deals pagina</div>
                        </FieldLabel>
                        <FieldValue widthProportions="50-50" align="right">
                            <WarningButton onClick={() => updateForm({ startViewDate: undefined, endViewDate: undefined })}>Verwijder</WarningButton>
                        </FieldValue>
                    </Section>
                </SectionContainer>
            }

            <Header2>Budget</Header2>
            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Budget</div>
                        <div className="description">Totaal uit te geven</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <NumberInputBox customStyle={{ backgroundPosition: "center left 15px" }} backgroundImage={InputBoxBackgroundImage.euro}
                                value={amountInEuros(formData.thresholdAmount)}
                                onChange={setTresholdAmountInCents}
                                onBlurAction={setDefaultTresholdAlarmAmount} />
                            <ValidationErrorIndication errorKey="thresholdAmount" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Alert op bedrag</div>
                        <div className="description">Budget alert bij ‘x’ bedrag dat is uitbetaald</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <NumberInputBox customStyle={{ backgroundPosition: "center left 15px" }} backgroundImage={InputBoxBackgroundImage.euro}
                                value={amountInEuros(formData.thresholdAlarmAmount)}
                                onChange={setTresholdAlarmAmountInCents} />
                            <ValidationErrorIndication errorKey="thresholdAlarmAmount" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
        </div>
    )
}
