import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { InputBox, InputBoxBackgroundImage } from "./forms"
import { PrimaryButton } from "./global-components";
import { FieldLabel, FieldValue, Section } from "./sections"

const PREVIEW_DESTINATION = 'preview-destination';

interface SendPreviewSectionProps {
  disabled: boolean;
  campaignIsValid: boolean;
  sendPreview: (userToken: string) => Promise<void>;
}
enum SendPreviewStatus {
  none = 'none',
  sending = 'sending',
  sent = 'sent',
}

export const SendPreviewSection = observer((props: SendPreviewSectionProps) => {
  const { disabled, campaignIsValid, sendPreview } = props;
  const validate = (userToken: string) => /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(userToken) && /^[a-z0-9-]{36}$/.test(userToken);
  const storedUserToken = localStorage.getItem(PREVIEW_DESTINATION) || '';
  const [state, setState] = useState({ userToken: storedUserToken, userTokenIsValidated: validate(storedUserToken) });
  const setUserToken = (value: string) => {
    const isValidated = validate(value);
    if (isValidated) {
      localStorage.setItem(PREVIEW_DESTINATION, value)
    }
    setState({ ...state, userToken: value, userTokenIsValidated: isValidated });
  };


  const [previewSendStatus, setPreviewSendStatus] = useState<SendPreviewStatus>(SendPreviewStatus.none);
  const doSendPreview = async () => {
    setPreviewSendStatus(SendPreviewStatus.sending);
    await sendPreview(state.userToken)
      .then(() => {
        setPreviewSendStatus(SendPreviewStatus.sent);
        setTimeout(() => setPreviewSendStatus(SendPreviewStatus.none), 3000)
      });
  }

  const label = previewSendStatus === SendPreviewStatus.none
    ? 'Verstuur preview'
    : previewSendStatus === SendPreviewStatus.sending
      ? 'Bezig met versturen'
      : 'Verstuurd'

  return <Section>
    <FieldLabel>
      <div className="title">Bekijk voorbeeld op toestel</div>
      <div className="description">Vul persoonlijk user token in</div>
    </FieldLabel>
    <FieldValue>
      <InputBox
        disabled={disabled}
        placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx (8-4-4-4-12)"
        value={state.userToken}
        onChange={setUserToken}
        backgroundImage={state.userTokenIsValidated ? InputBoxBackgroundImage.ok : InputBoxBackgroundImage.none}
      />
      <div style={{ flexGrow: 0, paddingTop: '12px' }} className="margin-left">
        <PrimaryButton disabled={!state.userTokenIsValidated || disabled || !campaignIsValid} onClick={doSendPreview} >{label}</PrimaryButton>
      </div>
    </FieldValue>
  </Section>

});
