import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AdvertisingCampaignEditPage, AdvertisingOverviewPage, UserIsNotAuthorized } from './containers';
import { GlobalStyle } from './global-styles';
import { observer } from 'mobx-react-lite';
import { useStore } from './stores/store-context';
import { CashbackOverviewPage } from "./containers/cashback-overview-page";
import { ModalProvider } from 'styled-react-modal';
import { NewCashbackPage } from "./containers/new-cashback-page";
import { EditCashbackPage } from "./containers/edit-cashback-page";
import { NotFoundPage } from "./containers/not-found";
import { UploadCashbackImagesPage } from './containers/cashback-campaign-media';
import { DealsOverviewPage } from "./containers/deals-overview-page";
import { NewDealsPage } from "./containers/new-deals-page";
import { EditDealsPage } from "./containers/edit-deals-page";
import { ViewPublishedPage } from "./containers/view-published-deals-page";
import { RedemptionRequestsOverviewPage } from "./containers/redemption-requets-overview-page";
import { ViewRedemptionRequestPage } from "./containers/view-redemption-request-page";

import './App.css'

export const App = observer(() => {
  const { advertisingCampaignStore, cashbackCampaignStore, dealsCampaignStore } = useStore();
  const userIsAuthorized = advertisingCampaignStore.userIsAuthorized || cashbackCampaignStore.userIsAuthorized || dealsCampaignStore.userIsAuthorized;
  return userIsAuthorized ? <Router>
    <div className="app">
      <ModalProvider>
        <Switch>
          <Route path="/edit-text"><Redirect to="/advertising-campaign-support/edit-text"></Redirect></Route>
          <Route path="/" exact={true}><Redirect to="/advertising-campaign-support"></Redirect></Route>
          <Route path={"/not-found"}><NotFoundPage /></Route>
          <Route path="/advertising-campaign-support" exact={true}><AdvertisingOverviewPage /></Route>
          <Route path="/advertising-campaign-support/advertising-campaign/" exact={true}><AdvertisingCampaignEditPage /></Route>
          <Route path="/advertising-campaign-support/advertising-campaign/:campaignId" ><AdvertisingCampaignEditPage /></Route>
          <Route path="/cashback-campaign-support" exact={true} ><CashbackOverviewPage /></Route>
          <Route path="/cashback-campaign-support/cashback-campaign/" exact={true}><NewCashbackPage /></Route>
          <Route path="/cashback-campaign-support/cashback-campaign/:campaignId" exact={true}><EditCashbackPage /></Route>
          <Route path="/cashback-campaign-support/cashback-campaign/:campaignId/media" exact={true}><UploadCashbackImagesPage /></Route>
          <Route path="/deals-campaign-support" exact={true} ><DealsOverviewPage /></Route>
          <Route path="/deals-campaign-support/new-campaign" exact={true}><NewDealsPage /></Route>
          <Route path="/deals-campaign-support/new-campaign/:campaignId" exact={true}><EditDealsPage /></Route>
          <Route path="/deals-campaign-support/new-campaign/view/:campaignId" exact={true}><ViewPublishedPage /></Route>
          <Route path="/redemption-requests/" exact={true}><RedemptionRequestsOverviewPage /></Route>
          <Route path="/redemption-requests/view/:id" exact={true}><ViewRedemptionRequestPage /></Route>
          { /* fallback */}
          <Route><>not found</></Route>
          <Route><Redirect to="/advertising-campaign-support"></Redirect></Route>
        </Switch>
        <div className="page-footer-content">© 2024 Tikkie - ABN AMRO.</div>
      </ModalProvider>
    </div>
  </Router> : <Router><div className="app"><UserIsNotAuthorized></UserIsNotAuthorized></div></Router>
});


