import React from 'react';
import { Navigation } from '../global/global-components';
import { CampaignWorkflowStatus } from '../../types';
import { useStore } from '../../stores/store-context';
import { observer } from 'mobx-react-lite';
import { StatusTab, TabBox } from './status-navigation.styles';

export const StatusNavigation = observer(() => {

    const { advertisingCampaignStore } = useStore();

    const setStatus = (status: CampaignWorkflowStatus) => () => advertisingCampaignStore.setCampaignStatusFilter(status);
    const status = advertisingCampaignStore.campaignStatusFilter;
    return <Navigation>
        <TabBox>
            <StatusTab active={status === CampaignWorkflowStatus.draft} onClick={setStatus(CampaignWorkflowStatus.draft)}>Concept</StatusTab>
            <StatusTab active={status === CampaignWorkflowStatus.approved} onClick={setStatus(CampaignWorkflowStatus.approved)}>Goedgekeurd</StatusTab>
            <StatusTab active={status === CampaignWorkflowStatus.active} onClick={setStatus(CampaignWorkflowStatus.active)}>Actief</StatusTab>
            <StatusTab active={status === CampaignWorkflowStatus.expired} onClick={setStatus(CampaignWorkflowStatus.expired)}>Afgelopen</StatusTab>
        </TabBox>
    </Navigation>
});
