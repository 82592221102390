import styled from 'styled-components';


/**
 * A container to host several Sections side by side
 * (like Row/Columns relation in other frameworks)
 */
export const SectionContainer = styled.div`
  display: flex;
  justify-content: stretch;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 20px;
  }

  > *:last-child {
    margin-right: 0px;
  }
`;

interface SectionProps {
  weight?: number;
  wrap?: string;
  gap?: string;
}

export const Section = styled.div<SectionProps>`
  box-sizing: border-box;
  flex-basis: ${(props) => props.weight || 0};
  background-color: #FFFFFF;
  padding-right: 18px;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 32px;
  margin-bottom: 16px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  row-gap: ${(props) => props.gap || 0};
  justify-content: space-around;
  align-items: stretch;
  border-radius: 8px;
  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &.extra-margin-top {
    margin-top: 120px;
  }
`;

export const CalendarSection = styled.div`
  padding-right: 45px;
  padding-left: 51px;
  padding-top: 45px;
  padding-bottom: 32px;
  margin-bottom: 16px;
  display: flex;
`;


type LabelValueWidthProportions = '30-70' | '50-50' | '70-30'
interface FieldLabelValueProps {
  widthProportions?: LabelValueWidthProportions,
  align?: 'left' | 'right' | 'center' | 'space-between',
}

const labelWidth = (s?: LabelValueWidthProportions) => {
  switch (s) {
    case '30-70': return '30%';
    case '50-50': return '50%';
    case '70-30': return '70%';
    default: return '30%';
  }
}

const valueWidth = (s?: LabelValueWidthProportions) => {
  switch (s) {
    case '30-70': return '70%';
    case '50-50': return '50%';
    case '70-30': return '30%';
    default: return '70%';
  }
}

export const FieldLabel = styled.div<FieldLabelValueProps>`
    flex-basis: ${(props) => labelWidth(props.widthProportions)};

    .title {
      line-height: 36px;
      font-size: 18px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: #C7C7C8;
    }
`;

const propsToFlexAlignment = (props: FieldLabelValueProps) => {
  switch (props.align) {
    case 'left': return 'flex-start';
    case 'right': return 'flex-end';
    case 'center': return 'center';
    case 'space-between': return 'space-between';
    default: return 'space-between';
  }
}

const propsToFlexGrow = (props: FieldLabelValueProps) => {
  switch (props.align) {
    case 'left':
    case 'right':
    case 'center':
      return 0;
    default: return 1;
  }
}

export const FieldValue = styled.div<FieldLabelValueProps>`
    flex-basis: ${(props) => valueWidth(props.widthProportions)};
    display: flex;
    justify-content: ${(props) => propsToFlexAlignment(props)};
    > * {
      flex-grow: ${(props) => propsToFlexGrow(props)};
    }
    .input {
        line-height: 36px;
    }

`;
