
export enum CampaignStatus {
    draft = 'draft',
    review = 'review',
    final = 'final'
}

export enum CampaignWorkflowStatus {
    draft = 'draft',
    approved = 'approved',
    active = 'active',
    expired = 'expired'
}