import styled from "styled-components";

export const ModalText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 1em;
`;

export const ModalSmallText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-bottom: 1em;
`;

export const ModalControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const ModalCampaignTitle = styled.span`
  font-weight: bold;
`;
