import Joi from 'joi';
import {
    AdvertisingCampaign,
    CampaignStatus,
    AdvertisingCampaignType,
    AdvertisingCardSize,
    DraftAdvertisingCampaign,
    AdvertisingCampaignCreationType
} from '../types';

const campaignId = Joi.string()
    .required();

export const campaignName = Joi.string()
    .label("campaignName")
    .trim(true)
    .required()
    .messages({
        'any.required': `Campagnenaam is een verplicht veld`,
        'string.empty': `Campagnenaam is een verplicht veld`
    });

    export const brandName = Joi.string()
    .label("brandName")
    .trim(true)
    .required()
    .messages({
        'any.required': `Merknaam is een verplicht veld`,
        'string.empty': `Merknaam is een verplicht veld`
    });


const active = Joi.boolean()
    .required();

const campaignType = Joi.string()
    .valid(AdvertisingCampaignType.app)
    .valid(AdvertisingCampaignType.web)
    .required();

const creationType = Joi.string()
    .valid(AdvertisingCampaignCreationType.single, AdvertisingCampaignCreationType.campaignSet)
    .default(AdvertisingCampaignCreationType.single)
    .optional();

const isoDateString = Joi.string()
    .regex(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ?$/)
    .required();

export const startDateIsoDateString = isoDateString
    .label('startDate')
    .messages({
        'any.required': `Startdatum is een verplicht veld`
    });

export const endDateIsoDateString = isoDateString
    .label('endDate')
    .messages({
        'any.required': `Einddatum is een verplicht veld`
    });

const colorString = Joi.string()
    .regex(/^[0-9A-F]{6}$/i)

export const draftUrlString = (label: string) => Joi.string()
    .allow('')
    .label(label)
    .trim(true)
    .messages({
        'any.required': `Afbeelding is een verplicht veld (${label})`,
        'string.empty': `Afbeelding is een verplicht veld (${label})`
    })
    .required();

export const urlString = (label: string) => Joi.string()
    .label(label)
    .trim(true)
    .regex(/^https:\/\//) //starts with https://
    .messages({
        'any.required': `Afbeelding is een verplicht veld (${label})`,
        'string.empty': `Afbeelding is een verplicht veld (${label})`
    })
    .required();

const cardSize = Joi.string()
    .valid(AdvertisingCardSize.small, AdvertisingCardSize.default)
    .optional()

const status = Joi.string()
    .valid(CampaignStatus.draft, CampaignStatus.final)
    .optional()

export const draftImageUrl = Joi.object({
    large: draftUrlString('imageUrl.large'),
    medium: draftUrlString('imageUrl.medium'),
    small: draftUrlString('imageUrl.small'),
}).required();

export const imageUrl = Joi.object({
    large: urlString('imageUrl.large'),
    medium: urlString('imageUrl.medium'),
    small: urlString('imageUrl.small'),
}).required();


//TODO: here the card size is always the default one so it is not required ;) (see other TODOs)
const imageBackgroundColor = colorString.when('cardSize', { is: 'small', then: Joi.required(), otherwise: Joi.optional() })
const imagePlaceholder = imageUrl.when('cardSize', { is: 'small', then: Joi.required(), otherwise: Joi.optional() })

export const languageOptionsTitle = (language: 'nl' | 'en') => Joi.string()
    .label(`language.${language}.title`)
    .trim(true)
    .required()
    .messages({
        'any.required': `Titel is een verplicht veld`,
        'string.empty': `Titel is een verplicht veld`
    });

export const languageOptionsSubTitle = (language : 'nl' | 'en') =>  Joi.string()
    .label(`language.${language}.subTitle`)
    .allow('')
    .trim(true)
    .required()
    .messages({
    'any.required': `Subtitel is een verplicht veld`,
    'string.empty': `Subtitel is een verplicht veld`
});

export const languageOptionsButton = (language : 'nl' | 'en') => Joi.string()
    .label(`language.${language}.button`)
    .trim(true)
    .required()
    .messages({
    'any.required': `Button tekst is een verplicht veld`,
    'string.empty': `Button tekst is een verplicht veld`
});

const languageOptions = (language: 'nl' | 'en') =>  Joi.object({
    title: languageOptionsTitle(language),
    subTitle: languageOptionsSubTitle(language),
    button: languageOptionsButton(language),
    imageUrl: imageUrl.optional().allow(''), //TODO: remove since it seems not used anymore?
});

const language = Joi.object({
    nl: languageOptions('nl').required(),
    en: languageOptions('en').required(),
}).required();

export const maximumTotalImpressions = Joi.number().allow('').integer().optional().positive().messages({
    'number.base' : 'Maximum aantal impressies moet een positief geheel getal zijn',
    'number.positive': 'Maximum aantal impressies moet een positief geheel getal zijn',
    'number.unsafe': 'Maximum aantal impressies moet een veilig positief geheel getal zijn'
}).label("impressions.maximumTotalImpressions");

export const maximumUniqueImpressios = Joi.number().allow('').integer().optional().positive().messages({
    'number.base' : 'Maximum Unique Impressions moet een positief geheel getal zijn',
    'number.positive': 'Maximum Unique Impressions moet een positief geheel getal zijn',
    'number.unsafe': 'Maximum Unique Impressions moet een veilig positief geheel getal zijn'
}).label("impressions.maximumUniqueImpressions")

const impressions = Joi.object({
    maximumTotalImpressions: maximumTotalImpressions,
    maximumUniqueImpressions: maximumUniqueImpressios,
    impressionsToServePerDay: Joi.optional(),
}).optional();

const cohortSchema = Joi.string().allow('').optional();

export const clickOutUrl = Joi.string()
    .label("clickOutUrl")
    .trim(true)
    .required()
    .messages({
        'any.required': `ClickOut Url is een verplicht veld`,
        'string.empty': `ClickOut Url is een verplicht veld`
    });

export const backgroundColorSchema = colorString
    .label('backgroundColor')
    .trim(true)
    .required()
    .messages({
    'any.required': `Achtergrondkleur is een verplicht veld`,
    'string.empty': `Achtergrondkleur is een verplicht veld`,
    'string.pattern.base': 'Achtergrondkleur is een HEX kleur zonder #',
});

export const textColorSchema = colorString
    .label('textColor')
    .trim(true)
    .required()
    .messages({
    'any.required': `Tekstkleur is een verplicht veld`,
    'string.empty': `Tekstkleur is een verplicht veld`,
    'string.pattern.base': `Tekstkleur is een HEX kleur zonder #`
});

export const draftWebAdvertisingCampaignSchema = Joi.object<DraftAdvertisingCampaign>({
    campaignId: campaignId.optional().allow(''),
    campaignName,
    brandName,
    startDate: startDateIsoDateString,
    endDate: endDateIsoDateString,
    campaignType,
    clickOutUrl,
    imageUrlNL: draftUrlString('imageUrlNL').optional(),
    imageUrlEN: draftUrlString('imageUrlEN').optional(),
    gifUrl: draftUrlString('gifUrl').optional(),
    impressions,
    status, // Hidden status field
    active: active.optional(), // Hidden active field
    cardSize, // cardSize is set by campaignType
}).required();

export const webAdvertisingCampaignSchema = Joi.object<AdvertisingCampaign>({
    campaignId: campaignId,
    campaignName,
    brandName,
    startDate: startDateIsoDateString,
    endDate: endDateIsoDateString,
    campaignType,
    clickOutUrl,
    imageUrlNL: urlString('imageUrlNL'),
    imageUrlEN: urlString('imageUrlEN'),
    gifUrl: urlString('gifUrl').optional(),
    impressions,
    status, // Hidden status field
    active: active.optional(), // Hidden active field
    cardSize, // cardSize is set by campaignType
}).required();

export const draftAdvertisingCampaignSchema = Joi.object<DraftAdvertisingCampaign>({
    campaignId: campaignId.optional().allow(''),
    campaignName,
    brandName,
    campaignType,
    status,
    logoUrl: draftUrlString('logoUrl').optional(),
    imageUrl: draftImageUrl.optional(),
    startDate: startDateIsoDateString,
    endDate: endDateIsoDateString,
    imageBackgroundColor,
    imagePlaceholder,
    active: active.optional(),
    impressions,
    textColor: textColorSchema,
    backgroundColor: backgroundColorSchema,
    clickOutUrl,
    language,
    cardSize,
    creationType,
    cohort: cohortSchema,
    campaignContent: Joi.object().optional(),
}).required();

export const advertisingCampaignSchema = Joi.object<AdvertisingCampaign>({
    campaignId,
    campaignName,
    brandName,
    campaignType,
    status,
    logoUrl: urlString('logoUrl'),
    imageUrl: imageUrl,
    startDate: startDateIsoDateString,
    endDate: endDateIsoDateString,
    imageBackgroundColor,
    imagePlaceholder,
    active,
    impressions,
    textColor: textColorSchema,
    backgroundColor: backgroundColorSchema,
    clickOutUrl,
    language,
    cardSize,
    creationType,
    cohort: cohortSchema,
    campaignContent: Joi.object().optional(),
}).required();

export const validateAdvertisingCampaign = (campaign: Partial<DraftAdvertisingCampaign>) => campaign.status === CampaignStatus.draft ?
    validateAsDraftAdvertisingCampaign(campaign) :
    validateAsFinalAdvertisingCampaign(campaign);

export const validateAsDraftAdvertisingCampaign = (campaign: Partial<DraftAdvertisingCampaign>) => campaign.campaignType === AdvertisingCampaignType.app ?
draftAdvertisingCampaignSchema.validate(campaign, { abortEarly: false }) : // App
draftWebAdvertisingCampaignSchema.validate(campaign, { abortEarly: false }); // Web

export const validateAsFinalAdvertisingCampaign = (campaign: Partial<DraftAdvertisingCampaign>) => campaign.campaignType === AdvertisingCampaignType.app ?
advertisingCampaignSchema.validate(campaign, { abortEarly: false }) :
webAdvertisingCampaignSchema.validate(campaign, { abortEarly: false }); // Web

export const validateSchema = (property: string | number | undefined, schema: Joi.StringSchema | Joi.NumberSchema | Joi.ObjectSchema ) => schema.validate(property, { abortEarly: false });
