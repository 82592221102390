import styled from "styled-components";
import React from "react";

export const CashbackCampaignListItem = styled.div`
  background-color: #FFFFFF;
  cursor: pointer;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-around;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  :hover {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  }

  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const CashbackCampaignListItemIcons = styled.div`
    margin-left: auto;
    svg {
        height: 28px;
        width: 28px;
        padding: 5px;
        margin-left: 10px;
        margin-right: 0;
        border: 2px solid #F4F4F4;
        border-radius: 4px;
        box-sizing: border-box;
    }
`;

export const CashbackCampaignListItemName = styled.div`
    flex-grow: 0;
    width: 371px;
    .campaign-list-item-name {
        font-size: 14px;
        line-height: 18px;
    }

`;


export const CashbackCampaignListItemStatus = styled.div`
    flex-grow: 1;
    padding-left: 45px;
    .campaign-list-item-name {
        font-size: 14px;
        line-height: 18px;
    }
`;

export const CashbackCampaignListTypeItem = styled.div`
    flex-grow: 0;
    width: 100px;
    .campaign-list-item-name {
        font-size: 14px;
        line-height: 18px;
        color: #6A6BD4;
    }
`;


export const CashbackCampaignListItemDates = styled.div`
    width: 200px;
    .campaign-list-item-label {
        font-size: 14px;
        line-height: 18px;
        color: #BEBEBF;
        margin-bottom: 4px;
    }

    .campaign-list-item-dot {
        font-size: 14px;
        color: #6A6BD4;
    }
`;

export const CashbackCampaignListHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  color: #949494;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-radius: 8px;
  
  div {
    // flex-grow: 1;
  }
  
  .campaign-list-header-campaign-id {
    width: 390px;
    padding-left: 19px;
  }
  .campaign-list-header-type {
    width: 100px;
    display: flex;
    flex-direction: row;
  }
  .campaign-list-header-duration {
    width: 245px;
    display: flex;
    flex-direction: row;
  }
  .campaign-list-header-status {
    display: flex;
    flex-direction: row;
  }
  * {
      align-items: center;
  }
`;

const SortButtonsDiv = styled.span`
  cursor: pointer;
  font-size: 10px;
  margin-top: 1px;
  flex-direction: column;
  display: flex;
  padding-left: 5px;
  justify-content: center;
`;

interface SortButtonsProps {
    setSortAscending: (sortAscending: boolean) => void;
    setSortField: () => void;
}

export const SortButtons = ({setSortField, setSortAscending}: SortButtonsProps) => {
    return <SortButtonsDiv>
        <span onClick={() => {setSortField(); setSortAscending(true) }}>▲</span>
        <span onClick={() => {setSortField(); setSortAscending(false) }}>▼</span>
    </SortButtonsDiv>;
}
