import { Configuration } from "./types";

const BEARER_TOKEN_STORAGE_KEY = 'token';
const REACT_APP_BASE_API_URL_ADVERTISING_SERVICE = 'REACT_APP_BASE_API_URL_ADVERTISING_SERVICE';
const REACT_APP_BASE_API_URL_CASHBACK_SERVICE = 'REACT_APP_BASE_API_URL_CASHBACK_SERVICE';
const REACT_APP_BASE_API_URL_DEALS_SERVICE = 'REACT_APP_BASE_API_URL_DEALS_SERVICE';
const REACT_APP_ENV = 'REACT_APP_ENV';

const requiredEnvVar = (name: string) => {
    if (process.env[name] === undefined) {
        throw new Error(`env var ${name} is required `)
    }
    return process.env[name]!;
}

const optionalEnvVar = (name: string) => {
    return process.env[name]!;
}

export const getConfiguration = () => {
    const config: Configuration = {
        isLocal: optionalEnvVar(REACT_APP_ENV) === 'local',
        baseUrlAdvertisingService: requiredEnvVar(REACT_APP_BASE_API_URL_ADVERTISING_SERVICE),
        baseUrlCashbackService: requiredEnvVar(REACT_APP_BASE_API_URL_CASHBACK_SERVICE),
        baseUrlDealsService: requiredEnvVar(REACT_APP_BASE_API_URL_DEALS_SERVICE),
        bearerTokenKey: BEARER_TOKEN_STORAGE_KEY
    };
    return config;
}
