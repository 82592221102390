import React from "react";
import styled from "styled-components";

const CenteredWarningSectionContent = styled.div`
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &.extra-margin-top {
    margin-top: 120px;
  }
`;

const CenteredWarningSectionWrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-color: orange;
  border-style: solid;
  padding-right: 18px;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 16px !important;
  border-radius: 8px;
  position: relative;
`;


export const CenteredWarningSection: React.FC<{}> = (props) =>
    <CenteredWarningSectionWrapper>
        <CenteredWarningSectionContent>
            {props.children}
        </CenteredWarningSectionContent>
    </CenteredWarningSectionWrapper>
