import { FieldLabel, FieldValue, Section, SectionContainer } from "../../global/sections";
import {
    InputBox,
    InputBoxBackgroundImage,
    NumberInputBox,
    ToggleComponent,
    ValidationErrorIndication
} from "../../global/forms";
import PercentActiveIcon from "../../../images/euro-off-percent-on.svg";
import EuroActiveIcon from "../../../images/euro-on-percent-off.svg";
import React from "react";
import {
    CashbackCampaignDictionaryList,
    CashbackCampaignMerchantList,
    CashbackReceiptPart
} from "../../../types/cashback-campaign";
import { ValidationError } from "joi";
import { Header2, SubTitle } from "../../global/global-components";
import { DictionaryBuilder } from "./dictionary-builder";
import { MerchantBuilder } from "./merchant-builder";
import { DEFAULT_INSTRUCTIONS, AVAILABLE_MERCHANTS } from "../default-data";

const filterMerchantsFromSelectedMerchants = (merchantOptions: CashbackCampaignMerchantList, selectedMerchants: CashbackCampaignMerchantList | undefined): CashbackCampaignMerchantList => {
    if (!selectedMerchants?.images.length) return merchantOptions;
    const filteredMerchants = [];
    for (let i = 0; i < merchantOptions.images.length; i++) {
        // If selectedMerchants doesn't contain that specific merchant option, it's still unselected and therefore a valid option
        const merchantName = merchantOptions.images[i].label;
        if (!selectedMerchants.images.find(selectedMerchant => selectedMerchant.label === merchantName)) filteredMerchants.push(merchantOptions.images[i]);
    }

    return {
        title: merchantOptions.title,
        images: filteredMerchants
    };
}

interface FormReceiptPartProps {
    formData: Partial<CashbackReceiptPart>;
    validationErrors?: ValidationError;
    updateForm: (part: Partial<CashbackReceiptPart>) => void;
}
export const FormReceiptPart = ({ formData, validationErrors, updateForm }: FormReceiptPartProps) => {

    const handleMerchants = (newMerchants: CashbackCampaignMerchantList | undefined): void => {
        if (formData.merchants !== undefined && !newMerchants) {
            delete formData.merchants;
            updateForm(formData);
        } else updateForm({ merchants: newMerchants });
    };

    const handleProducts = (newProducts: CashbackCampaignDictionaryList | undefined): void => {
        if (formData.products !== undefined && !newProducts) {
            delete formData.products;
            updateForm(formData);
        } else updateForm({ products: newProducts });
    };

    const updateReceiptText = (value: string) => updateForm({ 'receiptText': value.split(';') })
    const updateTrimmedReceiptText = (value: string) => updateForm({ 'receiptText': value.split(';').map(s => s.trim()) });

    return (
        <div>
            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Bedrag van teruggave</div>
                        <div className="description">Keuze uit percentage of vast bedrag</div>
                    </FieldLabel>
                    <FieldValue style={{ alignContent: "center", alignItems: "center", columnGap: "17px" }} widthProportions="30-70">
                        <ToggleComponent customStyle={{ flexShrink: 2, flexGrow: 0 }} toggleOnSvg={PercentActiveIcon} toggleOffSvg={EuroActiveIcon} value={formData.hasVariableCashbackAmount} onChange={newValue => updateForm({ hasVariableCashbackAmount: newValue, cashbackPercentage: undefined, cashbackAmount: undefined })} />
                        {formData.hasVariableCashbackAmount ?
                            <NumberInputBox
                                customStyle={{ backgroundPosition: "center left 7px", paddingLeft: "55px", flexGrow: 1 }}
                                backgroundImage={InputBoxBackgroundImage.percentBlueSquareBackgroundImage}
                                value={formData.cashbackPercentage}
                                onChange={(value) => updateForm({ 'cashbackPercentage': value })} />
                            :
                            <NumberInputBox customStyle={{ backgroundPosition: "center left 7px", paddingLeft: "55px", flexGrow: 1 }}
                                backgroundImage={InputBoxBackgroundImage.euroBlueSquareBackgroundImage}
                                value={formData.cashbackAmount ? formData.cashbackAmount / 100 : 0}
                                onChange={newAmount => updateForm({ 'cashbackAmount': Math.round(newAmount! * 100) })} />
                        }
                        <ValidationErrorIndication errorKey={formData?.hasVariableCashbackAmount ? 'cashbackPercentage' : 'cashbackAmount'} validationErrors={validationErrors} />
                    </FieldValue>
                </Section>
            </SectionContainer>
            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Min. prijs op bon</div>
                        <div className="description">Prijs op bon</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <NumberInputBox customStyle={{ backgroundPosition: "center left 15px" }} backgroundImage={InputBoxBackgroundImage.euro} value={formData.receiptMinPrice!} onChange={value => updateForm({ 'receiptMinPrice': value })} />
                            <ValidationErrorIndication errorKey="receiptMinPrice" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Max. prijs op bon</div>
                        <div className="description">Prijs op bon</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <NumberInputBox customStyle={{ backgroundPosition: "center left 15px" }} backgroundImage={InputBoxBackgroundImage.euro} value={formData.receiptMaxPrice!} onChange={(value) => updateForm({ 'receiptMaxPrice': value })} />
                            <ValidationErrorIndication errorKey="receiptMaxPrice" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="30-70">
                        <div className="title">Omschrijving op bon</div>
                        <div className="description">Gescheiden door een puntkomma</div>
                    </FieldLabel>
                    <FieldValue widthProportions="30-70">
                        <div>
                            <InputBox
                                customStyle={{ backgroundPosition: "center left 15px" }}
                                value={formData.receiptText?.join(';') || ''}
                                onChange={updateReceiptText}
                                onBlur={updateTrimmedReceiptText} />
                            <ValidationErrorIndication errorKey="receiptText" validationErrors={validationErrors} />
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
            <Header2>Campagne Content</Header2>
            <SubTitle>Hoe werkt deze actie?</SubTitle>
            <SectionContainer>
                <Section wrap="wrap" gap="37px">
                    <DictionaryBuilder dictionaryList={formData.instructions || DEFAULT_INSTRUCTIONS} dictionaryTitle='Instructies' dictionaryListUpdated={(instructions) => updateForm({ instructions })} />
                </Section>
            </SectionContainer>
            <SubTitle>Actieproducten</SubTitle>
            <SectionContainer>
                <Section wrap="wrap" gap="37px">
                    <DictionaryBuilder dictionaryList={formData.products} dictionaryTitle='Producten' dictionaryListUpdated={(products) => handleProducts(products)} stepPrefix='Product' maxItems={20} minItems={0} />
                </Section>
            </SectionContainer>
            <SubTitle>Deelnemende winkels</SubTitle>
            <SectionContainer>
                <MerchantBuilder
                    merchants={formData.merchants}
                    merchantTitle='Deelnemende winkels'
                    merchantOptions={filterMerchantsFromSelectedMerchants(AVAILABLE_MERCHANTS, formData.merchants)}
                    merchantOptionsUpdated={(merchants) => handleMerchants(merchants)} />
            </SectionContainer>
        </div>
    )
}