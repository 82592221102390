import { Configuration } from '../types'
import { CashbackCampaign, DraftCashbackCampaign } from "../types/cashback-campaign";
import fetch, { Response } from "node-fetch";
import { UnAuthorizedError } from "./advertising-api-service";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createCashbackApiService = (configuration: Configuration) => {

    const { bearerTokenKey, baseUrlCashbackService } = configuration

    const getHeaders = () => ({
        'Authorization': `Bearer ${localStorage.getItem(bearerTokenKey)}`,
        'Content-Type': 'application/json'
    });

    const baseFetchOptions = () => ({ method: 'GET', headers: getHeaders() });

    const checkUnAuthorized = (response: Response) => {
        if (response.status === 403) {
            throw new UnAuthorizedError('UnAuthorized', response.status)
        }
        return response as Response;
    };

    const multipartFormFetchOptions = (formData: FormData) => {
        return {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(bearerTokenKey)}`,
            },
            method: 'POST',
            body: formData
        }
    }

    return {
        getCampaigns: async () => {
            return fetch(`${baseUrlCashbackService}/cashback/draft`, baseFetchOptions())
                .then(checkUnAuthorized)
                .then(response => {
                    return response.json()
                })
        },
        publishCampaign: async (campaignId: string): Promise<CashbackCampaign> => {
            const result = await fetch(`${baseUrlCashbackService}/cashback/campaigns/${campaignId}/publish`, baseFetchOptions())
            return result.json();
        },
        getDraftCampaign: async (campaignId: string): Promise<DraftCashbackCampaign> => {
            const result = await fetch(`${baseUrlCashbackService}/cashback/draft/${campaignId}`, baseFetchOptions())
            return result.json();
        },
        uploadSource: async (campaignId: string, sourceIdentifier: string, file: File) => {
            const formData = new FormData();
            formData.append(sourceIdentifier, file);
            const options = multipartFormFetchOptions(formData);
            return fetch(`${baseUrlCashbackService}/cashback/draft/${campaignId}/media`, options as any)
                .then(checkUnAuthorized)
                .then(response => response.json())
        },

        postCampaign: async (campaign: DraftCashbackCampaign): Promise<DraftCashbackCampaign> => {
            console.log('Saving campaign: ', campaign);
            const result = await fetch(`${baseUrlCashbackService}/cashback/draft`, {
                ...baseFetchOptions(),
                method: 'PUT',
                body: JSON.stringify(campaign)
            });
            const response = await result.json();
            console.log('Response from server', response);
            if (result.status >= 200 && result.status < 300) {
                return response;
            } else {
                throw new Error(response.message);
            }
        },

        deleteCampaign: async (campaign: CashbackCampaign) => {
            console.log('Deleting campaign', campaign);
        },

        sendPreview: async (payload: { campaign: CashbackCampaign, userToken: string }) => {
            console.log('Sending preview campaign', payload);
        }
    };
}
