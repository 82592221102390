import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router';
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { RootStore } from './stores/root-store';
import { StoreProvider } from './stores/store-context';
import { App } from './App';
import 'react-datepicker/dist/react-datepicker.css';
import {User} from "./types/user";

const rootStore = new RootStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, rootStore.routerStore);

//TODO: _history and _keepAlive are sent from parent of this microfrontend but we do nothing with it :P
(window as any).renderCampaigns = (containerId: string, _history: any, _keepALive: any, user: User) => {
  rootStore.userStore.setCampaignRoles(user.campaignRoles);
  document.body.className = 'showScroll';
  ReactDOM.render(
    <StoreProvider value={rootStore}><Router history={history}><App /></Router></StoreProvider>,
    document.getElementById(containerId)
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

(window as any).unmountCampaigns = (containerId: string) => {
  rootStore.userStore.clear();
  document.body.className = 'hideScroll';
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
};
