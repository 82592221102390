import {
  CashbackCampaignDictionaryList,
  CashbackCampaignMerchantList,
} from "../../types/cashback-campaign";

export const DEFAULT_INSTRUCTIONS: CashbackCampaignDictionaryList = {
  title: {
    nl: "Hoe werkt deze actie?",
    en: "Hoe werkt deze actie?",
  },
  items: [
    {
      nl: "Koop x in de supermarkt",
      en: "Koop x in de supermarkt",
    },
    {
      nl: "Neem de bon mee bij de kassa",
      en: "Neem de bon mee bij de kassa",
    },
    {
      nl: "Scan de bon en krijg 50% van het aankoopbedrag / je geld / € 2 terug",
      en: "Scan de bon en krijg 50% van het aankoopbedrag / je geld / € 2 terug",
    },
  ],
};

export const DEFAULT_PRODUCTS: CashbackCampaignDictionaryList = {
  title: {
    nl: "Actieproducten",
    en: "Actieproducten",
  },
  items: [],
};

export const AVAILABLE_MERCHANTS: CashbackCampaignMerchantList = {
  title: {
    nl: "Deelnemende winkels",
    en: "Deelnemende winkels",
  },
  images: [
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/albertheijn_s.png",
        medium: "https://campaigns.tikkie.me/merchants/albertheijn_m.png",
        large: "https://campaigns.tikkie.me/merchants/albertheijn_l.png",
      },
      label: "Albert Heijn",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/albertheijntg_s.png",
        medium: "https://campaigns.tikkie.me/merchants/albertheijntg_m.png",
        large: "https://campaigns.tikkie.me/merchants/albertheijntg_l.png",
      },
      label: "Albert Heijn To Go",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/aldi_s.png",
        medium: "https://campaigns.tikkie.me/merchants/aldi_m.png",
        large: "https://campaigns.tikkie.me/merchants/aldi_l.png",
      },
      label: "Aldi",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/attent_s.png",
        medium: "https://campaigns.tikkie.me/merchants/attent_m.png",
        large: "https://campaigns.tikkie.me/merchants/attent_l.png",
      },
      label: "Attent",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/avia_s.png",
        medium: "https://campaigns.tikkie.me/merchants/avia_m.png",
        large: "https://campaigns.tikkie.me/merchants/avia_l.png",
      },
      label: "Avia",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/boni_s.png",
        medium: "https://campaigns.tikkie.me/merchants/boni_m.png",
        large: "https://campaigns.tikkie.me/merchants/boni_l.png",
      },
      label: "Boni",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/boon_s.png",
        medium: "https://campaigns.tikkie.me/merchants/boon_m.png",
        large: "https://campaigns.tikkie.me/merchants/boon_l.png",
      },
      label: "Boon",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/bp_s.png",
        medium: "https://campaigns.tikkie.me/merchants/bp_m.png",
        large: "https://campaigns.tikkie.me/merchants/bp_l.png",
      },
      label: "BP",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/coop_s.png",
        medium: "https://campaigns.tikkie.me/merchants/coop_m.png",
        large: "https://campaigns.tikkie.me/merchants/coop_l.png",
      },
      label: "Coop",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/deka_s.png",
        medium: "https://campaigns.tikkie.me/merchants/deka_m.png",
        large: "https://campaigns.tikkie.me/merchants/deka_l.png",
      },
      label: "Deka",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/dirk_s.png",
        medium: "https://campaigns.tikkie.me/merchants/dirk_m.png",
        large: "https://campaigns.tikkie.me/merchants/dirk_l.png",
      },
      label: "Dirk",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/esso_s.png",
        medium: "https://campaigns.tikkie.me/merchants/esso_m.png",
        large: "https://campaigns.tikkie.me/merchants/esso_l.png",
      },
      label: "Esso",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/etos_s.png",
        medium: "https://campaigns.tikkie.me/merchants/etos_m.png",
        large: "https://campaigns.tikkie.me/merchants/etos_l.png",
      },
      label: "Etos",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/hoogvliet_s.png",
        medium: "https://campaigns.tikkie.me/merchants/hoogvliet_m.png",
        large: "https://campaigns.tikkie.me/merchants/hoogvliet_l.png",
      },
      label: "Hoogvliet",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/janlinders_s.png",
        medium: "https://campaigns.tikkie.me/merchants/janlinders_m.png",
        large: "https://campaigns.tikkie.me/merchants/janlinders_l.png",
      },
      label: "Jan Linders",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/jumbo_s.png",
        medium: "https://campaigns.tikkie.me/merchants/jumbo_m.png",
        large: "https://campaigns.tikkie.me/merchants/jumbo_l.png",
      },
      label: "Jumbo",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/kiosk_s.png",
        medium: "https://campaigns.tikkie.me/merchants/kiosk_m.png",
        large: "https://campaigns.tikkie.me/merchants/kiosk_l.png",
      },
      label: "Kiosk",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/kruidvat_s.png",
        medium: "https://campaigns.tikkie.me/merchants/kruidvat_m.png",
        large: "https://campaigns.tikkie.me/merchants/kruidvat_l.png",
      },
      label: "Kruidvat",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/lidl_s.png",
        medium: "https://campaigns.tikkie.me/merchants/lidl_m.png",
        large: "https://campaigns.tikkie.me/merchants/lidl_l.png",
      },
      label: "Lidl",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/mcd_s.png",
        medium: "https://campaigns.tikkie.me/merchants/mcd_m.png",
        large: "https://campaigns.tikkie.me/merchants/mcd_l.png",
      },
      label: "MCD",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/nettorama_s.png",
        medium: "https://campaigns.tikkie.me/merchants/nettorama_m.png",
        large: "https://campaigns.tikkie.me/merchants/nettorama_l.png",
      },
      label: "Nettorama",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/plus_s.png",
        medium: "https://campaigns.tikkie.me/merchants/plus_m.png",
        large: "https://campaigns.tikkie.me/merchants/plus_l.png",
      },
      label: "Plus",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/poiesz_s.png",
        medium: "https://campaigns.tikkie.me/merchants/poiesz_m.png",
        large: "https://campaigns.tikkie.me/merchants/poiesz_l.png",
      },
      label: "Poiesz",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/shell_s.png",
        medium: "https://campaigns.tikkie.me/merchants/shell_m.png",
        large: "https://campaigns.tikkie.me/merchants/shell_l.png",
      },
      label: "Shell",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/spar_s.png",
        medium: "https://campaigns.tikkie.me/merchants/spar_m.png",
        large: "https://campaigns.tikkie.me/merchants/spar_l.png",
      },
      label: "Spar",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/sparexpress_s.png",
        medium: "https://campaigns.tikkie.me/merchants/sparexpress_m.png",
        large: "https://campaigns.tikkie.me/merchants/sparexpress_l.png",
      },
      label: "Spar Express",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/tango_s.png",
        medium: "https://campaigns.tikkie.me/merchants/tango_m.png",
        large: "https://campaigns.tikkie.me/merchants/tango_l.png",
      },
      label: "TanGo",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/totalenergies_s.png",
        medium: "https://campaigns.tikkie.me/merchants/totalenergies_m.png",
        large: "https://campaigns.tikkie.me/merchants/totalenergies_l.png",
      },
      label: "TotalEnergies",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/vomar_s.png",
        medium: "https://campaigns.tikkie.me/merchants/vomar_m.png",
        large: "https://campaigns.tikkie.me/merchants/vomar_l.png",
      },
      label: "Vomar",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/intratuin_s.png",
        medium: "https://campaigns.tikkie.me/merchants/intratuin_m.png",
        large: "https://campaigns.tikkie.me/merchants/intratuin_l.png",
      },
      label: "Intratuin",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/Trekpleister_s.png",
        medium: "https://campaigns.tikkie.me/merchants/Trekpleister_m.png",
        large: "https://campaigns.tikkie.me/merchants/Trekpleister_l.png",
      },
      label: "Trekpleister",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/DA_s.png",
        medium: "https://campaigns.tikkie.me/merchants/DA_m.png",
        large: "https://campaigns.tikkie.me/merchants/DA_l.png",
      },
      label: "DA",
    },
    {
      imageUrl: {
        small: "https://campaigns.tikkie.me/merchants/Blokker_s.png",
        medium: "https://campaigns.tikkie.me/merchants/Blokker_m.png",
        large: "https://campaigns.tikkie.me/merchants/Blokker_l.png",
      },
      label: "Blokker",
    },
    {
      imageUrl : {
        small: "https://campaigns.tikkie.me/merchants/Stach_S.png",
        medium: "https://campaigns.tikkie.me/merchants/Stach_L.png",
        large: "https://campaigns.tikkie.me/merchants/Stach_M.png",
      },
      label: "Stach",
    },
    {
      imageUrl : {

        small: "https://campaigns.tikkie.me/merchants/Holland_Barrett_S.png",
        medium: "https://campaigns.tikkie.me/merchants/Holland_Barrett_M.png",
        large: "https://campaigns.tikkie.me/merchants/Holland_Barrett_L.png",
      },
      label: "Holland Barrett",
    },
  ],
};
