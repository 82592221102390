import {observer} from 'mobx-react-lite';
import React from 'react';
import {Show} from '../components';
import {useStore} from '../stores/store-context';
import {Row} from '../components/global/global-components';
import {RedemptionRequestsList} from "../components/redemption-requests-list";

export const RedemptionRequestsOverviewPage = observer(() => {
    const { redemptionRequestStore } = useStore();

    return <div className="page">
        <h2 className="pageLabel">Deals & Tomra requests</h2>
        <Row style={{marginBottom: '40px'}}>
        </Row>
        <RedemptionRequestsList />
        <Show when={redemptionRequestStore.apiServiceError !== undefined}>
            {redemptionRequestStore.apiServiceError}
        </Show>
    </div>
});
