import styled from "styled-components";

export const AdvertisingCampaignListItem = styled.div`
  background-color: #FFFFFF;
  cursor: pointer;
  padding-right: 45px;
  padding-left: 51px;
  padding-top: 45px;
  padding-bottom: 32px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  :hover {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  }

  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const AdvertisingCampaignListItemIcon = styled.div`
    width: 48px;
`;

export const AdvertisingCampaignListItemName = styled.div`
    flex-grow: 1;
    .campaign-list-item-name {
        font-size: 22px;
    }

    .campaign-list-item-type {
        font-size: 16px;
        line-height: 24px;
        color: #BEBEBF;
    }
`;

export const AdvertisingCampaignListItemDate = styled.div`
    width: 30%;
    .campaign-list-item-label {
        font-size: 14px;
        line-height: 16px;
        color: #BEBEBF;
        margin-bottom: 4px;
    }

    .campaign-list-item-date {
        font-size: 22px;
    }
`;
