import React, { useEffect, useState } from 'react';

interface DelayedMessageProps {
  delay: number;
  delayMessage?: string;
  message: string;
}
export const DelayedMessage = (props: DelayedMessageProps) => {
  const { delay, message, delayMessage } = props;
  const [currentMessage, setCurrentMessage] = useState<string>(delayMessage || '');
  const handle = setTimeout(() => setCurrentMessage(message), delay);
  useEffect(() => clearTimeout(handle))
  return <span>{currentMessage} </span>
}
