import { useState } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { useStore } from "../../stores/store-context";
import { CampaignMediaConfiguration, ImageMedia } from "../../types";
import { Show } from "../show";
import { FieldLabel, FieldValue, Section, SectionContainer } from "./sections";
import { SingleFileUpload } from "./single-file-upload";
import { FullScreenModal } from './modal';
import { renderModal } from "./campaign-media-form/render-modal";

type ObjectLike = Record<string, string | number | Record<string, any>>;

export enum SaveState {
  notSaving,
  pending,
  errored,
  succeeded,
}

interface UploadCampaignImagesFormProps {
  campaign: ObjectLike;
  campaignConfiguration: CampaignMediaConfiguration;
}

const valueFromPropertyPath = (obj: any, propertyPath: string) => {
  const segments = propertyPath.split('.');
  const toValue = (o: any | undefined, segment: string, index: number) => {
    switch (true) {
      case o === undefined: return undefined;
      case index === segments.length - 1 || typeof o === 'object': return o[segment];
      default: return undefined;
    }
  }
  return segments.reduce(toValue, obj);
}

export const CampaignMediaForm = (props: UploadCampaignImagesFormProps) => {
  const { imageMedia } = props.campaignConfiguration;
  const { campaign } = props;
  const { cashbackApiService } = useStore();
  const history = useHistory();
  const [selectedPropertyPath, setSelectedPropertyPath] = useState<{ propertyPath: string; value: string; } | undefined>(undefined);
  const [saveState, setSaveState] = useState<{ state: SaveState, message?: string }>({ state: SaveState.notSaving });

  const toAffectedPropertyPathDisplay = (propertyPath: string,) => {
    const value = valueFromPropertyPath(campaign, propertyPath) as string;
    const displayValue = (value || '').split('/').reverse()[0];
    return <div key={propertyPath} style={{ marginTop: '0.5em', cursor: 'pointer' }} onClick={() => setSelectedPropertyPath(value ? { value, propertyPath } : undefined)}>
      <FieldLabel widthProportions="30-70">
        <div className="title">{propertyPath}</div>
      </FieldLabel>
      <FieldValue widthProportions="30-70">{displayValue}</FieldValue>
    </div>
  }

  const refreshPage = () => {
    history.go(0);
  }

  const resetModalRelatedProperties = () => {
    setSelectedPropertyPath(undefined);
    setSaveState({ state: SaveState.notSaving });
  }

  const uploadSource = (sourceIdentifier: string, file: File) => {
    setSaveState({ state: SaveState.pending });
    cashbackApiService.uploadSource(campaign.id as string, sourceIdentifier, file)
      .then((response) => {
        const isErrorResponse = (response: any) => response.status > 299;
        if (isErrorResponse(response)) {
          console.log(response);
          setSaveState({ state: SaveState.errored, message: String(response.details) });
        } else {
          setSaveState({ state: SaveState.succeeded });
        }
      })
      .catch(error => {
        console.log(error);
        setSaveState({ state: SaveState.errored, message: String(error) });
      })
  }

  const toImageMediaForm = (imageMedia: ImageMedia) => {
    const { sourceIdentifier, affectedPropertyPaths, description, title } = imageMedia;
    return <div key={sourceIdentifier}>
      <h3>{title}</h3>
      <p>{description}</p>
      <SectionContainer>
        <Section style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Col md={6}>
            {affectedPropertyPaths.map(toAffectedPropertyPathDisplay)}
          </Col>
          <Col md={6}>
            <SingleFileUpload hintText="Kies een bestand als bron" title={sourceIdentifier} disabled={false} doCommit={uploadSource} sourceIdentifier={sourceIdentifier} />
          </Col>
        </Section>
      </SectionContainer>
    </div >
  }

  const mustShowModal = selectedPropertyPath !== undefined
    || saveState.state !== SaveState.notSaving;

  return <div>
    {imageMedia!.map(toImageMediaForm)}
    <Show when={mustShowModal}>
      <FullScreenModal onClick={resetModalRelatedProperties}>
        {renderModal(saveState, refreshPage, resetModalRelatedProperties, selectedPropertyPath)}
      </FullScreenModal>
    </Show>
  </div >
}




