const DEFAULT_MAX_DURATION_SECONDS = 30;
const DEFAULT_RETRY_INTERVAL_SECONDS = 3;

export class RetryService {
  private readonly maxDurationSeconds: number;

  private readonly retryIntervalSeconds: number;
  constructor(settings?: { maxDurationSeconds?: number; retryIntervalSeconds?: number; }) {
    this.maxDurationSeconds = settings?.maxDurationSeconds ?? DEFAULT_MAX_DURATION_SECONDS;
    this.retryIntervalSeconds = settings?.retryIntervalSeconds ?? DEFAULT_RETRY_INTERVAL_SECONDS;
  }

  /**
   * Timeout provided promise when maxDurationSeconds is exceeded
   */
  private async timeout<T extends unknown>(promise: Promise<T>): Promise<T> {
    let id;
    const timeoutMs = this.maxDurationSeconds * 1000;
    const start = (): Promise<any> =>
      new Promise((_resolve, reject) => {
        id = setTimeout(() => {
          reject(new Error(`Timed out after ${timeoutMs} ms.`));
        }, timeoutMs);
      });
    try {
      const result = await Promise.race([promise, start()]);
      return result;
    } finally {
      clearTimeout(id);
    }
  }

  /**
   * Wait until retryIntervalSeconds have passed
   */
  private async wait(): Promise<'done'> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('done');
      }, this.retryIntervalSeconds * 1000);
    });
  }

  /**
   * Retry functionToCall until:
   * (1) it's call succeeds AND
   * (2) the validator passes
   */
  async retryUntil <T extends unknown[], O extends unknown, U extends O>(
    functionToCall: (...args: T) => Promise<O> | O,
    functionArguments: T,
    validator: (input: O) => input is U,
  ): Promise<U> {
    const retry = async (): Promise<U> => {
      try {
        const response = await functionToCall(...functionArguments);
        if (validator(response)) {
          return response;
        }
        await this.wait();
        return retry();
      } catch (err: unknown) {
        await this.wait();
        return retry();
      }
    }
    return this.timeout(retry());
  }
}
