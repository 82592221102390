import React from "react";
import { FieldLabel, FieldValue } from "../../global/sections";
import { InputBoxElement } from "../../global/forms";
import { CashbackCampaignDictionaryList, LanguageDictionary } from "../../../types/cashback-campaign";
import { Button } from "react-bootstrap";
import styled from "styled-components";

const StepControls = styled.div`
    text-align: right;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 1em;
`

interface DictionaryBuilderStepProps {
    stepIndex: number;
    step: LanguageDictionary;
    updateStep: (index: number, step: LanguageDictionary) => void;
    stepPrefix: string;
    newItemHint?: string;
}

export const DictionaryBuilderStep = ({stepIndex, step, updateStep, stepPrefix, newItemHint}: DictionaryBuilderStepProps) => {

    const updateForm = (value: string) => {
        const newValue = {
            nl: value,
            en: value
        };
        updateStep(stepIndex, newValue);
    }

    return (
        <div style={{ 'width': '100%' }}>
            <FieldLabel key={`label-${stepIndex}`}>
                <div className="title" key={stepIndex} >{stepPrefix === '' ? 'Stap' : stepPrefix} {stepIndex + 1}</div>
            </FieldLabel>
            <FieldValue key={`value-${stepIndex}`}>
                <div>
                    <InputBoxElement key={stepIndex} placeholder={newItemHint ?? 'Nieuwe stap'} value={step.nl} onChange={(event) => updateForm(event.target.value)} />
                </div>
            </FieldValue>
        </div>
    )
}

interface DictionaryBuilderProps {
    dictionaryList?: CashbackCampaignDictionaryList;
    dictionaryTitle: string;
    dictionaryListUpdated: (dictionaryList: CashbackCampaignDictionaryList | undefined) => void;
    stepPrefix?: string;
    newItemHint?: string;
    maxItems?: number;
    minItems?: number;
}

export const DictionaryBuilder = ({ dictionaryList, dictionaryTitle, dictionaryListUpdated, stepPrefix = '', newItemHint, maxItems = 5, minItems = 1 }: DictionaryBuilderProps) => {

    const updateStep = (index: number, step: LanguageDictionary) => {
        const newDictionaryList = { ...dictionaryList! };
        newDictionaryList.items[index] = step;
        dictionaryListUpdated(newDictionaryList);
    }

    const addStep = () => {
        if (dictionaryList?.items.length === maxItems) return;

        let newDictionary;
        if (!dictionaryList) {
            newDictionary = {
                title: {
                    en: dictionaryTitle,
                    nl: dictionaryTitle
                },
                items: []
            };
        } else newDictionary = { ...dictionaryList };
        newDictionary.items.push({
            nl: '',
            en: ''
        })
        dictionaryListUpdated(newDictionary);
    }

    const removeStep = () => {
        if (dictionaryList?.items.length === minItems) return;
        if (dictionaryList?.items.length === 1) {
            dictionaryListUpdated(undefined)
        }
        else if (dictionaryList) {
            const newDictionaryList = { ...dictionaryList };
            newDictionaryList.items = dictionaryList.items.slice(0, dictionaryList.items.length - 1);
            dictionaryListUpdated(newDictionaryList);
        }
    }

    const renderSteps = () => {
        return dictionaryList?.items.map((item, index) => <DictionaryBuilderStep key={`step-${index}`} newItemHint={newItemHint} stepIndex={index} step={item} updateStep={updateStep} stepPrefix={stepPrefix} />);
    }

    return (
        <div style={{ 'width': '100%' }}>
            <div>
                {renderSteps()}
            </div>
            <StepControls>
                <Button disabled={dictionaryList ? dictionaryList.items.length >= maxItems : false} key={'add'} onClick={addStep}>Extra stap</Button>
                <Button disabled={dictionaryList ? dictionaryList.items.length <= minItems : true} key={'remove'} variant={"danger"} onClick={removeStep}>Verwijder stap</Button>
            </StepControls>
        </div>
    )
}
