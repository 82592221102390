import { Configuration } from "../types";
import fetch from "node-fetch";
import { Redemption } from "../types/redemption-requets";
import { RedemptionExternalValidationResult } from "../types/redemption-external-validation";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createRedemptionRequestsApiService = (
  configuration: Configuration,
) => {
  const { bearerTokenKey, baseUrlDealsService } = configuration;

  const headers = {
    Authorization: `${localStorage.getItem(bearerTokenKey)}`,
  };

  return {
    search: async (
      searchTerm: string,
      searchValue: string,
    ): Promise<Redemption[]> => {
      const result = await fetch(`${baseUrlDealsService}/events`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          version: "1.0",
          type: "com.support.search.redemptions",
          data: {
            searchTerm: searchTerm,
            searchValue: searchValue,
          },
        }),
      });
      const json = await result.json();
      return json as unknown as Redemption[];
    },

    get: async (id: string): Promise<Redemption> => {
      const result = await fetch(`${baseUrlDealsService}/events`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          version: "1.0",
          type: "com.support.get.redemption",
          data: {
            id: id,
          },
        }),
      });
      const json = await result.json();
      return json as unknown as Redemption;
    },

    sendExternalValidation: async ({
      redemptionId,
      campaignType,
      userId,
      validationResult,
      amountInCents,
    }: {
      redemptionId: string;
      userId: string;
      validationResult: RedemptionExternalValidationResult;
      campaignType: "receipt" | "tomra" | "token";
      amountInCents: number;
    }): Promise<{}> => {
      const result = await fetch(`${baseUrlDealsService}/events`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          version: "1.0",
          type: "com.support.redemption.external.validation",
          data: {
            type: campaignType,
            requestId: redemptionId,
            supportUserId: userId,
            validationResult,
            amountInCents,
          },
        }),
      });
      if (result.status >= 200 && result.status < 300) {
        return {};
      }
      const json = await result.json();
      return json;
    },

    getImageUrl: async (imagePath: string): Promise<string> => {
      const result = await fetch(`${baseUrlDealsService}/events`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          version: "1.0",
          type: "com.support.get.receipt.image",
          data: {
            key: imagePath,
          },
        }),
      });
      const json = (await result.json()) as unknown as { url: string };
      return json.url;
    },

    getValidationDetails: async (
      bucketName: string,
      fileName: string,
    ): Promise<string> => {
      const result = await fetch(`${baseUrlDealsService}/events`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          version: "1.0",
          type: "com.support.get.validation.details",
          data: {
            bucketName,
            fileName,
          },
        }),
      });
      const json = (await result.json()) as unknown as { url: string };
      return json.url;
    },
  };
};
