import { observer } from 'mobx-react-lite';

import React from 'react';
import { AdvertisingCampaignList, Show, StatusNavigation } from '../components';
import { useStore } from '../stores/store-context';
import { Column, PrimaryButton, Row } from '../components/global/global-components';
import { useHistory } from 'react-router-dom';
import { ButtonIconPlus } from '../components/icons';

export const AdvertisingOverviewPage = observer(() => {
    const type = 'advertising-campaign';
    const { advertisingCampaignStore } = useStore();
    const { push } = useHistory();

    const toNewCampaign = () => {
        push('advertising-campaign-support/advertising-campaign');
    }

    advertisingCampaignStore.refreshCampaigns();

    return <div className="page">
        <h2 className="pageLabel">Advertenties</h2>
        <Row>
            <Column><StatusNavigation /></Column>
            <Column style={{ textAlign: 'right' }}>
                <PrimaryButton onClick={toNewCampaign}><ButtonIconPlus style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />Nieuw</PrimaryButton>
            </Column>
        </Row>
        <AdvertisingCampaignList type={type} />
        <Show when={advertisingCampaignStore.apiServiceError !== undefined}>
            {advertisingCampaignStore.apiServiceError}
        </Show>
    </div>
});
