import { WidgetProps } from '@rjsf/utils';
import { DateTime } from 'luxon';

export const CustomDate = {
  component: CustomDateWidget,
  name: "ui:date"
}

function CustomDateWidget(props: WidgetProps) {
  const zone = "UTC"

  const { registry } = props;
  const { DateWidget } = registry.widgets as any;

  const dateOnly = (datetime: string) => DateTime.fromISO(datetime, { zone }).toISODate()

  const addTime = (date: string) => DateTime.fromISO(date, { zone }).toISO()

  const onChange = (value: string) => props.onChange(addTime(value))

  return <DateWidget {...props} value={dateOnly(props.value)} onChange={onChange} />;
};
