import {useState} from "react";
import styled from "styled-components";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend'
import {CashbackCampaignMerchantList, ReceiptCampaignMerchant} from "../../../types/cashback-campaign";
import {InteractiveMerchantBox,} from "./merchant-builder-parts/interactive-merchant-box";

const sortMerchantsByAlphabet = (merchantA: ReceiptCampaignMerchant, merchantB: ReceiptCampaignMerchant) => merchantA.label > merchantB.label ? 1 : -1;

const MerchantBuilderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: none;
    width: 100%;
`

const MerchantBoxesWrapper = styled.div`
    display: flex;
    gap: 25px;
    justify-content: space-between;
    width: 100%;
`

interface MerchantBuilderProps {
    merchants?: CashbackCampaignMerchantList,
    merchantTitle: string,
    merchantOptions: CashbackCampaignMerchantList,
    merchantOptionsUpdated: (merchants: CashbackCampaignMerchantList | undefined) => void;
}
export const MerchantBuilder = ({merchants, merchantTitle, merchantOptions, merchantOptionsUpdated}: MerchantBuilderProps) => {
    const [options, setOptions] = useState<ReceiptCampaignMerchant[]>(merchantOptions.images);
    const [previewMerchants, setPreviewMerchants] = useState<ReceiptCampaignMerchant[]>(merchants?.images || []);

    const addOption = (optionToAddIndex: number) => {
        const optionToAdd = options.find((option, optionIndex) => optionIndex === optionToAddIndex);
        if (optionToAdd) {
            const filteredOptions = options.filter((option, index) => index !== optionToAddIndex)
            setOptions(filteredOptions);
            const newPreviewMerchants = [...previewMerchants, optionToAdd];
            setPreviewMerchants(newPreviewMerchants);
            merchantOptionsUpdated({
                title: {
                    en: merchantTitle,
                    nl: merchantTitle
                },
                images: newPreviewMerchants
            });
        }
    }

    const removeOption = (optionToRemoveIndex: number) => {
        const optionToRemove = previewMerchants.find((previewMerchant, index) => index === optionToRemoveIndex);
        if (optionToRemove) {
            const filteredPreviewMerchants = previewMerchants.filter((previewMerchant, index) => index !== optionToRemoveIndex);
            setPreviewMerchants(filteredPreviewMerchants);
            setOptions([...options, optionToRemove]);
            if (!filteredPreviewMerchants.length) merchantOptionsUpdated(undefined);
            else merchantOptionsUpdated({
                title: {
                    en: merchantTitle,
                    nl: merchantTitle
                },
                images: filteredPreviewMerchants
            });
        }
    }

    const swapItems = (source: number, target: number) => {
        const newList = [...previewMerchants];
        const [reorderedItem] = newList.splice(source, 1);
        newList.splice(target, 0, reorderedItem);
        setPreviewMerchants(newList);
    }

    return (
        <MerchantBuilderWrapper style={{ 'width': '100%' }}>
            <MerchantBoxesWrapper>
                <DndProvider backend={HTML5Backend}>
                    <InteractiveMerchantBox merchants={previewMerchants} title='Preview' merchantOnClickHandler={removeOption} onDropHandler={swapItems} />
                    <InteractiveMerchantBox merchants={options.sort(sortMerchantsByAlphabet)} title='Opties' merchantOnClickHandler={addOption} />
                </DndProvider>
            </MerchantBoxesWrapper>
        </MerchantBuilderWrapper>
    );
};