import React, {createRef, useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {toJS} from 'mobx';
import {DateTime} from "luxon"
import {campaignTypeOptions, CampaignTypeSizeOption} from "../../services/advertising-campaign-service"
import {
    AdvertisingCampaignType,
    AdvertisingCardSize,
    CampaignStatus,
    CampaignWorkflowStatus,
    DraftAdvertisingCampaign,
    IsoDateString,
    Lookup
} from "../../types"
import {
    CalendarTimeComponent,
    CheckboxInput,
    ColorInputBox,
    DropDown,
    InputBox,
    UploadComponent,
    UploadMediaTypes,
    ValidationErrorIndication
} from "../global/forms"
import {FieldLabel, FieldValue, Section, SectionContainer} from "../global/sections"
import {useStore} from "../../stores/store-context"
import {ClearTextButton, Column, Header2, PrimaryButton, Row, WarningButton} from "../global/global-components"
import {PublishButtonIconPlus, SaveButtonIconPlus} from "../icons"
import {
    backgroundColorSchema,
    brandName,
    campaignName,
    clickOutUrl,
    draftUrlString,
    endDateIsoDateString,
    languageOptionsButton,
    languageOptionsSubTitle,
    languageOptionsTitle,
    maximumTotalImpressions,
    maximumUniqueImpressios,
    startDateIsoDateString,
    textColorSchema,
    validateAsDraftAdvertisingCampaign,
    validateAsFinalAdvertisingCampaign,
    validateSchema,
} from "../../validation/validate-advertising-campaign"
import {CenteredSection} from "../global/centered-section";
import {DeleteImageIcon} from "../global/delete-image-icon";
import {SendPreviewSection} from "../global/send-preview";
import Joi, {ValidationError} from "joi";
import {Spinner} from "react-bootstrap";
import {FullScreenModal} from "../global/modal";
import {ModalCampaignTitle, ModalControls, ModalSmallText, ModalText} from "../modal/modal";
import {useHistory, useLocation} from "react-router-dom";
import {CenteredWarningSection} from "../global/centered-warning-section";

const genericErrorMessage = 'Er ging iets niet helemaal goed met het publiceren van de campagne. Probeer het opnieuw of neem contact op met het Clikkie team.';

const titleStyles = {display: 'inline-block'};

const mandatoryFieldsMessageStyles = {
    display: 'inline-block',
    fontSize: '14px',
    marginLeft: '8px',
    color: 'red',
};

const cohortSchema = Joi.object({
    noCohort: Joi.boolean().valid(true, false).required(),
    cohort: Joi.string()
        .when('noCohort', {
            is: false,
            then: Joi.string().min(1).required(),
            otherwise: Joi.string().allow('').max(0).required()
        }),
}).label('cohort').messages({
    'any.required': `Cohort is een verplicht veld`,
    'string.empty': `Cohort is een verplicht veld`
});

interface EditAdvertisingCampaignProps {
    campaign?: DraftAdvertisingCampaign;
    pageLabel: string;
}

export const EditAdvertisingCampaign = observer(({campaign, pageLabel}: EditAdvertisingCampaignProps) => {
        const {advertisingCampaignStore, userStore} = useStore();
        const {push} = useHistory();
        const {pathname} = useLocation();

        const clonedCampaign: Partial<DraftAdvertisingCampaign> = campaign !== undefined ?
            toJS(campaign) :
            {
                campaignName: '',
                campaignId: '',
                campaignType: AdvertisingCampaignType.app,
                cardSize: AdvertisingCardSize.default,
                startDate: DateTime.local().startOf('day').toISO({includeOffset: false}),
                status: CampaignStatus.draft,
                active: false,
            };

        const hasCampaignEditorRights = () => userStore.campaignRoles.includes('editor');

        const hasCampaignApproverRights = () => userStore.campaignRoles.includes('approver');

        const hasCampaignBeenSavedAtLeastOnce = () => campaign !== undefined;

        const toEditCampaign = (campaignId: string) => push(`advertising-campaign/${campaignId}`);

        const toCampaignList = () => push(`/advertising-campaign-support`);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [canSaveAsDraft, setCanSaveAsDraft] = useState(false);
        const [saveError, setSaveError] = useState<any>(undefined);

        const [isPublishing, setIsPublishing] = useState<boolean>(false);
        const [isSaving, setIsSaving] = useState<boolean>(false);

        const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
        const [campaignState, setCampaignState] = useState<{
            showErrorHints: boolean,
            campaign: Partial<DraftAdvertisingCampaign>,
            draftErrors: ValidationError | undefined,
            finalCampaignErrors: ValidationError | undefined,
            images: Lookup<File>,
        }>({
            showErrorHints: clonedCampaign.campaignId !== '' && clonedCampaign.active || false,
            campaign: clonedCampaign,
            draftErrors: undefined,
            finalCampaignErrors: clonedCampaign.campaignId !== '' ? validateAsFinalAdvertisingCampaign(clonedCampaign).error : undefined,
            images: {} as Lookup<File>
        });

        const [noCohort, setNoCohort] = useState<boolean>(campaign !== undefined && (campaignState.campaign.status === CampaignStatus.draft || campaign?.active === true) && !campaignState.campaign?.cohort);

        const sendPreview = async (userToken: string) => advertisingCampaignStore.sendPreview(campaignState.campaign as DraftAdvertisingCampaign, userToken);

        const updateCampaignState = (campaign: Partial<DraftAdvertisingCampaign>, images?: Lookup<File>) => {
            setCampaignState({
                showErrorHints: campaignState.showErrorHints || (campaign.campaignId !== '' && campaign.active) || false,
                campaign: campaign,
                draftErrors: validateAsDraftAdvertisingCampaign(campaign).error,
                finalCampaignErrors: campaignState.showErrorHints || (campaign.campaignId !== '' && campaign.active) ? validateAsFinalAdvertisingCampaign(campaign).error : undefined,
                images: images || campaignState.images,
            })
        }

        const updateCampaign = (propertyPath: string) => (newPropertyValue: string | number | boolean | undefined, images?: Lookup<File>) => {

            const toParentObject = (obj: any, property: string, index: number, list: string[]) => {
                if (index === list.length - 1) {
                    return obj;
                }
                obj[property] = obj[property] || {}
                return obj[property];
            }
            const newCampaignValue: Partial<DraftAdvertisingCampaign> = {...campaignState.campaign}
            const paths = propertyPath.split('.');
            const parent = paths.length === 1 ? newCampaignValue : paths.reduce(toParentObject, newCampaignValue);
            const actualProperty = paths.reverse()[0];

            if (actualProperty === 'endDate') {
                newPropertyValue = convertDateTimeToEndOfTheDay(newPropertyValue as IsoDateString);
            }

            if (actualProperty === 'cohort' && typeof newPropertyValue === 'string') {
                setNoCohort(false);
                newPropertyValue = newPropertyValue.replaceAll(' ', '');
            }

            if (actualProperty === 'cohort' && newPropertyValue === '') {
                setNoCohort(true);
                newPropertyValue = undefined;
            }

            if ((actualProperty === 'maximumTotalImpressions' || actualProperty === 'maximumUniqueImpressions') && newPropertyValue === '') {
                newPropertyValue = undefined;
            }

            if ((actualProperty === 'maximumTotalImpressions' || actualProperty === 'maximumUniqueImpressions') && !Number.isNaN(Number(newPropertyValue))) {
                newPropertyValue = Number(newPropertyValue);
            }

            parent[actualProperty] = newPropertyValue;
            updateCampaignState(newCampaignValue, images)
        }

        const convertDateTimeToEndOfTheDay = (date: IsoDateString): IsoDateString => {
            const parsedDate = DateTime.fromISO(date);

            if (parsedDate.equals(parsedDate.startOf('day'))) {
                return DateTime.fromISO(date).endOf('day').toISO({includeOffset: false});
            }
            return date;
        }

        const updateCampaignTypeAndSize = (cardSize: AdvertisingCardSize, campaignType: AdvertisingCampaignType) => {
            const newCampaignValue: Partial<DraftAdvertisingCampaign> = {
                ...campaignState.campaign,
                cardSize,
                campaignType
            }
            updateCampaignState(newCampaignValue)
        }

        const updateImage = (property: string, file: File) => updateCampaignState(campaignState.campaign, {
            ...campaignState.images,
            [property]: file
        });
        const removeImage = (property: string) => {
            const updatedImages = {...campaignState.images};
            delete updatedImages[property];
            updateCampaign(property)('', updatedImages);
        }

        const saveCampaign = async () => {
            setSaveError(undefined);
            setIsSaving(true);
            const {
                error,
                savedCampaign
            } = await advertisingCampaignStore.saveCampaign(draftCampaign as DraftAdvertisingCampaign, campaignState.images);
            if (error) {
                setSaveError(error)
            }

            if (savedCampaign) {
                updateCampaignState(savedCampaign)
                if (!pathname.endsWith(savedCampaign.campaignId)) {
                    toEditCampaign(savedCampaign.campaignId);
                }
            }

            setIsSaving(false);
        }

        const draftCampaign = campaignState.campaign;
        const validationErrors = campaignState.finalCampaignErrors;
        const draftValidationErrors = campaignState.draftErrors;

        const [campaignNameValidationErrors, setCampaignNameValidationErrors] = useState<ValidationError | undefined>();
        const [brandNameValidationErrors, setBrandNameValidationErrors] = useState<ValidationError | undefined>();
        const [clickOutUrlValidationErrors, setClickOutUrlValidationErrors] = useState<ValidationError | undefined>();
        const [backgroundColorValidationErrors, setBackgroundColorValidationErrors] = useState<ValidationError | undefined>();
        const [textColorValidationErrors, setTextColorValidationErrors] = useState<ValidationError | undefined>();

        const [startDateValidationErrors, setStartDateValidationErrors] = useState<ValidationError | undefined>();
        const [endDateValidationErrors, setEndDateValidationErrors] = useState<ValidationError | undefined>();

        const [dutchTitleValidationErrors, setDutchTitleValidationErrors] = useState<ValidationError | undefined>();
        const [dutchSubtitleValidationErrors, setDutchSubtitleValidationErrors] = useState<ValidationError | undefined>();
        const [dutchButtonValidationErrors, setDutchButtonValidationErrors] = useState<ValidationError | undefined>();

        const [englishTitleValidationErrors, setEnglishTitleValidationErrors] = useState<ValidationError | undefined>();
        const [englishSubtitleValidationErrors, setEnglishSubtitleValidationErrors] = useState<ValidationError | undefined>();
        const [englishButtonValidationErrors, setEnglishButtonValidationErrors] = useState<ValidationError | undefined>();

        const [logoValidationErrors, setLogoValidationErrors] = useState<ValidationError | undefined>();
        const [smallImageValidationErrors, setSmallImageValidationErrors] = useState<ValidationError | undefined>();
        const [mediumImageValidationErrors, setMediumImageValidationErrors] = useState<ValidationError | undefined>();
        const [largeImageValidationErrors, setLargeImageValidationErrors] = useState<ValidationError | undefined>();

        const [maximumTotalImpressionsValidationErrors, setMaximumTotalImpressionsValidationErrors] = useState<ValidationError | undefined>();
        const [maximumUniqueImpressionsValidationErrors, setMaximumUniqueImpressionsValidationErrors] = useState<ValidationError | undefined>();

        const [cohortValidationErrors, setCohortValidationErrors] = useState<ValidationError | undefined>();

        const isFormDisabled = draftCampaign.active === true;

        const campaignIsValid = validateAsFinalAdvertisingCampaign(campaignState.campaign).error === undefined;
        const selectedOptionsIndex = campaignTypeOptions
            .findIndex(option => option.value.size === (draftCampaign.cardSize ?? AdvertisingCardSize.default) && option.value.type === draftCampaign.campaignType);

        const isCohortValid = () => {
            return draftCampaign.campaignType === AdvertisingCampaignType.app ?
                cohortSchema.validate({
                    cohort: campaignState?.campaign?.cohort || '',
                    noCohort: noCohort
                }).error === undefined :
                true // Web does not support cohorts currently, thus they are always valid
        }

        const areImagesValid = () => {
            if (draftCampaign.campaignType === AdvertisingCampaignType.app) {
                return (campaignState.images['logoUrl'] !== undefined || campaignState.campaign?.logoUrl) &&
                    (campaignState.images['imageUrl.small'] !== undefined || campaignState.campaign.imageUrl?.small) &&
                    (campaignState.images['imageUrl.medium'] !== undefined || campaignState.campaign.imageUrl?.medium) &&
                    (campaignState.images['imageUrl.large'] !== undefined || campaignState.campaign.imageUrl?.large)
            } else if (draftCampaign.campaignType === AdvertisingCampaignType.web) {
                return (campaignState.images['imageUrlNL'] !== undefined || campaignState.campaign?.imageUrlNL) &&
                    (campaignState.images['imageUrlEN'] !== undefined || campaignState.campaign?.imageUrlEN)
            }
            throw Error("AdvertisingCampaignType not recognized")
        };

        useEffect(() => {
            if (noCohort && draftCampaign.campaignType === AdvertisingCampaignType.app && draftCampaign.cohort !== undefined) {
                updateCampaign("cohort")("");
            }

            if ((!isFormDisabled || hasCampaignApproverRights()) && campaignState.draftErrors === undefined && areImagesValid() && isCohortValid()) {
                setCanSaveAsDraft(true);
            } else {
                setCanSaveAsDraft(false);
            }
        }, [isFormDisabled, campaignState, campaignState.campaign, campaignState.images, campaignState.draftErrors, campaignState?.campaign?.cohort, noCohort]);

        const transformNumberInNumberWithDots = (number: number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        };

        const transformNumberWithDotsInNumber = (numberString: string) => {
            return Number(numberString.replaceAll('.', ''));
        };

        // App image references
        const refLogo = createRef<HTMLInputElement>();
        const refSmallImage = createRef<HTMLInputElement>();
        const refMediumImage = createRef<HTMLInputElement>();
        const refLargeImage = createRef<HTMLInputElement>();

        // Web image references
        const refWebImageNL = createRef<HTMLInputElement>();
        const refWebImageEN = createRef<HTMLInputElement>();
        const refWebGIF = createRef<HTMLInputElement>();

        const renderPublishIcon = () => {
            if (isPublishing) {
                return <Spinner animation="border" role="status" size={"sm"}
                                style={{verticalAlign: 'text-bottom', marginRight: '8px'}}/>
            } else {
                return <PublishButtonIconPlus style={{verticalAlign: 'text-bottom', marginRight: '8px'}}/>
            }
        }

        const renderSaveIcon = () => {
            if (isSaving) {
                return <Spinner animation="border" role="status" size={"sm"}
                                style={{verticalAlign: 'text-bottom', marginRight: '8px'}}/>
            } else {
                return <SaveButtonIconPlus style={{verticalAlign: 'text-bottom', marginRight: '8px'}}/>
            }
        }

        const publishedEditWarningText: String = "⚠️ Deze wijzigingen zullen niet zichtbaar zijn voor gebruikers die de uiting al hebben gezien ⚠️"

        const PublishedEditWarning = () => <CenteredWarningSection>{publishedEditWarningText}</CenteredWarningSection>

        const redirectToCorrectAdvertisementTabAfterPublishing = () => {
            const now = DateTime.local();

            const startDate = DateTime.fromISO(campaignState.campaign.startDate!);
            const endDate = DateTime.fromISO(campaignState.campaign.endDate!);

            if (startDate < now && endDate > now) {
                advertisingCampaignStore.setCampaignStatusFilter(CampaignWorkflowStatus.active);
            } else if (startDate > now && endDate > now) {
                advertisingCampaignStore.setCampaignStatusFilter(CampaignWorkflowStatus.approved);
            } else if (endDate < now) { // just to be sure since we do not do validations on start date < end date ;) at least for now :P
                advertisingCampaignStore.setCampaignStatusFilter(CampaignWorkflowStatus.expired);
            }

            toCampaignList();
        }

        const confirmPublish = async () => {
            setIsPublishing(true);
            setShowPublishConfirmation(false);

            draftCampaign.active = true;

            await saveCampaign();

            if (!saveError) {
                redirectToCorrectAdvertisementTabAfterPublishing();
            }
        }

        const publish = () => {
            setShowPublishConfirmation(true);
        };

        const publishConfirmText = draftCampaign.active === true ? " is al gepubliceerd, weet je zeker dat je deze wilt aanpassen? Deze wijzigingen zullen niet zichtbaar zijn voor gebruikers die de uiting al hebben gezien" : " definitief op actief zetten?"

        const renderPublishConfirmationModal = () => {
            const campaignName = `'${campaignState.campaign.campaignName}'`;
            if (showPublishConfirmation) {
                return (
                    <FullScreenModal>
                        <ModalText><ModalCampaignTitle>{campaignName}</ModalCampaignTitle>{publishConfirmText}</ModalText>
                        <ModalControls>
                            <WarningButton onClick={() => confirmPublish()}><span
                                style={{paddingLeft: '12px'}}>Bevestigen</span></WarningButton>
                            <ClearTextButton onClick={() => {
                                setShowPublishConfirmation(false)
                            }}>Annuleren</ClearTextButton>
                        </ModalControls>
                    </FullScreenModal>
                )
            }
        }

        const renderErrorModal = () => {
            if (saveError) {
                return (
                    <FullScreenModal>
                        <ModalText>{genericErrorMessage}</ModalText>
                        <ModalSmallText>{saveError.toString()}</ModalSmallText>
                        <ModalControls>
                            <ClearTextButton onClick={() => {
                                setSaveError(undefined);
                            }}>Ok</ClearTextButton>
                        </ModalControls>
                    </FullScreenModal>
                )
            }
        }

        return <>
            <Row>
                <Column>
                    <h2 style={titleStyles} className="page-title">
                        {pageLabel}
                        <h3 style={mandatoryFieldsMessageStyles}>{'(Alle velden met een * zijn verplicht)'}</h3>
                    </h2>
                    <div>{draftCampaign.campaignId}</div>
                </Column>
                {draftCampaign.active === false &&
                    <Column style={{textAlign: "right"}}>
                        <PrimaryButton disabled={!canSaveAsDraft || isSaving || isPublishing} onClick={saveCampaign}>
                            {renderSaveIcon()} Opslaan
                        </PrimaryButton>
                    </Column>}
            </Row>
            <Section>
                <FieldLabel>
                    <div className="title">Campagnenaam*</div>
                </FieldLabel>
                <FieldValue>
                    <div>
                        <InputBox
                            placeholder="Vul hier de (werk)titel in van de advertentie"
                            disabled={isFormDisabled}
                            value={draftCampaign.campaignName!}
                            onBlur={() => {
                                setCampaignNameValidationErrors(validateSchema(draftCampaign.campaignName!, campaignName).error);
                                updateCampaign('campaignName')(draftCampaign.campaignName!)
                            }}
                            onChange={(value) => {
                                setCampaignNameValidationErrors(undefined);
                                updateCampaign('campaignName')(value);
                            }}
                        />
                        <ValidationErrorIndication errorKey="campaignName"
                                                   validationErrors={campaignNameValidationErrors || validationErrors}/>
                    </div>
                </FieldValue>
            </Section>
            <Section>
                <FieldLabel>
                    <div className="title">Merknaam*</div>
                </FieldLabel>
                <FieldValue>
                    <div>
                        <InputBox
                            placeholder="Vul hier de naam in van het merk"
                            disabled={isFormDisabled}
                            value={draftCampaign.brandName!}
                            onBlur={() => {
                                setBrandNameValidationErrors(validateSchema(draftCampaign.brandName!, brandName).error);
                                updateCampaign('brandName')(draftCampaign.brandName!)
                            }}
                            onChange={(value) => {
                                setBrandNameValidationErrors(undefined);
                                updateCampaign('brandName')(value);
                            }}
                        />
                        <ValidationErrorIndication errorKey="brandName"
                                                   validationErrors={brandNameValidationErrors || validationErrors}/>
                    </div>
                </FieldValue>
            </Section>
            <SectionContainer>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Startdatum*</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <CalendarTimeComponent
                                value={draftCampaign.startDate!}
                                onChange={(value) => {
                                    setStartDateValidationErrors(undefined);
                                    updateCampaign('startDate')(value);
                                }}
                                onBlur={() => {
                                    setStartDateValidationErrors(validateSchema(draftCampaign.startDate!, startDateIsoDateString).error);
                                    updateCampaign('startDate')(draftCampaign.startDate!)
                                }}
                            />
                            <ValidationErrorIndication errorKey="startDate"
                                                       validationErrors={startDateValidationErrors || validationErrors}/>
                        </div>
                    </FieldValue>
                </Section>
                <Section>
                    <FieldLabel widthProportions="50-50">
                        <div className="title">Einddatum*</div>
                    </FieldLabel>
                    <FieldValue widthProportions="50-50">
                        <div>
                            <CalendarTimeComponent
                                value={draftCampaign.endDate}
                                onChange={(value) => {
                                    setEndDateValidationErrors(undefined);
                                    updateCampaign('endDate')(value);
                                }}
                                onBlur={() => {
                                    setEndDateValidationErrors(validateSchema(draftCampaign.endDate!, endDateIsoDateString).error);
                                    updateCampaign('endDate')(draftCampaign.endDate!)
                                }}
                            />
                            <ValidationErrorIndication errorKey="endDate"
                                                       validationErrors={endDateValidationErrors || validationErrors}/>
                        </div>
                    </FieldValue>
                </Section>
            </SectionContainer>
            <Section>
                <FieldLabel>
                    <div className="title">Locatie van de advertentie*</div>
                </FieldLabel>
                <FieldValue>
                    <div>
                        <DropDown disabled={hasCampaignBeenSavedAtLeastOnce()} selectedIndex={selectedOptionsIndex}
                                  optionValues={campaignTypeOptions} onChange={
                            (campaignTypeSizeOption: CampaignTypeSizeOption) => {
                                updateCampaignTypeAndSize(campaignTypeSizeOption.size, campaignTypeSizeOption.type)
                            }
                        }/>
                        <ValidationErrorIndication errorKey="campaignType"
                                                   validationErrors={draftValidationErrors || validationErrors}/>
                    </div>
                </FieldValue>
            </Section>

            <Section>
                <FieldLabel>
                    <div className="title">URL*</div>
                    <div className="description">Click-out url</div>
                </FieldLabel>
                <FieldValue>
                    <div>
                        <InputBox
                            placeholder="Click out URL"
                            value={draftCampaign.clickOutUrl || ''}
                            onBlur={() => {
                                setClickOutUrlValidationErrors(validateSchema(campaignState.campaign.clickOutUrl!, clickOutUrl).error);
                                updateCampaign('clickOutUrl')(draftCampaign.clickOutUrl || '');
                            }}
                            onChange={(value) => {
                                setClickOutUrlValidationErrors(undefined);
                                updateCampaign('clickOutUrl')(value);
                            }}
                        />
                        {(isFormDisabled && draftCampaign.campaignType === AdvertisingCampaignType.app) &&
                            <div style={{ paddingTop: 12}}>
                                {publishedEditWarningText}
                            </div>
                        }
                        <ValidationErrorIndication errorKey="clickOutUrl"
                                                   validationErrors={clickOutUrlValidationErrors || validationErrors}/>
                    </div>
                </FieldValue>
            </Section>
            {draftCampaign.campaignType === AdvertisingCampaignType.app &&
                <>
                    <Section>
                        <FieldLabel>
                            <div className="title">Achtergrondkleur*</div>
                            <div className="description">Alleen in hex-waarden</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <ColorInputBox
                                    placeholder="E9E9E9"
                                    value={draftCampaign.backgroundColor || ''}
                                    onBlur={() => {
                                        setBackgroundColorValidationErrors(validateSchema(draftCampaign.backgroundColor || '', backgroundColorSchema).error);
                                        updateCampaign('backgroundColor')(draftCampaign.backgroundColor || '')
                                    }}
                                    onChange={(value) => {
                                        setBackgroundColorValidationErrors(undefined);
                                        updateCampaign('backgroundColor')(value);
                                    }}
                                />
                                {isFormDisabled &&
                                    <div style={{ paddingTop: 12}}>
                                        {publishedEditWarningText}
                                    </div>
                                }
                                <ValidationErrorIndication errorKey="backgroundColor"
                                                           validationErrors={backgroundColorValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>
                    <Section>
                        <FieldLabel>
                            <div className="title">Tekstkleur*</div>
                            <div className="description">Alleen in hex-waarden</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <ColorInputBox
                                    placeholder="E9E9E9"
                                    value={draftCampaign.textColor || ''}
                                    onChange={(value) => {
                                        setTextColorValidationErrors(undefined);
                                        updateCampaign('textColor')(value);
                                    }}
                                    onBlur={() => {
                                        setTextColorValidationErrors(validateSchema(draftCampaign.textColor || '', textColorSchema).error);
                                        updateCampaign('textColor')(draftCampaign.textColor || '');
                                    }}
                                />
                                {isFormDisabled &&
                                    <div style={{ paddingTop: 12}}>
                                        {publishedEditWarningText}
                                    </div>
                                }
                                <ValidationErrorIndication errorKey="textColor"
                                                           validationErrors={textColorValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>
                </>
            }
            <Header2>Upload hieronder de benodigde bestanden</Header2>

            {draftCampaign.campaignType === AdvertisingCampaignType.app &&
                <>
                    {isFormDisabled &&
                        <PublishedEditWarning/>
                    }
                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.logoUrl}
                            removeImage={(image) => {
                                if (refLogo.current) {
                                    refLogo.current.value = '';
                                }
                                removeImage(image);
                                setLogoValidationErrors(validateSchema(undefined, draftUrlString('logoUrl')).error);
                            }}
                            property="logoUrl"
                        />
                        <UploadComponent
                            ref={refLogo}
                            images={campaignState.images}
                            hintText=".SVG of .PNG bestand"
                            onlineUrl={draftCampaign.logoUrl}
                            property="logoUrl"
                            title="Logo*"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setLogoValidationErrors(validateSchema(image, draftUrlString('logoUrl')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="logoUrl"
                                                   validationErrors={logoValidationErrors || validationErrors}/>
                    </CenteredSection>
                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.imageUrl?.small}
                            removeImage={(image) => {
                                if (refSmallImage.current) {
                                    refSmallImage.current.value = '';
                                }
                                removeImage(image);
                                setSmallImageValidationErrors(validateSchema(undefined, draftUrlString('imageUrl.small')).error);
                            }}
                            property="imageUrl.small"
                        />
                        <UploadComponent
                            ref={refSmallImage}
                            images={campaignState.images}
                            property="imageUrl.small"
                            onlineUrl={draftCampaign.imageUrl?.small}
                            title="Advertentiebeeld voor kleine schermen*"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setSmallImageValidationErrors(validateSchema(image, draftUrlString('imageUrl.small')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="imageUrl.small"
                                                   validationErrors={smallImageValidationErrors || validationErrors}/>
                    </CenteredSection>

                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.imageUrl?.medium}
                            removeImage={(image) => {
                                if (refMediumImage.current) {
                                    refMediumImage.current.value = '';
                                }
                                removeImage(image);
                                setMediumImageValidationErrors(validateSchema(undefined, draftUrlString('imageUrl.medium')).error);
                            }}
                            property="imageUrl.medium"
                        />
                        <UploadComponent
                            ref={refMediumImage}
                            images={campaignState.images}
                            property="imageUrl.medium"
                            onlineUrl={draftCampaign.imageUrl?.medium}
                            title="Advertentiebeeld voor gemiddelde schermen*"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setMediumImageValidationErrors(validateSchema(image, draftUrlString('imageUrl.medium')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="imageUrl.medium"
                                                   validationErrors={mediumImageValidationErrors || validationErrors}/>
                    </CenteredSection>

                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.imageUrl?.large}
                            removeImage={(image) => {
                                if (refLargeImage.current) {
                                    refLargeImage.current.value = '';
                                }
                                removeImage(image);
                                setLargeImageValidationErrors(validateSchema(undefined, draftUrlString('imageUrl.large')).error);
                            }}
                            property="imageUrl.large"
                        />
                        <UploadComponent
                            ref={refLargeImage}
                            images={campaignState.images}
                            property="imageUrl.large"
                            onlineUrl={draftCampaign.imageUrl?.large}
                            title="Advertentiebeeld voor grote schermen*"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setLargeImageValidationErrors(validateSchema(image, draftUrlString('imageUrl.large')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="imageUrl.large"
                                                   validationErrors={largeImageValidationErrors || validationErrors}/>
                    </CenteredSection>
                </>}

            {draftCampaign.campaignType === AdvertisingCampaignType.web &&
                <>
                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.imageUrlNL}
                            removeImage={(image) => {
                                if (refWebImageNL.current) {
                                    refWebImageNL.current.value = '';
                                }
                                removeImage(image);
                                setLogoValidationErrors(validateSchema(undefined, draftUrlString('imageUrlNL')).error);
                            }}
                            property="imageUrlNL"
                        />
                        <UploadComponent
                            ref={refWebImageNL}
                            images={campaignState.images}
                            hintText=".SVG of .PNG bestand"
                            onlineUrl={draftCampaign.imageUrlNL}
                            property="imageUrlNL"
                            title="Afbeelding NL*"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setLogoValidationErrors(validateSchema(image, draftUrlString('imageUrlNL')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="imageUrlNL"
                                                   validationErrors={logoValidationErrors || validationErrors}/>
                    </CenteredSection>

                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.imageUrlEN}
                            removeImage={(image) => {
                                if (refWebImageEN.current) {
                                    refWebImageEN.current.value = '';
                                }
                                removeImage(image);
                                setLogoValidationErrors(validateSchema(undefined, draftUrlString('imageUrlEN')).error);
                            }}
                            property="imageUrlEN"
                        />
                        <UploadComponent
                            ref={refWebImageEN}
                            images={campaignState.images}
                            hintText=".SVG of .PNG bestand"
                            onlineUrl={draftCampaign.imageUrlEN}
                            property="imageUrlEN"
                            title="Afbeelding EN*"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setLogoValidationErrors(validateSchema(image, draftUrlString('imageUrlEN')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="imageUrlEN"
                                                   validationErrors={logoValidationErrors || validationErrors}/>
                    </CenteredSection>

                    <CenteredSection>
                        <DeleteImageIcon
                            images={campaignState.images}
                            onlineUrl={draftCampaign.gifUrl}
                            removeImage={(image) => {
                                if (refWebGIF.current) {
                                    refWebGIF.current.value = '';
                                }
                                removeImage(image);
                                setLogoValidationErrors(validateSchema(undefined, draftUrlString('gifUrl').optional()).error);
                            }}
                            property="gifUrl"
                        />
                        <UploadComponent
                            ref={refWebGIF}
                            images={campaignState.images}
                            allowedMediaTypes={[UploadMediaTypes.GIF]}
                            hintText=".GIF bestand"
                            onlineUrl={draftCampaign.gifUrl}
                            property="gifUrl"
                            title="Bedank GIF"
                            onChange={(image, file) => {
                                updateImage(image, file);
                                setLogoValidationErrors(validateSchema(image, draftUrlString('gifUrl')).error);
                            }}
                        />
                        <ValidationErrorIndication errorKey="gifUrl"
                                                   validationErrors={logoValidationErrors || validationErrors}/>
                    </CenteredSection>
                </>}

            {draftCampaign.campaignType === AdvertisingCampaignType.app &&
                <>
                    <Header2>Nederlandstalige tekst in advertentie</Header2>

                    {isFormDisabled &&
                        <PublishedEditWarning/>
                    }

                    <Section>
                        <FieldLabel>
                            <div className="title">Titel*</div>
                            <div className="description">Header van 28 characters</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Bijvoorbeeld: Koop een Coca Cola en ontvang je geld terug!"
                                    value={draftCampaign.language?.nl?.title || ''}
                                    onChange={(value) => {
                                        setDutchTitleValidationErrors(undefined);
                                        updateCampaign('language.nl.title')(value);
                                    }}
                                    onBlur={() => {
                                        setDutchTitleValidationErrors(validateSchema(draftCampaign.language?.nl?.title || '', languageOptionsTitle('nl')).error);
                                        updateCampaign('language.nl.title')(draftCampaign.language?.nl?.title || '');
                                    }}
                                    showMaxChars={true}
                                    maxChars={28}
                                />
                                <ValidationErrorIndication errorKey="language.nl.title"
                                                           validationErrors={dutchTitleValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                    <Section>
                        <FieldLabel>
                            <div className="title">Subtitel*</div>
                            <div className="description">Maximaal 70 karakters</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Subtitle"
                                    value={draftCampaign.language?.nl?.subTitle || ''}
                                    onChange={(value) => {
                                        setDutchSubtitleValidationErrors(undefined);
                                        updateCampaign('language.nl.subTitle')(value);
                                    }}
                                    onBlur={() => {
                                        setDutchSubtitleValidationErrors(validateSchema(draftCampaign.language?.nl?.subTitle || '', languageOptionsSubTitle('nl')).error);
                                        updateCampaign('language.nl.subTitle')(draftCampaign.language?.nl?.subTitle || '');
                                    }}
                                    showMaxChars={true}
                                    maxChars={70}
                                />
                                <ValidationErrorIndication errorKey="language.nl.subTitle"
                                                           validationErrors={dutchSubtitleValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                    <Section>
                        <FieldLabel>
                            <div className="title">Knop*</div>
                            <div className="description">Call to action</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Bekijk actie!"
                                    value={draftCampaign.language?.nl?.button || ''}
                                    onChange={(value) => {
                                        setDutchButtonValidationErrors(undefined);
                                        updateCampaign('language.nl.button')(value);
                                    }}
                                    onBlur={() => {
                                        setDutchButtonValidationErrors(validateSchema(draftCampaign.language?.nl?.button || '', languageOptionsButton('nl')).error);
                                        updateCampaign('language.nl.button')(draftCampaign.language?.nl?.button || '');
                                    }}
                                    showMaxChars={true}
                                    maxChars={22}
                                />
                                <ValidationErrorIndication errorKey="language.nl.button"
                                                           validationErrors={dutchButtonValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                    <Header2>Engelstalige tekst in advertentie</Header2>

                    {isFormDisabled &&
                        <PublishedEditWarning/>
                    }

                    <Section>
                        <FieldLabel>
                            <div className="title">Titel*</div>
                            <div className="description">Header van 28 characters</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Bijvoorbeeld: Buy a Coca Cola and receive your money back!"
                                    value={draftCampaign.language?.en?.title || ''}
                                    onChange={(value) => {
                                        setEnglishTitleValidationErrors(undefined);
                                        updateCampaign('language.en.title')(value);
                                    }}
                                    onBlur={() => {
                                        setEnglishTitleValidationErrors(validateSchema(draftCampaign.language?.en?.title || '', languageOptionsTitle('en')).error);
                                        updateCampaign('language.en.title')(draftCampaign.language?.en?.title || '');
                                    }}
                                    showMaxChars={true}
                                    maxChars={28}
                                />
                                <ValidationErrorIndication errorKey="language.en.title"
                                                           validationErrors={englishTitleValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                    <Section>
                        <FieldLabel>
                            <div className="title">Subtitel*</div>
                            <div className="description">Maximaal 70 karakters</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Subtitle" value={draftCampaign.language?.en?.subTitle || ''}
                                    onChange={(value) => {
                                        setEnglishSubtitleValidationErrors(undefined);
                                        updateCampaign('language.en.subTitle')(value);
                                    }}
                                    onBlur={() => {
                                        setEnglishSubtitleValidationErrors(validateSchema(draftCampaign.language?.en?.subTitle || '', languageOptionsSubTitle('en')).error);
                                        updateCampaign('language.en.subTitle')(draftCampaign.language?.en?.subTitle || '');
                                    }}
                                    showMaxChars={true}
                                    maxChars={70}
                                />
                                <ValidationErrorIndication errorKey="language.en.subTitle"
                                                           validationErrors={englishSubtitleValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                    <Section>
                        <FieldLabel>
                            <div className="title">Knop*</div>
                            <div className="description">Call to action</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Bekijk actie!"
                                    value={draftCampaign.language?.en?.button || ''}
                                    onChange={(value) => {
                                        setEnglishButtonValidationErrors(undefined);
                                        updateCampaign('language.en.button')(value);
                                    }}
                                    onBlur={() => {
                                        setEnglishButtonValidationErrors(validateSchema(draftCampaign.language?.en?.button || '', languageOptionsButton('en')).error);
                                        updateCampaign('language.en.button')(draftCampaign.language?.en?.button || '');
                                    }}
                                    showMaxChars={true}
                                    maxChars={22}
                                />
                                <ValidationErrorIndication errorKey="language.en.button"
                                                           validationErrors={englishButtonValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                    <Header2>Targeted advertising</Header2>
                    <Section>
                        <FieldLabel>
                            <div className="title">Cohort*</div>
                            <div className="description">Check bij het datateam voor de goede cohort naam</div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    customStyle={{marginBottom: '8px'}}
                                    placeholder="Cohort"
                                    value={draftCampaign?.cohort || ''}
                                    onBlur={() => {
                                        setCohortValidationErrors(cohortSchema.validate({
                                            cohort: draftCampaign?.cohort || '',
                                            noCohort: noCohort
                                        }).error);
                                    }}
                                    onChange={(value) => {
                                        updateCampaign('cohort')(value)
                                        setCohortValidationErrors(cohortSchema.validate({
                                            cohort: value,
                                            noCohort: !value
                                        }).error);
                                    }}
                                />
                                <CheckboxInput
                                    checked={noCohort}
                                    onChange={(event) => {
                                        setNoCohort(!noCohort);
                                        setCohortValidationErrors(cohortSchema.validate({
                                            cohort: "",
                                            noCohort: !noCohort
                                        }).error);
                                    }}
                                    label={"Deze advertentie is beschikbaar voor alle gebruikers"}
                                    className={""}
                                />
                                <ValidationErrorIndication errorKey="cohort"
                                                           validationErrors={cohortValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>

                </>}

            <Header2>Impressies</Header2>

            <Section>
                <FieldLabel>
                    <div className="title">Maximum aantal impressies</div>
                    <div className="description">Maximaal totaal aantal impressies waarna de campagne moet worden gestopt
                    </div>
                </FieldLabel>
                <FieldValue>
                    <div>
                        <InputBox
                            placeholder="Maximum aantal impressies"
                            value={draftCampaign?.impressions?.maximumTotalImpressions !== undefined ? transformNumberInNumberWithDots(draftCampaign?.impressions?.maximumTotalImpressions) : ''}
                            onBlur={() => {
                                setMaximumTotalImpressionsValidationErrors(validateSchema(draftCampaign?.impressions?.maximumTotalImpressions, maximumTotalImpressions).error);
                            }}
                            onChange={(value) => {
                                const number = transformNumberWithDotsInNumber(value) || '';
                                setMaximumTotalImpressionsValidationErrors(validateSchema(number, maximumTotalImpressions).error);
                                if (number <= Number.MAX_SAFE_INTEGER) {
                                    updateCampaign('impressions.maximumTotalImpressions')(number);
                                }
                            }}
                        />
                        <ValidationErrorIndication errorKey="impressions.maximumTotalImpressions"
                                                   validationErrors={maximumTotalImpressionsValidationErrors || validationErrors}/>
                    </div>
                </FieldValue>
            </Section>


            {draftCampaign.campaignType === AdvertisingCampaignType.app &&
                <>
                    <Section>
                        <FieldLabel>
                            <div className="title">Maximum unieke impressies</div>
                            <div className="description">Maximaal aantal unieke impressies waarna de campagne moet worden
                                gestopt
                            </div>
                        </FieldLabel>
                        <FieldValue>
                            <div>
                                <InputBox
                                    placeholder="Maximale unieke impressies"
                                    value={draftCampaign?.impressions?.maximumUniqueImpressions !== undefined ? transformNumberInNumberWithDots(draftCampaign?.impressions?.maximumUniqueImpressions) : ''}
                                    onBlur={() => {
                                        setMaximumUniqueImpressionsValidationErrors(validateSchema(draftCampaign?.impressions?.maximumUniqueImpressions || '', maximumUniqueImpressios).error);
                                    }}
                                    onChange={(value) => {
                                        const number = transformNumberWithDotsInNumber(value) || '';
                                        setMaximumUniqueImpressionsValidationErrors(validateSchema(number, maximumUniqueImpressios).error);
                                        if (number <= Number.MAX_SAFE_INTEGER) {
                                            updateCampaign('impressions.maximumUniqueImpressions')(number);
                                        }
                                    }}
                                />
                                <ValidationErrorIndication errorKey="impressions.maximumUniqueImpressions"
                                                           validationErrors={maximumUniqueImpressionsValidationErrors || validationErrors}/>
                            </div>
                        </FieldValue>
                    </Section>
                </>}

            <div style={{marginTop: '64px', marginBottom: '64px', textAlign: "right"}}>
                {hasCampaignApproverRights() &&
                    <WarningButton
                        style={{marginRight: '16px'}}
                        disabled={!canSaveAsDraft || isSaving || !hasCampaignBeenSavedAtLeastOnce()}
                        onClick={publish}>
                        {renderPublishIcon()} {draftCampaign.active === true ? "Aanpassingen publiceren" : "Publiceren"}
                    </WarningButton>}
                {!isFormDisabled &&
                    <PrimaryButton disabled={!canSaveAsDraft || isSaving || isPublishing} onClick={saveCampaign}>
                        {renderSaveIcon()} Opslaan
                    </PrimaryButton>}
            </div>

            {!isFormDisabled && draftCampaign.campaignType === AdvertisingCampaignType.app &&
                <SendPreviewSection disabled={isFormDisabled} campaignIsValid={campaignIsValid} sendPreview={sendPreview}/>
            }

            {renderPublishConfirmationModal()}
            {renderErrorModal()}
        </>
    }
);
