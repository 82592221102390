import Joi from "joi";

export const idSchema = Joi.string();
export const titleSchema = Joi.string().min(1);
export const typeSchema = Joi.string().min(1).valid('barcode', 'receipt', 'token');
export const brandNameSchema = Joi.string().min(1).max(50);
export const campaignNameSchema = Joi.string().min(1).max(50);
export const activeSchema = Joi.boolean().strict();
export const remittanceTestSchema = Joi.string().min(1).max(100)
    // PAIN service may reject payments with characters outside this set:
    .regex(/^[-A-Za-z0-9ÀÁÂÃÄÅÆÇÈÉÊËÎÍÌÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſ `._()*:;=?@#!$-]*$/)
    // The remittanceText must contain a marker for the payoutCommandId (for reconciliation), and may not contain any other $ sign:
    .regex(/^[^$]*\$ID[^$]*$/)
    // The remittanceText may not contain anything that looks like a payoutCommandId:
    .regex(/cb-[0-9a-zA-Z]{20,22}/, { invert: true }) // Reconciliation searches for the id based on a format 'cb-' + friendlyId. Thus we don't allow that as part of the surrounding remittanceInfo text!
    .default('Tikkie $ID');
export const ibanSchema = Joi.string().regex(/NL[0-9]{2}[A-Z]{4}[0-9]{10}/);
export const suspenseAccountCodeSchema = Joi.string().regex(/[A-Za-z0-9]+/);
export const organisationIdSchema = Joi.number().min(1);
export const dateSchema = Joi.string().regex(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/, 'date');
export const thresholdAmountSchema = Joi.number().strict().integer().min(1);
export const levenshteinDistanceSchema = Joi.number();
export const receiptTextSchema = Joi.array().items(Joi.string().min(1));
export const receiptAmount = Joi.number().strict().min(0).max(10000);
export const receiptRequiredTextSchema = Joi.string();

export const hasVariableCashbackAmountSchema = Joi.boolean().strict();

export const cashbackAmountSchema = Joi.when('hasVariableCashbackAmount', {
    not: Joi.boolean().strict().valid(true),
    then: Joi.number().strict().integer().min(1),
    otherwise: Joi.forbidden(),
});

export const cashbackPercentageSchema = Joi.when('hasVariableCashbackAmount', {
    is: Joi.boolean().strict().valid(true),
    then: Joi.number().strict().integer().min(0).max(100),
    otherwise: Joi.forbidden(),
});

export const productBarcodesSchema = Joi.array().items(Joi.string().min(1));
export const tokenValueSchema = Joi.number().strict().min(0);
export const tokenTTLSchema = Joi.number().integer();
export const tokensPerPersonSchema = Joi.number().integer();
export const tokensPerPersonTimeSpanSchema = Joi.number().integer();
export const cardDescriptionSchema = Joi.string();
export const shareTitleSchema = Joi.string();
export const termsDescriptionSchema = Joi.string().min(1);
export const termsButtonSchema = Joi.string().min(1);
export const termsUrlSchema = Joi.string().min(1);
export const faqUrlSchema = Joi.string().min(1);
export const faqButtonTextSchema = Joi.string().min(1);
export const faqDescriptionSchema = Joi.string().min(1);
export const colorSchema = Joi.string().hex().min(6).max(6);
export const nextButtonSchema = Joi.string().min(1);
export const amountOfCardsSchema = Joi.number().strict().integer();
export const amountOfPayoutsSchema = Joi.number().strict().integer();
export const totalPayoutAmountSchema = Joi.number().strict().integer();

const alarmIf =Joi.string().min(1).valid('above_absolute_value', 'above_relative_value', 'below_absolute_value', 'below_relative_value');
const budgetAlarmValue = Joi.when('alarmIf', {
    is: Joi.string().valid('above_absolute_value', 'below_absolute_value'),
    then: Joi.number().strict().integer().required(),
    otherwise: Joi.number().strict().min(0).max(1),
});
const budgetAlarmLevel =Joi.string().min(1).valid('critical', 'warning', 'info');
const budgetAlarmEntity =Joi.string().min(1).valid('availableBudgetInCents', 'reservedBudgetInCents', 'totalPaidOutInCents', 'totalFundedInCents');
const budgetAlarm = Joi.object({
    alarmIf: alarmIf.required(),
    value: budgetAlarmValue.required(),
    level: budgetAlarmLevel.optional(),
    entity: budgetAlarmEntity.optional(),
});
export const budgetAlarms = Joi.array().items(budgetAlarm).optional();