import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store-context";
import { AdvertisingCardSize, DraftAdvertisingCampaign } from "../types";
import { getCampaignTypeDescription, toFriendlyCampaignDate } from "../services/advertising-campaign-service";
import { AdvertisingCampaignListItem, AdvertisingCampaignListItemDate, AdvertisingCampaignListItemIcon, AdvertisingCampaignListItemName } from "./advertising-campaign-list.styles";
import { IconCampaignType } from "./icons";
import { useHistory } from "react-router-dom";

interface CampaignListProps {
    type: string;
}

export const AdvertisingCampaignList = observer((props: CampaignListProps) => {
    const { advertisingCampaignStore } = useStore();
    const { push } = useHistory();

    const toEditCampaign = (campaignId: string) => () => push(`advertising-campaign-support/advertising-campaign/${campaignId}`);

    const toCampaignListItem = (campaign: DraftAdvertisingCampaign) => {

        return <AdvertisingCampaignListItem key={campaign.campaignId} onClick={toEditCampaign(campaign.campaignId)} >
            <AdvertisingCampaignListItemIcon>
                <IconCampaignType type={campaign.campaignType}></IconCampaignType>
            </AdvertisingCampaignListItemIcon>
            <AdvertisingCampaignListItemName>
                <div className="campaign-list-item-name">{campaign.campaignName}</div>
                <div className="campaign-list-item-type">{getCampaignTypeDescription(campaign.campaignType, campaign.cardSize || AdvertisingCardSize.default)}</div>
            </AdvertisingCampaignListItemName>
            <AdvertisingCampaignListItemDate>
                <div className="campaign-list-item-label">Looptijd</div>
                <div className="campaign-list-item-date">{toFriendlyCampaignDate(campaign.startDate)} - {toFriendlyCampaignDate(campaign.endDate)}</div>
            </AdvertisingCampaignListItemDate>
        </AdvertisingCampaignListItem>
    }

    return <div>
        {advertisingCampaignStore.filteredCampaigns.map(toCampaignListItem)}
    </div>

})
