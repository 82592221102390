
import React from 'react';
import { Row } from '../components/global/global-components';
import { BreadCrumbNavigation } from '../components';
import { EditAdvertisingCampaign } from '../components';
import { useStore } from '../stores/store-context';
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { DelayedMessage } from '../components/global/delayed-message';

export const AdvertisingCampaignEditPage = observer(() => {

    const { advertisingCampaignStore } = useStore();
    const { campaignId } = useParams<{ campaignId: string }>()
    const isNewCampaign = campaignId === undefined;
    const campaign = !isNewCampaign ? advertisingCampaignStore.campaigns.find(advertisingCampaignStore.byId(campaignId)) : undefined;
    const pageLabel = isNewCampaign ? 'Nieuwe advertentie' : 'Advertentie wijzigen'
    return <div className="page">
        <Row>
            <BreadCrumbNavigation crumbs={[{ label: 'Campagnes' }, { link: '/', label: 'Advertentie overzicht' }, { label: pageLabel }]} />
        </Row>

        {
            campaignId === undefined ?
                <EditAdvertisingCampaign pageLabel={pageLabel} /> :
                campaign !== undefined ?
                    <EditAdvertisingCampaign campaign={campaign} pageLabel={pageLabel} /> :
                    <div><DelayedMessage message={`Er is geen campaign gevonden met id '{campaignId}'`} delay={2000} delayMessage="Bezig met ophalen" /> </div>
        }

    </div>
});

