import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
    CashbackCampaignType,
    DraftCashbackCampaign
} from "../../types/cashback-campaign";
import { ValidationError } from "joi";
import { FormMetaPart } from "./form/form-meta-part";
import { FormReceiptPart } from "./form/form-receipt-part";
import { FormTokenPart } from "./form/form-token-part";
import { FormDesignPart } from "./form/form-design-part";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { useStore } from "../../stores/store-context";
import { draftCampaignSchema } from "../../validation/validate-draft-cashback-campaign";
import { FullScreenModal } from "../global/modal";
import { ClearTextButton, PrimaryButton, WarningButton } from "../global/global-components";
import { ImageButtonIcon, SaveButtonIconPlus } from "../icons";
import { useHistory } from "react-router-dom";
import { PublishRequirements } from "./form/publish-requirements";
import { Show } from "../show";
import {ModalCampaignTitle, ModalControls, ModalText} from "../modal/modal";

const CreateFormHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

interface EditCashbackFormProps {
    cashbackCampaignId: string
}

export const EditCashbackForm = observer(({ cashbackCampaignId }: EditCashbackFormProps) => {
    const [validationErrors, setValidationErrors] = useState<ValidationError>();
    const [campaignData, setCampaignData] = useState<Partial<DraftCashbackCampaign>>({});
    const [isSaving, setIsSaving] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setError] = useState<string>();
    const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
    const [showToUploadConfirmation, setShowToUploadConfirmation] = useState(false);
    const { cashbackCampaignStore } = useStore();
    const history = useHistory();


    const toUpload = () => {
        setShowToUploadConfirmation(false);
        history.push(`/cashback-campaign-support/cashback-campaign/${cashbackCampaignId}/media`);
    }

    const updateCampaignData = (data: Partial<DraftCashbackCampaign>) => {
        const newData = { ...campaignData, ...data };
        setCampaignData(newData);
        setIsDirty(true);
    }

    const validateCampaignForDraft = () => {
        const validationResults = draftCampaignSchema.validate(campaignData, { abortEarly: false });
        setValidationErrors(validationResults.error);
    }

    const renderTypeFormPart = () => {
        switch (campaignData?.type) {
            case CashbackCampaignType.receipt:
                return <FormReceiptPart formData={campaignData!} updateForm={updateCampaignData} validationErrors={validationErrors} />
            case CashbackCampaignType.token:
                return <FormTokenPart />
            default:
                return;
        }
    }

    const storeCashback = async () => {
        setIsSaving(true);
        try {
            await cashbackCampaignStore.saveCampaign(campaignData as DraftCashbackCampaign);
            setIsDirty(false);
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(validateCampaignForDraft, [campaignData]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        cashbackCampaignStore.getCampaign(cashbackCampaignId)
            .then((campaign) => {
                if (campaign === undefined) {
                    throw new Error('something errored');
                }
                setCampaignData(campaign);
                console.log('Fetched campaign: ', campaign);
            })
            .catch((e) => {
                console.error('Failed to fetch campaign: ', e);
                history.push('/not-found');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    const renderPublishConfirmation = () => {
        if (showPublishConfirmation) {
            return (
                <FullScreenModal>
                    <ModalText><ModalCampaignTitle>{campaignData.campaignName}</ModalCampaignTitle> definitief op actief zetten?</ModalText>
                    <Show when={!isPublishing}>
                        <ModalControls>
                            <WarningButton onClick={() => publishCampaign()}><span style={{ paddingLeft: '12px' }}>Publish</span></WarningButton>
                            <ClearTextButton onClick={() => { setShowPublishConfirmation(false) }}>Annuleren</ClearTextButton>
                        </ModalControls>
                    </Show>
                    <Show when={isPublishing}>
                        <div>Bezig met publiceren, dit kan even duren</div>
                    </Show>
                </FullScreenModal>
            )
        }
    }

    const renderToUploadConfirmation = () => {
        if (showToUploadConfirmation && isDirty) {
            return (
                <FullScreenModal>
                    <ModalText><ModalCampaignTitle>Als je doorgaat worden de huidige veranderingen niet opgeslagen</ModalCampaignTitle></ModalText>
                    <ModalControls>
                        <WarningButton onClick={() => toUpload()}><span style={{ paddingLeft: '12px' }}>naar Image Upload</span></WarningButton>
                        <ClearTextButton onClick={() => setShowToUploadConfirmation(false)}>Annuleren</ClearTextButton>
                    </ModalControls>

                </FullScreenModal>
            )
        }
    }

    const publishCampaign = () => {
        setIsPublishing(true);
        cashbackCampaignStore.publishCampaign(campaignData.id!)
            .then(() => {
                console.log(`published`)
            })
            .catch((error) => setError(error))
            .finally(() => {
                setShowPublishConfirmation(false)
                setIsPublishing(false);
                console.log(`publishing done`)
            });
    }

    const renderSaveIcon = () => {
        if (isSaving) {
            return <Spinner animation="border" role="status" size={"sm"} style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />
        } else {
            return <SaveButtonIconPlus style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} />
        }
    }

    const renderControls = () => {
        const minimumValidationError = draftCampaignSchema.validate(campaignData, { allowUnknown: true }).error;
        const meetsMinimumRequirements = minimumValidationError === undefined;
        const canSave = !isSaving && meetsMinimumRequirements && isDirty
        return (
            <div style={{ paddingTop: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: '20px' }}>
                <PrimaryButton onClick={() => setShowToUploadConfirmation(true)}><ImageButtonIcon style={{ verticalAlign: 'text-bottom', marginRight: '8px' }} /> Upload</PrimaryButton>
                <PrimaryButton disabled={!canSave} onClick={storeCashback}>{renderSaveIcon()} Opslaan</PrimaryButton>
            </div>
        )
    }

    const renderForm = () => {
        if (isLoading) {
            return (<div><Spinner animation={"border"} size={"sm"} /> Loading...</div>)
        } else {

            return (
                <div>
                    <CreateFormHeader>
                        <div>
                            <h2 className="page-title">{campaignData.id}</h2>
                        </div>
                        {renderControls()}
                    </CreateFormHeader>
                    <pre>{JSON.stringify(validationErrors, null, 2)}</pre>
                    <PublishRequirements cashbackCampaign={campaignData as DraftCashbackCampaign} onClickPublish={() => setShowPublishConfirmation(true)} formIsDirty={isDirty} isPublishing={isPublishing} />
                    <FormMetaPart formData={campaignData} validationErrors={validationErrors} updateForm={updateCampaignData} />
                    {renderTypeFormPart()}
                    <FormDesignPart
                        formData={campaignData}
                        updateForm={updateCampaignData}
                        validationErrors={validationErrors} />
                    {renderControls()}
                    {renderPublishConfirmation()}
                    {renderToUploadConfirmation()}
                </div>
            )
        }
    }

    return (renderForm())
});
