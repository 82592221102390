import { WidgetProps } from "@rjsf/utils";
import { CSSProperties } from "styled-components";
import { Merchant } from "../../../types";

export const MerchantSelect = {
  component: MerchantsSelectComponent,
  name: "MerchantSelect"
}

interface NonSelectedMerchantsProps {
  merchants: Merchant[]
  readonly?: boolean
  onChange: (m: Merchant) => void
}

function MerchantSelectPanel(props: NonSelectedMerchantsProps) {
  const backgroundColorStyle: CSSProperties = props.readonly ? { backgroundColor: '#e9ecef' } : { backgroundColor: 'white' }
  const borderStyle: CSSProperties = { ...backgroundColorStyle, border: '1px solid #ced4da', borderRadius: ".25rem" }
  const pointerStyle: CSSProperties = props.readonly ? {} : { cursor: "pointer" }
  const imgStyle: CSSProperties = { ...pointerStyle, width: "90px", margin: "15px" }

  const handleClick = (item: Merchant) => {
    if (props.readonly) {
      return
    }

    props.onChange(item)
  }

  return (
    <div style={borderStyle}>
      {
        props.merchants.map((item: any, index: number) => (
          <img
            style={imgStyle}
            key={`merchants-${index}`}
            alt={item.label}
            src={item.small}
            onClick={() => handleClick(item)}
          />
        ))
      }
    </div>
  )
}

function MerchantsSelectComponent(props: WidgetProps) {
  if (!props.schema.items) {
    return <div></div>
  }

  // TODO: understand how to type this
  const { enum: enumSchema } = props.schema.items as unknown as { enum: Merchant[] }

  if (!enumSchema?.length) {
    return <div></div>
  }

  const merchants = enumSchema.filter((merchant: Merchant) => !props.value.find((item: Merchant) => item.label === merchant.label))

  const gridStyle: CSSProperties = props.readonly ? {} : { display: "grid", columnGap: "1rem", gridTemplateColumns: "1fr 1fr", gridTemplateAreas: "item item" }

  return (
    <>
      <div style={gridStyle}>
        {!props.readonly &&
          <MerchantSelectPanel
            merchants={merchants}
            onChange={(item) => props.onChange([...props.value, item])}
          />
        }

        <MerchantSelectPanel
          merchants={props.value}
          onChange={(item) => props.onChange(props.value.filter((v: Merchant) => v.label !== item.label))}
          readonly={props.readonly}
        />
      </div>
    </>
  );
}
