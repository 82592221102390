import { useEffect, useState } from 'react';
import { FieldProps } from '@rjsf/utils';

export const MultiLanguageInputField = {
  component: MultiLanguageInputFieldComponent,
  name: "ui:multilanguageinput"
}

function MultiLanguageInputFieldComponent(props: FieldProps) {
  const { name, idSchema, schema, required } = props

  const [en, setEn] = useState("")
  const [nl, setNl] = useState("")

  const onEnChange = (event: any) => {
    const { value } = event.target

    if (!value && !props.formData?.nl) {
      setEn("")
      props.onChange(undefined)
      return
    }

    props.onChange({ nl: props.formData?.nl, en: value })
  }

  const onNlChange = (event: any) => {
    const { value } = event.target

    if (!value && !props.formData?.en) {
      setNl("")
      props.onChange(undefined)
      return
    }

    props.onChange({ en: props.formData?.en, nl: value })
  }

  const isEnRequired = () => {
    return required || schema.required?.includes("en") || (!en && !!nl)
  }

  const isNlRequired = () => {
    return required || schema.required?.includes("nl") || (!nl && !!en)
  }

  useEffect(() => {
    setEn(props.formData?.en)
    setNl(props.formData?.nl)
  }, [props.formData?.en, props.formData?.nl])

  return (
    <div className="form-group field field-object">
      <legend id={`${idSchema.$id}__title`}>{name}</legend>

      <div style={{ display: "flex" }}>
        <div className="form-group field field-string">
          <label className="control-label" htmlFor={idSchema.en?.$id}>EN{isEnRequired() && "*"}</label>
          <input id={idSchema.en?.$id} name={idSchema.en?.$id} className="form-control" onChange={onEnChange} value={en} required={isEnRequired()} />
        </div>

        <div className="form-group field field-string">
          <label className="control-label" htmlFor={idSchema.nl?.$id}>NL{isNlRequired() && "*"}</label>
          <input id={idSchema.nl?.$id} name={idSchema.nl?.$id} className="form-control" onChange={onNlChange} value={nl} required={isNlRequired()} />
        </div>
      </div>

    </div>)
};
