import { GenericObjectType } from "@rjsf/utils";
import { CashbackCampaign } from "./cashback-campaign";

export interface DealCampaign {
  active: boolean;
  endDate: string;
  startDate: string;
  uiData: UiData;
  id: string;
  campaignData: CampaignData;
  type: string;
  budget?: Budget;
}

export type SortableTypesDealsCampaign = CashbackCampaign[
  | "id"
  | "type"
  | "startDate"];

export enum DealType {
  RECEIPT = "receipt",
  TOMRA = "tomra",
}

export enum DealStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export type SortableTypesTomraCampaign = DealCampaign[
  | "id"
  | "type"
  | "startDate"];

export interface Budget {
  availableInCents: number;
  reservedInCents: number;
  totalPaidOutInCents: number;
  totalFundedInCents: number;
  updatedDateTime: string;
}

export interface UiData {
  campaignUiData: CampaignUiData;
  genericUiData: GenericUiData;
  cardUiData: CardUiData;
}

export interface CampaignUiData {
  faqUrl: FaqUrl;
  faqButtonText: FaqButtonText;
  imageUrl: ImageUrl;
  faqDescription: FaqDescription;
  title: Title;
  actionButtonText: ActionButtonText;
  informationTitle: InformationTitle;
}

export interface FaqUrl {
  en: string;
  nl: string;
}

export interface FaqButtonText {
  en: string;
  nl: string;
}

export interface ImageUrl {
  large: string;
  small: string;
  medium: string;
}

export interface FaqDescription {
  en: string;
  nl: string;
}

export interface Title {
  en: string;
  nl: string;
}

export interface ActionButtonText {
  en: string;
  nl: string;
}

export interface InformationTitle {
  en: string;
  nl: string;
}

export interface GenericUiData {
  backgroundColorHex: string;
  circleColorHex: string;
  textColorHex: string;
}

export interface CardUiData {
  buttonText: ButtonText;
  senderName: string;
  title: Title2;
  imageUrl: ImageUrl2;
}

export interface ButtonText {
  en: string;
  nl: string;
}

export interface Title2 {
  en: string;
  nl: string;
}

export interface ImageUrl2 {
  large: string;
  small: string;
  medium: string;
}

export interface CampaignData {
  brandName: string;
  budgetAlarms: any[];
  organisationId: string;
  suspenseAccountCode: string;
  tomraSecretId: string;
  billingIban: string;
  senderIban: string;
  campaignName: string;
  remittanceText: string;
  cashbackAmount?: number;
  receiptMaxPrice?: number;
  receiptMinPrice?: number;
  cashbackPercentage?: number;
  hasVariableCashbackAmount?: boolean;
}

export interface Merchant extends GenericObjectType {
  small: string;
  medium: string;
  large: string;
  label: string;
}
