import { RJSFSchema } from "@rjsf/utils";

import { AVAILABLE_MERCHANTS } from "../../components/cashback-campaign/deals-data";
import { CustomDate } from "../../components/rjsf/widgets/CustomDate";
import { MerchantSelect } from "../../components/rjsf/widgets/MerchantsSelect";
import { MultiLanguageInputField } from "../../components/rjsf/fields/MultiLanguageInputField";

export const tomraUiSchema = {};

export const tomraSchema: RJSFSchema = {
  title: "Tomra campaign form",
  description: "",
  type: "object",
  required: ["active", "startDate", "endDate", "id", "type"],
  properties: {
    active: {
      type: "boolean",
      title: "Active",
      default: false,
    },
    type: {
      type: "string",
      title: "type",
      default: "tomra",
    },
    id: {
      type: "string",
      title: "id",
    },
    startDate: {
      type: "string",
      format: "date-time",
      title: "start Date",
    },
    endDate: {
      type: "string",
      format: "date-time",
      title: "end Date",
    },
    campaignData: {
      title: "Campaign Data",
      description: "",
      type: "object",
      required: [
        "tomraSecretId",
        "senderIban",
        "suspenseAccountCode",
        "remittanceText",
        "organisationId",
        "billingIban",
        "campaignName",
        "brandName",
      ],
      properties: {
        budgetAlarms: {
          type: "array",
          items: {
            type: "object",
            properties: {
              alarmIf: {
                type: "string",
                enum: [
                  "above_absolute_value",
                  "above_relative_value",
                  "below_absolute_value",
                  "below_relative_value",
                ],
              },
              entity: {
                type: "string",
                enum: [
                  "availableBudgetInCents",
                  "reservedBudgetInCents",
                  "totalPaidOutInCents",
                  "totalFundedInCents",
                ],
              },
              value: {
                type: "number",
              },
              level: {
                type: "string",
                enum: ["critical", "warning", "info"],
              },
            },
            required: ["alarmIf", "entity", "value", "level"],
          },
        },
        billingIban: {
          title: "billing Iban",
          type: "string",
          default: "NL00ABNA0987654321",
        },
        campaignName: {
          title: "campaign Name",
          type: "string",
        },
        brandName: {
          title: "brand Name",
          type: "string",
        },
        organisationId: {
          title: "organisation Id",
          type: "string",
        },
        remittanceText: {
          title: "remittance Text",
          type: "string",
        },
        senderIban: {
          title: "sender Iban",
          type: "string",
        },
        suspenseAccountCode: {
          title: "suspense Account Code",
          type: "string",
          default: "CB1",
        },
        tomraSecretId: {
          title: "tomra Secret Id",
          type: "string",
        },
      },
    },
    uiData: {
      title: "UI Data",
      description: "this section will be in the app under ...",
      type: "object",
      properties: {
        genericUiData: {
          title: "genericUiData",
          type: "object",
          required: ["backgroundColorHex", "circleColorHex", "textColorHex"],
          properties: {
            backgroundColorHex: {
              title: "backgroundColorHex",
              type: "string",
            },
            circleColorHex: {
              title: "circleColorHex",
              type: "string",
            },
            textColorHex: {
              title: "textColorHex",
              type: "string",
            },
          },
        },
        campaignUiData: {
          title: "campaign UI Data",
          description: "this section will be in the app under ...",
          type: "object",
          properties: {
            actionButtonText: {
              title: "actionButtonText",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            faqButtonText: {
              title: "faqButtonText",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            faqUrl: {
              title: "faqUrl",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            imageUrl: {
              title: "imageUrl",
              type: "object",
              required: ["large", "medium", "small"],
              properties: {
                large: {
                  title: "large",
                  type: "string",
                },
                medium: {
                  title: "medium",
                  type: "string",
                },
                small: {
                  title: "small",
                  type: "string",
                },
              },
            },
            informationTitle: {
              title: "informationTitle",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            title: {
              title: "title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
          },
        },
        cardUiData: {
          title: "card UI Data",
          description: "this section will be in the app under ...",
          type: "object",
          properties: {
            buttonText: {
              title: "buttonText",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            imageUrl: {
              title: "imageUrl",
              type: "object",
              required: ["large", "medium", "small"],
              properties: {
                large: {
                  title: "large",
                  type: "string",
                },
                medium: {
                  title: "medium",
                  type: "string",
                },
                small: {
                  title: "small",
                  type: "string",
                },
              },
            },
            senderName: {
              title: "senderName",
              type: "string",
            },
            title: {
              title: "title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
};

export const receiptUiSchema = {
  startDate: {
    "ui:widget": CustomDate.name,
  },
  endDate: {
    "ui:widget": CustomDate.name,
  },
  uiData: {
    campaignUiData: {
      subtitle: {
        "ui:field": MultiLanguageInputField.name,
      },
      merchants: {
        "ui:widget": MerchantSelect.name,
      },
    },
  },
};

export const receiptSchema: RJSFSchema = {
  definitions: {
    Target: {
      title: "Target",
      type: "string",
      anyOf: [
        {
          type: "string",
          enum: ["PUBLIC"],
          title: "Public",
        },
        {
          type: "string",
          enum: ["HIDDEN"],
          title: "Hidden",
        },
      ],
    },
  },
  title: "Receipt campaign form",
  description: "",
  type: "object",
  required: ["active", "startDate", "endDate", "id", "type"],
  properties: {
    active: {
      type: "boolean",
      title: "Active",
      default: false,
    },
    type: {
      type: "string",
      title: "type",
      default: "receipt",
    },
    id: {
      type: "string",
      title: "id",
    },
    startDate: {
      type: "string",
      format: "date-time",
      title: "start Date",
    },
    endDate: {
      type: "string",
      format: "date-time",
      title: "end Date",
    },
    campaignData: {
      title: "Campaign Data",
      description: "",
      type: "object",
      required: [
        "senderIban",
        "suspenseAccountCode",
        "remittanceText",
        "organisationId",
        "billingIban",
        "campaignName",
        "brandName",
        "receiptText",
        "receiptMinPrice",
        "receiptMaxPrice",
        "maximumLevenshteinDistance",
        "visibleFromDate",
        "visibleToDate",
        "target",
      ],

      if: {
        properties: {
          hasVariableCashbackAmount: {
            const: true,
          },
        },
      },
      then: {
        properties: {
          cashbackPercentage: {
            title: "Percentage Cashback (0-100)",
            type: "integer",
          },
        },
        required: ["cashbackPercentage"],
      },
      else: {
        properties: {
          cashbackAmount: {
            title: "Cashback Amount (cents)",
            type: "integer",
          },
        },
        required: ["cashbackAmount"],
      },
      properties: {
        target: {
          $ref: "#/definitions/Target",
          title: "Target",
        },
        billingIban: {
          title: "billing Iban",
          type: "string",
        },
        campaignName: {
          title: "campaign Name",
          type: "string",
        },
        brandName: {
          title: "brand Name",
          type: "string",
        },
        organisationId: {
          title: "organisation Id",
          type: "string",
        },
        visibleFromDate: {
          type: "string",
          format: "date-time",
          title: "visible From Date",
        },
        visibleToDate: {
          type: "string",
          format: "date-time",
          title: "visible To Date",
        },
        remittanceText: {
          title: "remittance Text",
          type: "string",
          default: "Tikkie $ID",
        },
        senderIban: {
          title: "sender Iban",
          type: "string",
        },
        suspenseAccountCode: {
          title: "suspense Account Code",
          type: "string",
        },
        receiptMinPrice: {
          title: "Receipt min price (euro)",
          type: "number",
        },
        receiptMaxPrice: {
          title: "Receipt max price (euro)",
          type: "number",
        },
        maximumLevenshteinDistance: {
          title: "Maximum levenshtein distance",
          default: 2,
          type: "number",
        },
        hasVariableCashbackAmount: {
          title: "Use Percentage Cashback",
          type: "boolean",
          default: false,
        },
        budgetAlarms: {
          type: "array",
          items: {
            type: "object",
            properties: {
              alarmIf: {
                type: "string",
                enum: [
                  "above_absolute_value",
                  "above_relative_value",
                  "below_absolute_value",
                  "below_relative_value",
                ],
              },
              entity: {
                type: "string",
                enum: [
                  "availableBudgetInCents",
                  "reservedBudgetInCents",
                  "totalPaidOutInCents",
                  "totalFundedInCents",
                ],
              },
              value: {
                type: "number",
              },
              level: {
                type: "string",
                enum: ["critical", "warning", "info"],
              },
            },
            required: ["alarmIf", "entity", "value", "level"],
          },
        },
        receiptText: {
          type: "array",
          title: "Receipt text",
          items: {
            type: "string",
            default: "",
          },
        },
        duplicationsThresholds: {
          title: "Duplications Thresholds",
          description: "",
          type: "object",
          properties: {
            receiptIsDuplicateDateTime4CC: {
              title: "Time and Total Duplication Threshold",
              type: "number",
              default: 1,
            },
            receiptIsDuplicateTimeTerminalId: {
              title: "Date, time & terminal ID duplicates allowed",
              type: "number",
              default: 1,
            },
            receiptIsDuplicateTimeTotal: {
              title: "Date, time & total duplicates allowed",
              type: "number",
              default: 1,
            },
            receiptIsDuplicateTransaction: {
              title: "Date, time & transaction ID duplicates allowed",
              type: "number",
              default: 1,
            },
          },
        },
      validationsConfigurations: {
          title: "Validation Configurations",
          description: "",
          type: "object",
          properties: {
              maxFailedAttempts: {
              title: "Max Failed Attempts",
              minimum: 1,
              type: "number",
              default: 10,
            },
          },
        },
      },
    },
    uiData: {
      title: "UI Data",
      description: "this section will be in the app under ...",
      type: "object",
      properties: {
        genericUiData: {
          title: "genericUiData",
          type: "object",
          required: ["backgroundColorHex", "circleColorHex", "textColorHex"],
          properties: {
            backgroundColorHex: {
              title: "backgroundColorHex",
              type: "string",
            },
            circleColorHex: {
              title: "circleColorHex",
              type: "string",
            },
            textColorHex: {
              title: "textColorHex",
              type: "string",
            },
          },
        },
        campaignUiData: {
          title: "campaign UI Data",
          description: "this section will be in the app under ...",
          type: "object",
          properties: {
            shareTitle: {
              title: "share Title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            shareUrl: {
              title: "share Url",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            actionButtonText: {
              title: "actionButtonText",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                  default: "Scan the receipt!",
                },
                nl: {
                  title: "nl",
                  type: "string",
                  default: "Scan het bonnetje!",
                },
              },
            },
            // TODO: this should not be required per design, but it's required in the app
            infoText: {
              title: "info Text",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            pillText: {
              title: "pill Text",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                  default: "Pill Text",
                },
                nl: {
                  title: "nl",
                  type: "string",
                  default: "Pil Tekst",
                },
              },
            },
            productTitle: {
              title: "product Title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                  //TODO: change this in the en translation
                  default: "Deze producten doen mee 🔥",
                },
                nl: {
                  title: "nl",
                  type: "string",
                  default: "Deze producten doen mee 🔥",
                },
              },
            },
            subtitle: {
              title: "subtitle",
              type: "object",
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            products: {
              type: "array",
              title: "products",
              items: {
                type: "string",
                default: "",
              },
            },
            instructionsTitle: {
              title: "instructions Title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                  //TODO: change this in the en translation
                  default: "Hoe werkt deze cashback? 💸",
                },
                nl: {
                  title: "nl",
                  type: "string",
                  default: "Hoe werkt deze cashback? 💸",
                },
              },
            },
            instructions: {
              type: "array",
              title: "instructions",
              default: [
                {
                  en: "Koop het actieproduct bij deelnemende winkel(s)",
                  nl: "Koop het actieproduct bij deelnemende winkel(s)",
                },
                {
                  en: "Neem de bon mee bij de kassa",
                  nl: "Neem de bon mee bij de kassa",
                },
                {
                  en: "Ga in de Tikkie app naar de deal",
                  nl: "Ga in de Tikkie app naar de deal",
                },
                {
                  en: "Scan de bon en krijg direct je geld terug",
                  nl: "Scan de bon en krijg direct je geld terug",
                },
              ],
              items: {
                type: "object",
                properties: {
                  en: {
                    title: "en",
                    type: "string",
                  },
                  nl: {
                    title: "nl",
                    type: "string",
                  },
                },
              },
            },
            merchantsTitle: {
              title: "merchants Title",
              type: "object",
              default: {},
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                  // TODO: change this in the en translation
                  default: "Bij deze winkels📍",
                },
                nl: {
                  title: "nl",
                  type: "string",
                  default: "Bij deze winkels📍",
                },
              },
            },
            merchants: {
              type: "array",
              title: "merchants",
              items: {
                enum: AVAILABLE_MERCHANTS,
              },
            },
            termsUrl: {
              title: "terms Url",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            campaignImage: {
              title: "campaign Image",
              type: "object",
              required: ["large", "medium", "small"],
              properties: {
                large: {
                  title: "large",
                  type: "string",
                },
                medium: {
                  title: "medium",
                  type: "string",
                },
                small: {
                  title: "small",
                  type: "string",
                },
              },
            },
            informationTitle: {
              title: "informationTitle",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            backgroundColor: {
              title: "background Color",
              type: "string",
            },
            title: {
              title: "title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            placement: {
              type: "string",
              title: "Placement",
              description: "Placement of the deal in the deals overview page",
              enum: ["top"]
            },
            imageUrlFullWidth: {
              title: "Full width card image",
              type: "object",
              description: "Used on the top deal card in the deals overview page",
              properties: {
                large: {
                  title: "large",
                  type: "string",
                },
                medium: {
                  title: "medium",
                  type: "string",
                },
                small: {
                  title: "small",
                  type: "string",
                },
              },
            },
          },
        },
        cardUiData: {
          title: "card UI Data",
          description: "this section will be in the app under ...",
          type: "object",
          properties: {
            buttonText: {
              title: "buttonText",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            title: {
              title: "title",
              type: "object",
              required: ["en", "nl"],
              properties: {
                en: {
                  title: "en",
                  type: "string",
                },
                nl: {
                  title: "nl",
                  type: "string",
                },
              },
            },
            imageUrl: {
              title: "imageUrl",
              type: "object",
              required: ["large", "medium", "small"],
              properties: {
                large: {
                  title: "large",
                  type: "string",
                },
                medium: {
                  title: "medium",
                  type: "string",
                },
                small: {
                  title: "small",
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
};
