export enum CashbackCampaignType {
    receipt = 'receipt',
    barcode = 'barcode',
    token = 'token',
}

export type SortableTypesCashbackCampaign = CashbackCampaign["id" | "type" | "startDate"]

export interface CashbackMetaPart {
    id: string,
    type: CashbackCampaignType, //
    brandName: string, //
    campaignName: string, //
    active: boolean, //
    remittanceText: string,
    senderIban: string,
    suspenseAccountCode: string,
    billingIban: string,
    organisationId: string, //
    startDate: string, //
    endDate: string, //
    startViewDate: string, //
    endViewDate: string;
    reconTxStoredEndDate: string;
    lastPayoutCommandId: string;
    advertisementId: string,
    dealsAdvertisementId: string;
    thresholdAmount: number;
    thresholdAlarmAmount?: number;
}

export interface LanguageDictionary {
    nl: string;
    en: string;
}

export interface CashbackCampaignDictionaryList {
    title: LanguageDictionary;
    items: LanguageDictionary[];
}

export interface ReceiptCampaignMerchant {
    label: string,
    imageUrl: ImageUrl;
}

export interface CashbackCampaignMerchantList {
    title: LanguageDictionary;
    images: ReceiptCampaignMerchant[];
}

export interface CashbackDesignPart {
    termsDescription: string;
    termsButtonText: string;
    termsUrl: string;
    title: string,

    faqUrl: string;
    faqButtonText: string;
    faqDescription: string;

    cardColorHex: string;
    cardImageUrl: ImageUrl;
    cardColorCircleHex?: string;
    textColorHex?: string;
    cardButtonText?: LanguageDictionary;

    nextButtonText: string;
    amountText: string;
    productImage?: ImageUrl;
    productImageLanding?: ImageUrl;
    cardDescription?: string;
    shareTitle?: string;
}

export interface CashbackReceiptPart {
    cashbackAmount: number;
    receiptText: string[];
    hasVariableCashbackAmount: boolean;
    cashbackPercentage?: number;
    receiptMinPrice: number;
    receiptMaxPrice: number;
    receiptRequiredText: string;
    maximumLevenshteinDistance?: number;
    instructions: CashbackCampaignDictionaryList;
    products?: CashbackCampaignDictionaryList;
    merchants?: CashbackCampaignMerchantList;
}

export interface CashbackTokenPart {

}

export interface DraftCashbackCampaign extends CashbackMetaPart, CashbackDesignPart, CashbackReceiptPart, CashbackTokenPart{}

export interface CashbackCampaign {
    id: string;
    amountOfPayouts: number;
    instructions: string[];
    lastPayoutCommandId: string;
    cardColorHex: string;
    endDate: string;
    brandName: string;
    senderIban: string;
    termsUrl: string;
    totalPayoutAmount: number;
    thresholdAmount: number;
    faqButtonText: string;
    productBarcodes: string[];
    imageUrl: ImageUrl;
    thresholdAlarmAmount: number;
    startDate: string;
    cardColorCircleHex: string;
    endViewDate: string;
    termsButtonText: string;
    nextButtonText: string;
    productImageLanding: ImageUrl;
    startViewDate: string;
    cashbackAmount: number;
    organisationId: string;
    billingIban: string;
    cardImageUrl: ImageUrl;
    faqDescription: string;
    productImage: ImageUrl;
    campaignName: string;
    suspenseAccountCode: string;
    cardDescription: string;
    amountOfCards: number;
    faqUrl: string;
    termsDescription: string;
    reconTxStoredEndDate: string;
    title: string;
    receiptText: string[];
    type: CashbackCampaignType;
    receiptMinPrice: number;
    receiptMaxPrice: number;
    hasVariableCashbackAmount: boolean;
    cashbackPercentage: number;
    [key: string]: any;
}

interface ImageUrl {
    small: string;
    medium: string;
    large: string;
}
