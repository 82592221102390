import { action, computed, observable, runInAction } from 'mobx';
import { UnAuthorizedError } from '../services/advertising-api-service';
import { CampaignWorkflowStatus } from '../types';
import { RootStore } from './root-store';
import { CashbackCampaign, DraftCashbackCampaign, SortableTypesCashbackCampaign } from "../types/cashback-campaign";
import { sortCampaigns } from "../services/cashback-campaign-service";

export class CashbackCampaignStore {

    @observable readonly campaigns: CashbackCampaign[] = [];
    @observable apiServiceError: string | undefined;
    @observable userIsAuthorized: boolean = true;
    @observable campaignStatusFilter: CampaignWorkflowStatus = CampaignWorkflowStatus.draft;
    @observable sortAscending: boolean = true;
    @observable sortField: SortableTypesCashbackCampaign = "startDate";
    @observable campaignIdFilterString: string = '';

    constructor(private rootStore: RootStore) {
        this.getCampaigns();
    }

    @action private readonly errorHandler = (error: Error) => {
        if (error instanceof UnAuthorizedError) {
            this.userIsAuthorized = false;
        } else {
            this.apiServiceError = error.message;
        }
        console.error('error encountered', error);
        return { error };
    }

    @action private setCampaigns = (campaigns: CashbackCampaign[]) => this.campaigns.splice(0, this.campaigns.length, ...campaigns);

    @action public publishCampaign = (campaignId: string) => {
        const result = this.rootStore.cashbackApiService
            .publishCampaign(campaignId)
            .then(this.updateCampaign)
            .catch(this.errorHandler);
        return result;
    }

    @action async saveCampaign(updatedCampaign: DraftCashbackCampaign): Promise<DraftCashbackCampaign> {
        return await this.rootStore.cashbackApiService.postCampaign(updatedCampaign);
    }

    @action private updateCampaign = (campaign: CashbackCampaign): CashbackCampaign => {
        const index = this.campaigns.findIndex(this.byId(campaign.campaignId));
        if (index === -1) {
            this.campaigns.push(campaign);
        } else {
            this.campaigns.splice(index, 1, campaign);
        }
        return campaign
    }

    private getCampaigns = () => this.rootStore.cashbackApiService
        .getCampaigns()
        .then(campaigns => {
            this.setCampaigns(campaigns);
        })
        .catch(this.errorHandler);

    async getCampaign(campaignId: string): Promise<DraftCashbackCampaign | undefined> {
        const result = await this.rootStore
            .cashbackApiService
            .getDraftCampaign(campaignId)
            .catch(this.errorHandler);
        return ((result as { error: Error }).error !== undefined)
            ? undefined
            : result as DraftCashbackCampaign;
    }

    @action refreshCampaigns = () => this.getCampaigns();

    @action readonly removeCampaign = (campaign: CashbackCampaign) => {
        this.rootStore.cashbackApiService
            .deleteCampaign(campaign)
            .then(() => runInAction(() => {
                const index = this.campaigns.findIndex(c => c.id === campaign.id);
                if (index > -1) {
                    this.campaigns.splice(index, 1);
                }
            }))
            .catch(this.errorHandler)
    }

    @action setCampaignStatusFilter(status: CampaignWorkflowStatus) {
        this.campaignStatusFilter = status;
    }

    @action setCampaignIdFilterString(filter: string) {
        this.campaignIdFilterString = filter;
    }


    @computed get filteredCampaigns() {
        return this.campaigns.filter(x => x.id.includes(this.campaignIdFilterString));
    }

    @action setSortField(field: SortableTypesCashbackCampaign) {
        this.sortField = field;
    }

    @action setSortAscending(ascending: boolean) {
        this.sortAscending = ascending;
    }

    public readonly byId = (id?: string) => (campaign: CashbackCampaign) => campaign.id === id;

    @computed get sortedCampaigns() {
        const sortedCampaigns = sortCampaigns(this.sortField, this.filteredCampaigns.slice());
        return this.sortAscending ? sortedCampaigns : sortedCampaigns.reverse();
    }

}
