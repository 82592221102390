import React, {
  ChangeEvent,
  ChangeEventHandler,
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import toggleON from './images/active.svg';
import toggleOFF from './images/inactive.svg';

import { PrimaryButton } from './global-components';
import DatePicker, {registerLocale} from "react-datepicker";
import { DateTime } from "luxon";
import { isEmpty } from "../../utils";
import { ValidationError } from "joi";
import { Lookup } from "../../types";
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Modal from "styled-react-modal";
import { CashbackCampaignType } from "../../types/cashback-campaign";
import nl from "date-fns/locale/nl"

registerLocale("nl", nl);

const okBackgroundImage = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM13.8995 8.24293C14.29 7.85241 14.9232 7.85241 15.3137 8.24293C15.7042 8.63346 15.7042 9.26662 15.3137 9.65715L10.669 14.3019L10.6568 14.3143C10.2663 14.7048 9.63309 14.7048 9.24256 14.3143L6.70703 11.7788C6.31651 11.3882 6.31651 10.7551 6.70703 10.3645C7.09755 9.97401 7.73072 9.97401 8.12124 10.3645L9.94957 12.1929L13.8995 8.24293Z" fill="#4FC3B8"/>
</svg>`;

const searchBackgroundImage = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1625 13.7568C17.3862 11.9353 17.888 9.72352 17.5702 7.55231C17.2523 5.3811 16.1375 3.40598 14.443 2.01176C12.7485 0.617539 10.5956 -0.0959216 8.40385 0.0103758C6.21207 0.116673 4.13836 1.03512 2.58672 2.58677C1.03508 4.13842 0.116656 6.21214 0.010373 8.40393C-0.0959099 10.5957 0.617563 12.7486 2.01179 14.4431C3.40602 16.1376 5.38113 17.2524 7.55234 17.5702C9.72355 17.8881 11.9353 17.3863 13.7568 16.1625L17.096 19.5018C17.415 19.8208 17.8477 20 18.2989 20C18.75 20 19.1827 19.8208 19.5018 19.5018C19.8208 19.1827 20 18.75 20 18.2989C20 17.8477 19.8208 17.415 19.5018 17.096L16.1625 13.7568ZM8.83955 15.6221C7.49809 15.6221 6.18676 15.2243 5.07138 14.479C3.956 13.7337 3.08667 12.6744 2.57332 11.4351C2.05997 10.1957 1.92565 8.83198 2.18736 7.51629C2.44907 6.20061 3.09505 4.99207 4.0436 4.04352C4.99216 3.09496 6.20069 2.44899 7.51638 2.18729C8.83206 1.92558 10.1958 2.0599 11.4351 2.57326C12.6745 3.08662 13.7338 3.95596 14.479 5.07134C15.2243 6.18673 15.6221 7.49807 15.6221 8.83953C15.6201 10.6378 14.9048 12.3617 13.6333 13.6333C12.3618 14.9048 10.6378 15.6201 8.83955 15.6221Z" fill="#40C4B8"/>
</svg>`;

const euroBackgroundImage = `
<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.2 8H1.696C2.352 10.48 4.416 12.192 7.168 12.192C7.664 12.192 8.112 12.128 8.416 12.064C8.848 11.984 9.008 11.68 8.928 11.28L8.8 10.672C8.736 10.256 8.448 10.112 8.032 10.176C7.808 10.208 7.52 10.24 7.2 10.24C5.712 10.24 4.496 9.36 3.984 8H6.688C7.152 8 7.328 7.84 7.424 7.408C7.504 7.008 7.312 6.784 6.8 6.784H3.616C3.568 6.464 3.568 6.096 3.616 5.792H7.12C7.584 5.792 7.776 5.616 7.856 5.184C7.936 4.768 7.76 4.56 7.232 4.56H3.904C4.4 3.216 5.584 2.368 7.12 2.368C7.392 2.368 7.68 2.4 7.872 2.432C8.208 2.48 8.432 2.32 8.544 1.92L8.704 1.328C8.816 0.896 8.64 0.591999 8.192 0.528C7.92 0.495999 7.552 0.447999 7.168 0.447999C4.528 0.447999 2.384 2.16 1.696 4.56H1.216C0.752 4.56 0.592 4.768 0.592 5.168C0.592 5.568 0.768 5.792 1.216 5.792H1.472C1.44 6.112 1.44 6.528 1.488 6.784H1.2C0.736 6.784 0.592 6.992 0.592 7.392C0.592 7.792 0.736 8 1.2 8Z" fill="#40C4B8"/>
</svg>`;

const euroOrangeBackgroundImage = `
<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.2 8H1.696C2.352 10.48 4.416 12.192 7.168 12.192C7.664 12.192 8.112 12.128 8.416 12.064C8.848 11.984 9.008 11.68 8.928 11.28L8.8 10.672C8.736 10.256 8.448 10.112 8.032 10.176C7.808 10.208 7.52 10.24 7.2 10.24C5.712 10.24 4.496 9.36 3.984 8H6.688C7.152 8 7.328 7.84 7.424 7.408C7.504 7.008 7.312 6.784 6.8 6.784H3.616C3.568 6.464 3.568 6.096 3.616 5.792H7.12C7.584 5.792 7.776 5.616 7.856 5.184C7.936 4.768 7.76 4.56 7.232 4.56H3.904C4.4 3.216 5.584 2.368 7.12 2.368C7.392 2.368 7.68 2.4 7.872 2.432C8.208 2.48 8.432 2.32 8.544 1.92L8.704 1.328C8.816 0.896 8.64 0.591999 8.192 0.528C7.92 0.495999 7.552 0.447999 7.168 0.447999C4.528 0.447999 2.384 2.16 1.696 4.56H1.216C0.752 4.56 0.592 4.768 0.592 5.168C0.592 5.568 0.768 5.792 1.216 5.792H1.472C1.44 6.112 1.44 6.528 1.488 6.784H1.2C0.736 6.784 0.592 6.992 0.592 7.392C0.592 7.792 0.736 8 1.2 8Z" fill="#F1AB47"/>
</svg>`;

const euroBlueSquareBackgroundImage = `
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity=".1" width="36" height="36" rx="6" fill="#40C4B8"/>
    <g transform="translate(13,11)">
      <path d="M1.2 8H1.696C2.352 10.48 4.416 12.192 7.168 12.192C7.664 12.192 8.112 12.128 8.416 12.064C8.848 11.984 9.008 11.68 8.928 11.28L8.8 10.672C8.736 10.256 8.448 10.112 8.032 10.176C7.808 10.208 7.52 10.24 7.2 10.24C5.712 10.24 4.496 9.36 3.984 8H6.688C7.152 8 7.328 7.84 7.424 7.408C7.504 7.008 7.312 6.784 6.8 6.784H3.616C3.568 6.464 3.568 6.096 3.616 5.792H7.12C7.584 5.792 7.776 5.616 7.856 5.184C7.936 4.768 7.76 4.56 7.232 4.56H3.904C4.4 3.216 5.584 2.368 7.12 2.368C7.392 2.368 7.68 2.4 7.872 2.432C8.208 2.48 8.432 2.32 8.544 1.92L8.704 1.328C8.816 0.896 8.64 0.591999 8.192 0.528C7.92 0.495999 7.552 0.447999 7.168 0.447999C4.528 0.447999 2.384 2.16 1.696 4.56H1.216C0.752 4.56 0.592 4.768 0.592 5.168C0.592 5.568 0.768 5.792 1.216 5.792H1.472C1.44 6.112 1.44 6.528 1.488 6.784H1.2C0.736 6.784 0.592 6.992 0.592 7.392C0.592 7.792 0.736 8 1.2 8Z" fill="#40C4B8"/>
    </g>
</svg>`;

const percentBlueSquareBackgroundImage = `
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity=".1" width="36" height="36" rx="6" fill="#40C4B8"/>
    <g transform="translate(13,11) scale(.6)">
        <path fill="#40C4B8" opacity="1" d="M4.34409 8.256C6.50409 8.256 8.25609 6.576 8.25609 4.464C8.25609 2.4 6.50409 0.671999 4.34409 0.671999C2.16009 0.671999 0.432094 2.4 0.432094 4.464C0.432094 6.576 2.16009 8.256 4.34409 8.256ZM1.99209 16.776C1.46409 17.448 1.72809 18 2.56809 18H3.07209C3.62409 18 3.98409 17.808 4.29609 17.4L15.9601 2.184C16.4881 1.512 16.2241 0.959999 15.3601 0.959999H14.8801C14.3281 0.959999 13.9681 1.152 13.6561 1.56L1.99209 16.776ZM4.34409 6.048C3.45609 6.048 2.76009 5.352 2.76009 4.464C2.76009 3.6 3.45609 2.88 4.34409 2.88C5.23209 2.88 5.95209 3.6 5.95209 4.464C5.95209 5.352 5.23209 6.048 4.34409 6.048ZM9.74409 14.52C9.74409 16.608 11.4721 18.288 13.6561 18.288C15.8161 18.288 17.5681 16.608 17.5681 14.52C17.5681 12.456 15.8161 10.728 13.6561 10.728C11.4721 10.728 9.74409 12.456 9.74409 14.52ZM12.0721 14.496C12.0721 13.632 12.7681 12.912 13.6561 12.912C14.5441 12.912 15.2641 13.632 15.2641 14.496C15.2641 15.384 14.5441 16.08 13.6561 16.08C12.7681 16.08 12.0721 15.384 12.0721 14.496Z"/>
    </g>
</svg>
`;


export enum InputBoxBackgroundImage {
  none = 'none',
  ok = 'ok',
  search = 'search',
  euro = 'euro',
  euroOrange = 'euroOrange',
  euroBlueSquareBackgroundImage = 'euroBlueSquareBackgroundImage',
  percent = 'percent',
  percentBlueSquareBackgroundImage = 'percentBlueSquareBackgroundImage',
}

interface InputBoxElementProps {
  backgroundImage?: InputBoxBackgroundImage;
}

const getInputBoxElementBackgroundImage = (backgroundImage?: InputBoxBackgroundImage) => {
  switch (backgroundImage) {
    case InputBoxBackgroundImage.none: return 'none';
    case InputBoxBackgroundImage.ok: return `data:image/svg+xml,${encodeURIComponent(okBackgroundImage)}`;
    case InputBoxBackgroundImage.search: return `data:image/svg+xml,${encodeURIComponent(searchBackgroundImage)}`;
    case InputBoxBackgroundImage.euro: return `data:image/svg+xml,${encodeURIComponent(euroBackgroundImage)}`;
    case InputBoxBackgroundImage.euroBlueSquareBackgroundImage: return `data:image/svg+xml,${encodeURIComponent(euroBlueSquareBackgroundImage)}`;
    case InputBoxBackgroundImage.euroOrange: return `data:image/svg+xml,${encodeURIComponent(euroOrangeBackgroundImage)}`;
    case InputBoxBackgroundImage.percent: return `data:image/svg+xml,${encodeURIComponent(euroBackgroundImage)}`;
    case InputBoxBackgroundImage.percentBlueSquareBackgroundImage: return `data:image/svg+xml,${encodeURIComponent(percentBlueSquareBackgroundImage)}`;
    default: return 'none';
  }
}

/* background-image: url(${searchIconUrl}); */
export const InputBoxElement = styled.input<InputBoxElementProps>`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  margin-left: auto;
  border-radius: 8px;
  border: 2px solid #f4f4f4;
  background-color: #ffffff;
  align-self: flex-end;
  padding-left: ${(props) => props.backgroundImage ? '30px' : '16px'};
  margin-right: 0px;
  font-family: MuseoSansRounded700, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: url("${props => getInputBoxElementBackgroundImage(props.backgroundImage)}");
`;

interface InputBoxProps {
  value: string | number | undefined;
  placeholder?: string;
  onChange: (newValue: string) => void;
  onBlur?: (newValue: string) => void;
  showMaxChars?: boolean;
  maxChars?: number;
  disabled?: boolean;
  backgroundImage?: InputBoxBackgroundImage;
  customStyle?: CSSProperties;
}

export const InputBox = ({
  value,
  onChange,
  onBlur,
  placeholder,
  showMaxChars,
  maxChars,
  disabled,
  backgroundImage,
  customStyle
}: InputBoxProps) => {

  let [availableChars, setChars] = useState<number>(maxChars ?? 0);
  const valueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (maxChars) {
      if (event.currentTarget.value.length <= maxChars) {
        setChars(maxChars - event.currentTarget.value.length);
        onChange(event.currentTarget.value);
      }
    } else {
      onChange(event.currentTarget.value);
    }
  }

  return <div style={{ display: 'flex' }}>
    <InputBoxElement style={customStyle} disabled={disabled}
      value={value}
      onChange={valueChange}
      onBlur={event => onBlur && onBlur(event.currentTarget.value)}
      placeholder={placeholder}
      backgroundImage={backgroundImage} />
    {showMaxChars && <label className="inputBoxNumber">{availableChars}</label>}
  </div>
}

interface NumberInputBoxProps {
  value: number | undefined;
  placeholder?: string;
  onChange: (newValue: number | undefined) => void;
  showMaxChars?: boolean;
  maxChars?: number;
  disabled?: boolean;
  backgroundImage?: InputBoxBackgroundImage;
  customStyle?: CSSProperties;
  onBlurAction?: () => void;
  autoFocus?: boolean;
  decimalScale?: number;
}

export const NumberInputBox = ({ value, onChange, placeholder, disabled, backgroundImage, customStyle, onBlurAction, autoFocus, decimalScale }: NumberInputBoxProps) => {

  const onValueChange = (values: NumberFormatValues) => {
    onChange(values.floatValue);
  }

  // @ts-ignore
  const numberFormat = <NumberFormat onBlur={onBlurAction}
                              style={customStyle}
                              placeholder={placeholder}
                              disabled={disabled}
                              backgroundImage={backgroundImage}
                              defaultValue={0}
                              onValueChange={onValueChange} decimalScale={decimalScale ?? 2} decimalSeparator={","} thousandSeparator={"."}
                              customInput={InputBoxElement}
                              value={value} autoFocus={autoFocus}></NumberFormat>

  return <div style={{ display: 'flex' }}>
    {numberFormat}
  </div>
}
const dataImageSelect = encodeURIComponent(`<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.87189 17.5218C9.27026 17.9765 9.97786 17.9765 10.3762 17.5218L17.2289 9.70001C17.7953 9.05356 17.3362 8.04104 16.4767 8.04104L2.77138 8.04104C1.91193 8.04104 1.45286 9.05356 2.01921 9.70001L8.87189 17.5218Z" fill="#57565B"/></svg>`);
const DropDownSelect = styled.select`
  display: block;
  width: 100%;
  padding: 10px 10px 10px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 2px solid #f4f4f4;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml,${dataImageSelect});
  font-family: MuseoSansRounded700, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  `;
interface DropDownProps {
  optionValues: { label: string, value: any }[];
  selectedIndex?: number;
  onChange: (newValue: any) => void;
  disabled?: boolean;
}

export const DropDown = ({ optionValues, selectedIndex, onChange, disabled }: DropDownProps) => {

  const valueChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(optionValues[parseInt(event.target.value, 10)].value);
  }

  return <DropDownSelect disabled={disabled} onChange={valueChange} value={selectedIndex}>
    {optionValues.map((ov, index) => <option value={index} key={index}>{ov.label}</option>)}
  </DropDownSelect>
}

export const CashbackCampaignStatus = styled.div`
  display: block;
  width: 124px;
  padding: 6px 29px 6px 9px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  border: 0;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #F1C347;
  color: #FFF;
  font-family: MuseoSansRounded700, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

/* background-image: url(${searchIconUrl}); */
interface ColorInputBoxElementProps {
  color: string;
}

const dataColorBackgroundImage = (color: string) => {
  const assureStartsWithHash = (s: string) => s[0] === '#' ? s : `#${s}`;
  return `
<svg xmlns="http://www.w3.org/2000/svg" width="200" height="50" version="1.1">
    <rect x="150" y="3" width="35" height="35" rx="5" fill="#ffffff" stroke="#e9e9e9" stroke-width="2" stroke-opacity="0.5" />
    <rect x="153" y="6" width="29" height="29" rx="5" fill="${assureStartsWithHash(color.trim() !== "" ? color : 'e9e9e9')}" />
</svg>
`
}


const ColorInputBoxElement = styled.input<ColorInputBoxElementProps>`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  margin-left: auto;
  border-radius: 8px;
  border: 2px solid #f4f4f4;
  background-color: #ffffff;
  align-self: flex-end;
  background-repeat: no-repeat;
  background-position: 15.4px 2px;
  padding-left: 16px;
  margin-right: 0px;
  font-family: MuseoSansRounded700, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  background-image: ${props => `url(data:image/svg+xml,${encodeURIComponent(dataColorBackgroundImage(props.color))})`}
`;

interface ColorInputBoxProps {
  value: string;
  onChange: (newValue: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
}

export const ColorInputBox = ({ value, onChange, onBlur, placeholder, disabled }: ColorInputBoxProps) => {

  const valueChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value.toUpperCase());
  }
  return <ColorInputBoxElement disabled={disabled} value={value} onChange={valueChange} onBlur={onBlur} color={`${value ?? '#E9E9E9'}`} placeholder={placeholder} />
}

export const CalendarComponentElement = styled.div`
  display: flex;
  align-items: center;
`;

const ExampleCustomInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  margin-left: auto;
  border-radius: 8px;
  border: 2px solid #f4f4f4;
  background-color: #ffffff;
  align-self: flex-end;
  background-repeat: no-repeat;
  background-position: 15.4px 2px;
  padding-left: 16px;
  margin-right: 0px;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  background-image: url(data:image/svg+xml,${dataImageSelect});
  font-family: MuseoSansRounded700, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
`;

interface CalendarProps {
  value?: string;
  onChange: (newValue: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  timeSelection?: boolean;
}

interface CustomInputProps {
  value: string;
  onClick?: (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
}

export const StyledCheckboxInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  vertical-align: middle;

  input[type=checkbox] {
    height: 25px;
    width: 25px;
    margin-right: 0.5em;
  }

  input:checked {
    accent-color: #40C4B8;
  }

  label {
    margin: 0px;
    color: #57565B;
    vertical-align: middle;
    display: block;
  }
`

interface CheckboxProps {
  onChange?: ChangeEventHandler<HTMLInputElement>,
  checked?: boolean;
  label: string,
  className: string;
}

export const CheckboxInput = ({ label, className, checked, onChange }: CheckboxProps) => {
  return (
    <StyledCheckboxInput>
      <input checked={checked} onChange={onChange} name={label} type={'checkbox'} className={className} />
      <label htmlFor={label}>{label}</label>
    </StyledCheckboxInput>
  );
}

const ExampleCustomInputHandler = ({ value, onClick }: CustomInputProps) => (
  <ExampleCustomInput readOnly={true} style={{ color: "#000000" }} onClick={onClick} value={value}>
  </ExampleCustomInput>
);

export const CalendarComponent = ({ value, onChange, onBlur, disabled }: CalendarProps) => {
  const valueChange = (date: Date) => {
    const newValue = DateTime.fromJSDate(date).toISO({ includeOffset: false });
    onChange(newValue);
  }
  return <CalendarComponentElement onBlur={onBlur}>
    <DatePicker disabled={disabled}
      selected={isEmpty(value) ? undefined : DateTime.fromISO(value!).toJSDate()}
      onChange={date => date && date instanceof Date && valueChange(date)}
      locale="nl"
      dateFormat="P"
      customInput={<ExampleCustomInputHandler value={isEmpty(value) ? '' : value!} />}
    />
  </CalendarComponentElement>
}

export const CalendarTimeComponent = ({ value, onChange, onBlur, disabled }: CalendarProps) => {
  const valueChange = (date: Date) => {
    const newValue = DateTime.fromJSDate(date).toISO({ includeOffset: false });
    onChange(newValue);
  }
  return <CalendarComponentElement onBlur={onBlur}>
    <DatePicker disabled={disabled}
                selected={isEmpty(value) ? undefined : DateTime.fromISO(value!).toJSDate()}
                onChange={date => date && date instanceof Date && valueChange(date)}
                showTimeSelect={true}
                locale="nl"
                timeIntervals={60}
                dateFormat="Pp"
                injectTimes={[
                  DateTime.local().endOf('day').toJSDate()
                ]}
                customInput={<ExampleCustomInputHandler value={isEmpty(value) ? '' : value!} />}
    />
  </CalendarComponentElement>
}

export const UploadComponentElement = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & > *:last-child {
    margin-bottom: 0px !important;
  }

  .upload-image-title {
    position: relative;
    margin-bottom: 20px
  }

  .upload-image-hint {
    color: #C7C7C8;
    margin-bottom: 20px
  }

  .file-preview {
    width: 160px;
    height: 101px;
    box-sizing: border-box;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export enum UploadMediaTypes {
  PNG = "image/png",
  SVG = "image/svg+xml",
  JPEG = "image/jpeg",
  GIF = "image/gif"
}

interface UploadComponentProps {
  onlineUrl?: string;
  title: string;
  property: string;
  onChange: (property: string, file: File) => void;
  hintText?: string;
  disabled?: boolean;
  images: Lookup<File>
  allowedMediaTypes?: UploadMediaTypes[];
}

export const UploadComponent = forwardRef<HTMLInputElement, UploadComponentProps>((props, ref) => {
  const {  title, property, onChange, hintText, disabled, onlineUrl, images, allowedMediaTypes } = props;

  const inputFile = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => inputFile.current as HTMLInputElement);

  const previewDiv = useRef<HTMLDivElement>(null)

  const backgroundImage = isEmpty(onlineUrl)
    ? ''
    : `url(${onlineUrl}?${Math.random()})`;

  if (images[property]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (previewDiv.current) {
        previewDiv.current.style.backgroundImage = `url(${e.target?.result})`;
      }
    }
    reader.readAsDataURL(images[property]);
  } else if (previewDiv.current !== null && isEmpty(backgroundImage)) {
    previewDiv.current!.style.backgroundImage = `none`;
  }
  const showPreview = images[property] || backgroundImage !== '';

  const clickHandler = () => {
    if (inputFile && inputFile.current && (disabled !== true))
      inputFile.current.click()
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) {
      return;
    }
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file) {
            onChange(property, file);
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        onChange(property, event.dataTransfer.files[i]);
      }
    }
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // default/further event handling has to be prevented, otherwise onDrop can't be captured
    event.stopPropagation();
    event.preventDefault();
  }

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      onChange(property, event.target.files[0])
    }
  }

  return <UploadComponentElement onDrop={onDrop} onDragOver={onDragOver}>
    <div className="upload-image-title" >{title}</div>
    {
      showPreview
        ? <div onClick={clickHandler} className="file-preview" style={{ backgroundImage }} ref={previewDiv}></div>
        : <>
          <div className="upload-image-hint">{hintText ?? ".PNG bestand"}</div>
          <PrimaryButton onClick={clickHandler} disabled={disabled}>Selecteer bestand</PrimaryButton>
        </>
    }
    <input disabled={disabled} accept={(allowedMediaTypes || [UploadMediaTypes.PNG, UploadMediaTypes.JPEG, UploadMediaTypes.SVG]).join(', ')} onChange={onFileInputChange} multiple={false} type="file" ref={inputFile} style={{ display: "none" }} />
  </UploadComponentElement>
});

const ValidationErrorIndicationElement = styled.div`
  color: #FFF;
  background: #ED5565;
  border-radius: 10px;
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
  padding: 14px;
  max-width: 250px;

`

interface ValidationErrorIndicationProps {
  errorKey: string;
  validationErrors?: ValidationError;
}

export const ValidationErrorIndication = (props: ValidationErrorIndicationProps) => {
  const details = props.validationErrors?.details ?? [];
  const errorDetail = details.find(detail => detail.context?.label === props.errorKey);

  return errorDetail === undefined ?
    null :
    <ValidationErrorIndicationElement>{errorDetail.message}</ValidationErrorIndicationElement>;
}


const ToggleButton = styled.div`
  float: left;
  height: 50px;
  line-height: 38px;
  font-family: MuseoSansRounded, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  padding: 4px 4px 4px 3px;
  border: 2px solid #40C4B8;
  border-radius: 10px;

  span {
    vertical-align: top;
  }

  img {
    float: left;
    cursor: pointer;
  }
`;

interface ToggleComponentProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  toggleOnSvg?: string;
  toggleOffSvg?: string;
  customStyle?: CSSProperties;
}

export const ToggleComponent = ({ value, onChange, toggleOffSvg, toggleOnSvg, customStyle }: ToggleComponentProps) => {
  const [isOn, setIsOn] = useState<boolean>(value ?? false);

  const toggleOnImage = toggleOnSvg ?? toggleON;
  const toggleOffImage = toggleOffSvg ?? toggleOFF;

  const clickHandler = () => {
    if (onChange) onChange(!isOn);
    setIsOn(!isOn);
  }

  return <ToggleButton style={customStyle} onClick={clickHandler}>
    <img src={isOn ? toggleOnImage : toggleOffImage} alt="Toggle" />
  </ToggleButton>
}

interface CashbackCampaignTypeSwitchComponentProps {
  onChange: (type: CashbackCampaignType) => void;
}

interface TypeSwitchOptionProps {
  name: string
  onSelect: () => void
  selected: boolean
}

const TypeSwitch = styled.div`
  border: 3px solid #949494;
  border-width: 0px 0px 3px 0px;
  color: #949494;
  font-size: 18px;
  line-height: 36px;
  cursor: pointer;
`

const SelectedTypeSwitch = styled.div`
  border: 3px solid #39A4A1;
  border-width: 0px 0px 3px 0px;
  color: #39A4A1;
  font-size: 18px;
  line-height: 36px;
  cursor: pointer;
`

const TypeSwitchOption = ({ name, onSelect, selected }: TypeSwitchOptionProps) => {
  const renderOption = () => {
    if (selected) {
      return (
        <SelectedTypeSwitch onClick={onSelect}>
          <span>{name}</span>
        </SelectedTypeSwitch>
      )
    } else {
      return (
        <TypeSwitch onClick={onSelect} className={selected ? 'selected' : ''}>
          <span>{name}</span>
        </TypeSwitch>
      )
    }
  }

  return (
    <div>
      {renderOption()}
    </div>
  )
}

export const CashbackCampaignTypeSwitchComponent = ({ onChange }: CashbackCampaignTypeSwitchComponentProps) => {
  const [selectedType, setSelectedType] = useState<CashbackCampaignType>(CashbackCampaignType.receipt);

  const selectType = (type: CashbackCampaignType) => {
    setSelectedType(type);
    onChange(type);
  }

  const COLOR_SELECTED = '#39A4A1';
  const COLOR_BLURRED = '#949494';

  return <div style={{ width: '100%' }}>
    <div style={{ display: "flex", justifyContent: "space-evenly", alignContent: "flex-end", flexDirection: 'row', flexGrow: 1 }}>
      <TypeSwitchOption name={'Op basis van aankoopbon'} onSelect={() => selectType(CashbackCampaignType.receipt)} selected={selectedType === CashbackCampaignType.receipt} />
      <TypeSwitchOption name={'Op basis van tokens'} onSelect={() => selectType(CashbackCampaignType.token)} selected={selectedType === CashbackCampaignType.token} />
    </div>
  </div>;
}

const StyledModal = Modal.styled`
  width: 760px;
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  gap: 18px;

  .acceptButton {
    background: #E25E89;
    border: 0;
    border-radius: 50px;
    line-height: 19px;
  }

  .dismissButton {
    font-size: 14px;
    line-height: 18px;
    color: #39A4A1;
    border: 0;
    background-color: #FFF;
  }

  .modalMessage {
    padding: 20px;
  }
`

interface ModalWrapperProps {
  isModalOpen: boolean;
  isAccepted: (accepted: boolean) => void;
}

export const ModalWrapper = ({ isModalOpen, isAccepted }: ModalWrapperProps) => {

  const accept = () => {
    isAccepted(true);
  }

  const dismiss = () => {
    isAccepted(false);
  }

  return <div>
    <StyledModal
      isOpen={isModalOpen}
      onBackgroundClick={dismiss}
      onEscapeKeydown={dismiss}>
      <span className={"modalMessage"}>Indien je doorgaat wordt het huidige formulier verwijderd!</span>
      <button className={"acceptButton"} onClick={accept}>Doorgaan</button>
      <button className={"dismissButton"} onClick={dismiss}>Annuleren</button>
    </StyledModal>
  </div>;
}
