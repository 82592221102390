import { BreadCrumbNavigation } from "../components";
import { Header2, Header3, Row, Section as BaseSection, WarningButton, ClearTextButton, PrimaryButton, DropDown, NumberInputBox, InputBox } from "../components/global/global-components";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../stores/store-context";
// @ts-ignore
import { Spinner } from "react-bootstrap";
import { Redemption, RedemptionValidation } from "../types/redemption-requets";
import { FullScreenModal } from '../components/global/modal';
import { ModalCampaignTitle, ModalControls, ModalSmallText, ModalText } from '../components/modal/modal';
import { RetryService } from '../services/retry-service';
import { RedemptionExternalValidationResult } from '../types/redemption-external-validation';
import { DealCampaign } from "../types";
import { ViewRedemptionValidationDetails } from "../components/redemption/view-redemption-validation-details";

export const ViewRedemptionRequestPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { redemptionRequestStore, dealsCampaignStore } = useStore();
  const [redemption, setRedemption] = useState({} as Redemption);
  const [campaign, setCampaign] = useState({} as DealCampaign);
  const [isLoading, setIsLoading] = useState(true);
  const [saveError, setSaveError] = useState<any>(undefined);
  const [externalValidationConfirmation, setExternalValidationConfirmation] = useState(false);
  const [externalValidationInput, setExternalValidationInput] = useState(false);
  const [prepareExternalValidation, setPrepareExternalValidation] = useState(false);
  const [externalValidationProcessing, setExternalValidationProcessing] = useState(false);
  const [externalValidationAmountInCents, setExternalValidationAmountInCents] = useState(redemption.amountInCents);
  const [isSendingExternalValidation, setIsSendingExternalValidation] = useState(false);
  const [externalValidationIsProcessed, setExternalValidationIsProcessed] = useState(false);

  const getRedemptionRequest = () => {
    setIsLoading(true);
    return redemptionRequestStore.get(id)
      .then((redemptionRequest) => {
        if (redemptionRequest === undefined) {
          throw new Error('something errored');
        }
        setRedemption(redemptionRequest);
        setExternalValidationAmountInCents(redemptionRequest.amountInCents);
      })
      .catch((e) => {
        console.error('Failed to fetch redemption: ', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const getCampaign = (successCallback: () => void, errorCallback: () => void) => {
    return dealsCampaignStore.getPublishedCampaign(redemption.campaignId)
      .then((campaign) => {
        if (campaign === undefined) {
          throw new Error('Campaign not found.');
        }
        console.log(campaign)
        setCampaign(campaign);
        successCallback();
      })
      .catch((e) => {
        console.error('Failed to fetch campaign: ', e);
        errorCallback();
      });
  }

  useEffect(() => {
    getRedemptionRequest()
  }, [])

  const Section = (props: any) => <BaseSection {...props} style={{ display: 'block' }} />

  const buildValidationHtml = (redemption: Redemption) => {
    return redemption.validations?.map((validation, index) => {
      return (
        <Section key={`validation-${redemption.id}-${index}`} >
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', columnGap: '16px' }}>
            <Section wrap="wrap">
              <div className="title">Validation type</div>
              <div className="description">{validation.validationType}</div>
            </Section>
            <Section wrap="wrap">
              <div className="title">Amount in cents</div>
              <div className="description">{validation.amountInCents}</div>
            </Section>
            <Section wrap="wrap">
              <div className="title">Response code</div>
              <div className="description">{validation.consolidatedResponseCode}</div>
            </Section>
            {validation.rejectionReasons?.length &&
              <Section wrap="wrap">
                <div className="title">Rejection Reasons</div>
                <div className="description"><ul>{buildRejectionReasonsHtml(validation.rejectionReasons)}</ul></div>
              </Section>
            }
            {validation.rejectionDetails?.length &&
              <Section wrap="wrap">
                <div className="title">Rejection Details</div>
                <div className="description"><ul>{buildRejectionDetailsHtml(validation.rejectionDetails)}</ul></div>
              </Section>
            }
            <Section wrap="wrap">
              <div className="title">Date</div>
              <div className="description">{validation.createdDate}</div>
            </Section>

          </div>
          <ViewRedemptionValidationDetails details={validation.details} />
        </Section>)
    })
  }

  const buildRejectionReasonsHtml = (reasons: string[]) => {
    return reasons.map((reason, index) => {
      return (<li>{reason}</li>)
    })
  }

  const buildRejectionDetailsHtml = (details: RedemptionValidation["rejectionDetails"]) => {
    return details!.map((detail, index) => {
      return (<div>
        <li>{detail.reason}</li>
        {detail.details?.matchingRequestsIds?.map(requestId =>
          <div>
            <a href={`/redemption-requests/view/${requestId}`}>{requestId}</a>
          </div>
        )}
      </div>)
    })
  }

  const renderExternalValidationInputModal = () => {
    if (externalValidationInput) {
      const FIXED_MAXIMUM_APPROVAL_VALUE_EURO = 19.99;
      const productMinPriceEuro = campaign.campaignData.receiptMinPrice ?? 0;
      const productMaxPriceEuro = campaign.campaignData.receiptMaxPrice ?? 0;
      const cashbackPercentage = campaign.campaignData.cashbackPercentage ?? 0;
      const cashbackAmountEuro = campaign.campaignData.hasVariableCashbackAmount ? undefined : (campaign.campaignData.cashbackAmount ?? 0) / 100;

      const maxPriceEuro = Math.min(cashbackAmountEuro ?? (productMaxPriceEuro * cashbackPercentage / 100), FIXED_MAXIMUM_APPROVAL_VALUE_EURO);
      const minPriceEuro = cashbackAmountEuro ?? (productMinPriceEuro * cashbackPercentage / 100);
      const availableBudgetCents = campaign.budget?.availableInCents ?? 0;

      const currentValue = externalValidationAmountInCents ?? 0;

      const compliesWithMaximum = currentValue <= maxPriceEuro * 100;
      const compliesWithMinimum = currentValue >= minPriceEuro * 100;
      const compliesWithBudget = currentValue <= availableBudgetCents;
      const compliesWithBoundaries = compliesWithMaximum && compliesWithMinimum && compliesWithBudget;
      return (
        <FullScreenModal>
          <ModalText><ModalCampaignTitle>External approval</ModalCampaignTitle></ModalText>
          <ModalSmallText style={{ color: compliesWithBudget ? 'green' : 'red' }}>Remaining budget: €{(availableBudgetCents / 100).toFixed(2)}</ModalSmallText>
          <ModalSmallText style={{ color: compliesWithMinimum ? 'green' : 'red' }}>Min price: €{(minPriceEuro).toFixed(2)}</ModalSmallText>
          <ModalSmallText style={{ color: compliesWithMaximum ? 'green' : 'red' }}>Max price: €{(maxPriceEuro).toFixed(2)}</ModalSmallText>
          <Section wrap="wrap">
            <div className="title">Amount in cents</div>
            <div className="description"><NumberInputBox value={externalValidationAmountInCents} onChange={setExternalValidationAmountInCents} customStyle={{ borderColor: compliesWithBoundaries ? 'green' : 'red' }} decimalScale={0} autoFocus /></div>
          </Section>

          <ModalControls>
            <WarningButton onClick={() => {
              setExternalValidationInput(false);
              setExternalValidationConfirmation(true);
            }} disabled={!compliesWithBoundaries}><span
              style={{ paddingLeft: '12px' }}>Confirm</span></WarningButton>
            <ClearTextButton onClick={() => {
              setExternalValidationInput(false);
            }}>Cancel</ClearTextButton>
          </ModalControls>
        </FullScreenModal>
      )
    }
  }

  const renderExternalValidationConfirmationModal = () => {
    if (externalValidationConfirmation) {
      return (
        <FullScreenModal>
          <ModalText><ModalCampaignTitle>Are you sure you want to approve this redemption?</ModalCampaignTitle></ModalText>
          <ModalSmallText>ID: {redemption.id}</ModalSmallText>
          <ModalSmallText>Amount in cents: {externalValidationAmountInCents}</ModalSmallText>
          <ModalSmallText>Receiver: {redemption.receiver.name}</ModalSmallText>
          <ModalSmallText>Note: It might take some time before external validation is processed.</ModalSmallText>
          <ModalControls>
            <WarningButton onClick={() => sendExternalValidation()}><span
              style={{ paddingLeft: '12px' }}>Confirm</span></WarningButton>
            <ClearTextButton onClick={() => {
              setExternalValidationConfirmation(false);
            }}>Cancel</ClearTextButton>
          </ModalControls>
        </FullScreenModal>
      )
    }
  }

  const renderExternalValidationProcessingModal = () => {
    if (externalValidationProcessing) {
      return (
        <FullScreenModal>
          <ModalText>External validation being processed.</ModalText>
          <ModalSmallText>{isSendingExternalValidation ? (
            <div><Spinner animation={"border"} size={"sm"} />Sending external validation...</div>
          ) :
            (externalValidationIsProcessed ? (<div>Successfully processed</div>) : (<div><ModalSmallText><Spinner animation={"border"} size={"sm"} />Awaiting processing of external validation.</ModalSmallText><ModalSmallText>This might take a few seconds (if desired, you may close this screen).</ModalSmallText></div>))}
          </ModalSmallText>
          <ModalControls>
            <ClearTextButton onClick={() => {
              setExternalValidationProcessing(false);
            }}>Close</ClearTextButton>
          </ModalControls>
        </FullScreenModal>
      )
    }
  }

  const prepareExternalValidationModal = () => {
    if (prepareExternalValidation) {
      getCampaign(() => {
        setExternalValidationInput(true);
        setPrepareExternalValidation(false);
      }, () => {
        setPrepareExternalValidation(false);
      })
      return (
        <FullScreenModal>
          <ModalText><ModalCampaignTitle>Preparing approval</ModalCampaignTitle></ModalText>
          <div><Spinner animation={"border"} size={"sm"} />Preparing external validation ...</div>
          <ModalControls>
            <ClearTextButton onClick={() => {
              setPrepareExternalValidation(false);
            }}>Cancel</ClearTextButton>
          </ModalControls>
        </FullScreenModal>
      )
    }
  }

  const renderErrorModal = () => {
    const genericErrorMessage = 'Oops...';
    if (saveError) {
      return (
        <FullScreenModal>
          <ModalText>{genericErrorMessage}</ModalText>
          <ModalSmallText>{saveError.toString()}</ModalSmallText>
          <ModalControls>
            <ClearTextButton onClick={() => {
              setSaveError(undefined);
            }}>Ok</ClearTextButton>
          </ModalControls>
        </FullScreenModal>
      )
    }
  }

  const awaitRedemptionUpdate = async () => {
    const maxDurationSeconds = 60;
    const retryIntervalSeconds = 3;
    const lastEventDate = redemption.lastEventDate;
    const redemptionRequestIsUpdated = (newRedemption: Redemption | undefined): newRedemption is Redemption => {
      return new Date(newRedemption?.lastEventDate ?? 0) > new Date(lastEventDate ?? 0)
    }
    setExternalValidationIsProcessed(false);
    await new RetryService({ maxDurationSeconds, retryIntervalSeconds }).retryUntil(redemptionRequestStore.get.bind(redemptionRequestStore), [id], redemptionRequestIsUpdated).then((newRedemption) => {
      setExternalValidationIsProcessed(true);
      setRedemption(newRedemption);
    }).catch(() => {
      setExternalValidationProcessing(false);
      setSaveError(`External validation not yet processed after ${maxDurationSeconds} seconds. Please be patient for a while, it might still be processed later.`);
    });
  }

  const sendExternalValidation = async () => {
    setExternalValidationConfirmation(false);
    setIsSendingExternalValidation(true);
    setExternalValidationProcessing(true);
    const response = await redemptionRequestStore.sendExternalValidation({
      redemptionId: redemption.id,
      campaignType: 'receipt',
      userId: localStorage.getItem('usertoken') ?? 'unknown',
      validationResult: RedemptionExternalValidationResult.SUCCESS,
      amountInCents: externalValidationAmountInCents!,
    });
    setIsSendingExternalValidation(false);
    if ('error' in response) {
      setSaveError(response.error);
      setExternalValidationProcessing(false);
    } else if ('errorCode' in response) {
      setSaveError(response.errorCode);
      setExternalValidationProcessing(false);
    } else {
      await awaitRedemptionUpdate();
    }
  }

  return (
    <>
      <div className={"page"} style={{ position: 'relative' }}>
        <Row>
          <BreadCrumbNavigation crumbs={[{ label: 'Deals & Tomra requests' }, { link: '/redemption-requests', label: 'requests' }]} />
        </Row>
        {isLoading ? (<div><Spinner animation={"border"} size={"sm"} />Loading redemption request...</div>) : (
          <>
            <Header2>Redemption</Header2>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: '16px' }}>
              <Section wrap="wrap">
                <div className="title">ID</div>
                <div className="description">{redemption.id}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Status</div>
                <div className="description">{redemption.status}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Campaign</div>
                <div className="description">{redemption.campaignId}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Amount in Cents:</div>
                <div className="description">{redemption.amountInCents}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Payout Id:</div>
                <div className="description">{redemption.payoutCommandId}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Created date:</div>
                <div className="description">{redemption.created}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Last event date:</div>
                <div className="description">{redemption.lastEventDate}</div>
              </Section>
              <Section wrap="wrap">
                <div className="title">Receiver</div>
                <div className="description">{redemption.receiver?.name} <br /> {redemption.receiver?.telephone} <br /> {redemption.receiver?.iban}</div>
              </Section>
              {redemption.type === 'receipt' && redemption.status === 'REJECTED' && (<Section wrap="wrap">
                <div className="title">External validation</div>
                <div className="description">
                  <PrimaryButton onClick={() => setPrepareExternalValidation(true)}>APPROVE CLAIM</PrimaryButton>
                </div>
              </Section>)}
            </div>

            <Header3>Validations</Header3>
            <div style={{ display: 'grid', columnGap: '16px' }}>
              {buildValidationHtml(redemption)}
            </div>
          </>
        )}
      </div>
      {renderExternalValidationConfirmationModal()}
      {renderExternalValidationProcessingModal()}
      {renderErrorModal()}
      {renderExternalValidationInputModal()}
      {prepareExternalValidationModal()}
    </>
  )

});
