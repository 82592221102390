import { action, observable } from "mobx";
import { UnAuthorizedError } from "../services/advertising-api-service";
import { RootStore } from "./root-store";
import { Redemption } from "../types/redemption-requets";
import { RedemptionExternalValidationResult } from "../types/redemption-external-validation";

export class RedemptionRequestStore {
  @observable redemptionRequests: Redemption[] = [];
  @observable apiServiceError: string | undefined;
  @observable userIsAuthorized: boolean = true;

  constructor(private rootStore: RootStore) {}

  @action private readonly errorHandler = (error: Error) => {
    if (error instanceof UnAuthorizedError) {
      this.userIsAuthorized = false;
    } else {
      this.apiServiceError = error.message;
    }
    console.error("error encountered", error);
    return { error };
  };

  @action private set = (redemptionRequests: Redemption[]) =>
    (this.redemptionRequests = redemptionRequests);

  private search = (searchTerm: string, searchValue: string) =>
    this.rootStore.redemptionApiService
      .search(searchTerm, searchValue)
      .then((redemptions) => {
        this.set(redemptions);
      })
      .catch(this.errorHandler);

  async get(id: string): Promise<Redemption | undefined> {
    const result = await this.rootStore.redemptionApiService
      .get(id)
      .catch(this.errorHandler);
    return (result as { error: Error }).error !== undefined
      ? undefined
      : (result as unknown as Redemption);
  }

  async sendExternalValidation({
    redemptionId,
    campaignType,
    userId,
    validationResult,
    amountInCents,
  }: {
    redemptionId: string;
    userId: string;
    validationResult: RedemptionExternalValidationResult;
    campaignType: "receipt";
    amountInCents: number;
  }) {
    return this.rootStore.redemptionApiService
      .sendExternalValidation({
        redemptionId,
        campaignType,
        userId,
        validationResult,
        amountInCents,
      })
      .catch(this.errorHandler);
  }

  async getImageUrl(imagePath: string): Promise<string> {
    return this.rootStore.redemptionApiService.getImageUrl(imagePath);
  }

  async getValidationDetails(
    bucketName: string,
    fileName: string,
  ): Promise<string> {
    return await this.rootStore.redemptionApiService.getValidationDetails(
      bucketName,
      fileName,
    );
  }

  @action getRedemptionRequests = async (
    searchTerm: string,
    searchValue: string,
  ) => await this.search(searchTerm, searchValue);
}
