import {Column, Row} from "../components/global/global-components";
import React from "react";

export const NotFoundPage = () => {

    return (
        <div className="page">
            <h2>Niet gevonden</h2>
            <Row><Column>De pagina die je probeert te benaderen bestaat niet.</Column></Row>
        </div>
    )
}
