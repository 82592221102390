import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components";

interface BreadCrumbProps {
    label: string;
    link?: string;
}

interface BreadCrumbNavigationProps {
    crumbs: BreadCrumbProps[];
}

const BreadCrumbNavigationDiv = styled.div`
    display: inline-block;
    padding: 1em;
    background-color: #FFF;
    margin-bottom: 1em;
    background: #FFFFFF;
    border: 2px solid #F4F4F4;
    box-sizing: border-box;
    border-radius: 24px;

    a.bread-crumb-link, a.bread-crumb-link:hover, a.bread-crumb-link:visited {
        color: #40C4B8 !important;
        text-decoration: none;
  }

  a.bread-crumb-link:hover {
    text-decoration: underline;
  }
`;

export const BreadCrumb = ({ label, link }: BreadCrumbProps) => {
    return link !== undefined ?
        <span className="bread-crumb"><Link className="bread-crumb-link" to={link}>{label}</Link> </span> :
        <span className="bread-crumb">{label}</span>
}

export const BreadCrumbSeparator = styled.span`
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
`

const toCrumb = ({ label, link }: BreadCrumbProps, index: number, list: BreadCrumbProps[]) =>
    index < list.length - 1 ?
        <span key={index}><BreadCrumb label={label} link={link} /><BreadCrumbSeparator>&gt;</BreadCrumbSeparator></span> :
        <span key={index}><BreadCrumb label={label} link={link} /></span>

export const BreadCrumbNavigation = ({ crumbs }: BreadCrumbNavigationProps) => {
    return <BreadCrumbNavigationDiv>{
        crumbs.map(toCrumb)
    }</BreadCrumbNavigationDiv>
}
