import styled from "styled-components";
import {ReceiptCampaignMerchant} from "../../../../types/cashback-campaign";
import {SingleMerchantBadge} from "./single-merchant-badge";

const MerchantBox = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 8px;
    width: 40%;
    padding: 18px;
`;

const BoxTitle = styled.h2`
    font-size: 24px;
    margin-top: 0 !important;
    color: #000000;
`;

interface MerchantGridProps {
    gap: string;
}
const MerchantGrid = styled.div<MerchantGridProps>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-row-end: -1;
    grid-gap: ${props => (props.gap)};
    justify-items: center;
    width: 100%;
`;

interface InteractiveMerchantBoxProps {
    merchants: ReceiptCampaignMerchant[];
    title: string;
    merchantOnClickHandler: (optionToAddIndex: number) => void;
    onDropHandler?: (source: number, target: number) => void;
}
export const InteractiveMerchantBox = ({merchants, merchantOnClickHandler, title, onDropHandler}: InteractiveMerchantBoxProps) => {
    return (
        <MerchantBox>
            <BoxTitle>{title}</BoxTitle>
            <MerchantGrid gap="10px">
                {merchants.map(({ label, imageUrl }, index) => (
                        <SingleMerchantBadge key={label} index={index} imageUrl={imageUrl.large} onClick={() => merchantOnClickHandler(index)} onDropHandler={onDropHandler} />
                    ))}
            </MerchantGrid>
        </MerchantBox>
    )
}