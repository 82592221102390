import {BreadCrumbNavigation} from "../components";
import {Row} from "../components/global/global-components";
import React from "react";
import {CreateCashbackForm} from "../components/cashback-campaign/create-cashback-form";
import {observer} from "mobx-react-lite";

export const NewCashbackPage = observer(() => {

    return (
        <div className={"page"} style={{position: 'relative'}}>
            <Row>
                <BreadCrumbNavigation crumbs={[{ label: 'Campagnes' }, { link: '/cashback-campaign-support', label: 'Cashbacks' }, { label: 'Nieuwe cashback campagne' }]} />
            </Row>

            <CreateCashbackForm />
        </div>
    )
});
