
import Joi from 'joi';
import {
    activeSchema,
    amountOfCardsSchema,
    amountOfPayoutsSchema,
    brandNameSchema,
    campaignNameSchema,
    cardDescriptionSchema,
    cashbackAmountSchema,
    cashbackPercentageSchema,
    colorSchema,
    dateSchema,
    faqButtonTextSchema,
    faqDescriptionSchema,
    faqUrlSchema,
    hasVariableCashbackAmountSchema,
    ibanSchema,
    idSchema,
    nextButtonSchema,
    organisationIdSchema,
    productBarcodesSchema,
    receiptAmount,
    receiptTextSchema,
    remittanceTestSchema,
    shareTitleSchema,
    suspenseAccountCodeSchema,
    termsButtonSchema,
    termsDescriptionSchema,
    termsUrlSchema,
    thresholdAmountSchema,
    titleSchema,
    tokensPerPersonSchema,
    tokensPerPersonTimeSpanSchema,
    tokenTTLSchema,
    tokenValueSchema,
    totalPayoutAmountSchema,
    typeSchema
} from "./campaign-fields";

export const languageDictionary = Joi.object({
    nl: Joi.string().required(),
    en: Joi.string().required(),
})

export const imageUrl = Joi.object({
    small: Joi.string().required(), medium: Joi.string().required(), large: Joi.string().required()
})

export const campaignInstructions = Joi.object({
    title: languageDictionary.required(),
    items: Joi.array().items(languageDictionary).min(1).max(5).required(),
});

const campaignProducts = Joi.object({
    title: languageDictionary.required(),
    items: Joi.array().items(languageDictionary).min(0).max(5).required(),
})

export const merchantImage = Joi.object({
    imageUrl: imageUrl.required(),
    label: Joi.string().required().min(1),
})

export const merchants = Joi.object({
    title: languageDictionary.required(),
    images: Joi.array().items(merchantImage).required(),
});

const draftCampaignMetaSchema = Joi.object({
    id: idSchema.optional(),
    title: titleSchema.optional(),
    type: typeSchema.required(),
    brandName: brandNameSchema.required(),
    campaignName: campaignNameSchema.required(),
    active: activeSchema.optional(),
    remittanceText: remittanceTestSchema,
    senderIban: ibanSchema.optional(),
    suspenseAccountCode: suspenseAccountCodeSchema.optional(),
    billingIban: ibanSchema.optional(),
    /**
     * The organisationId specifies which organisation owns the campaign.
     * Portal-Users of that organisation can view the campaign (and possibly edit
     * some values) and see reports about it. The Tikkie Api also has functionality
     * that is provided based on the organisationId of the Tikkie Api caller (e.g.
     * to create new tokens for token campaigns).
     * If left empty, the campaign is not accessible from the portal, and from the
     * Tikkie API.
     */
    organisationId: organisationIdSchema.optional(),
    startDate: dateSchema.required(),
    endDate: dateSchema.required(),
    startViewDate: dateSchema.optional(),
    endViewDate: dateSchema.when('startViewDate', { is: Joi.string().min(1).required(), then: Joi.required(), otherwise: Joi.optional() }),
    /**
     * When set to true, payouts will be skipped for the campaign.
     * This can be used to have campaigns that client can use to test the complete
     * flow, without actually doing payouts.
     * This property MUST NEVER be changed for an existing campaign. So once created
     * a campaign is either a test or real campaign. If you need to change that, create
     * a new campaign instead.
     */
    test: Joi.boolean().default(false).optional(),
    /**
     * When set to true, processCashback will force a REJECT status on the cashback.
     * So if the campaign is not over threshold, your cashback will still be rejected.
     * This is only for testing purposes, and will make all cashbacks for the campaign
     * REJECT during processing as long as this option is set.
     */
    testReject: Joi.boolean().optional(),
    reconTxStoredEndDate: Joi.string().optional(),
    lastPayoutCommandId: Joi.string().optional(),
    advertisementId: Joi.string().optional(),
    dealsAdvertisementId: Joi.string().optional(),
    thresholdAmount: thresholdAmountSchema.optional(),
    thresholdAlarmAmount: thresholdAmountSchema.optional(),
});

const draftReceiptCampaignSchema = Joi.object({
    maximumLevenshteinDistance: Joi.number().optional(),
    receiptText: receiptTextSchema.optional(),
    receiptMinPrice: receiptAmount.optional(),
    receiptMaxPrice: receiptAmount.optional(),
    cashbackAmount: cashbackAmountSchema.optional(),
    hasVariableCashbackAmount: hasVariableCashbackAmountSchema.optional(),
    cashbackPercentage: cashbackPercentageSchema.optional(),
    merchants: merchants.optional(),
    instructions: campaignInstructions.optional(),
    products: campaignProducts.optional(),
});

const draftBarcodeCampaignSchema = Joi.object({
    productBarcodes: productBarcodesSchema.optional(),
    cashbackAmount: cashbackAmountSchema,
});

const draftTokenCampaignSchema = Joi.object({
    tokenMinValue: tokenValueSchema.optional(),
    tokenMaxValue: tokenValueSchema.optional(),
    tokenTtlMilliseconds: tokenTTLSchema.optional(),
    maxTokensPerPerson: tokensPerPersonSchema.optional(),
    maxTokensPerPersonTimeSpan: tokensPerPersonTimeSpanSchema.optional(),
});

const draftCampaignContentSchema = Joi.object({
    imageUrl: imageUrl.optional(),
    cardImageUrl: imageUrl.optional(),
    productImage: imageUrl.optional(),
    productImageLanding: imageUrl.optional(),
    cardDescription: cardDescriptionSchema.optional(),
    shareTitle: shareTitleSchema.optional(),
    termsDescription: termsDescriptionSchema.optional(),
    termsButtonText: termsButtonSchema.optional(),
    termsUrl: termsUrlSchema.optional(),
    faqUrl: faqUrlSchema.optional(),
    faqButtonText: faqButtonTextSchema.optional(),
    faqDescription: faqDescriptionSchema.optional(),

    cardColorHex: colorSchema.optional(),
    cardColorCircleHex: colorSchema.optional(),
    textColorHex: colorSchema.optional(),
    cardButtonText: languageDictionary.optional(),
    nextButtonText: nextButtonSchema.optional(),

    amountText: languageDictionary.optional(),
    amountOfCards: amountOfCardsSchema.optional(),
    amountOfPayouts: amountOfPayoutsSchema.optional(),
    totalPayoutAmount: totalPayoutAmountSchema.optional(),
});

export const draftCampaignSchema = draftCampaignMetaSchema
    .when(Joi.object({ type: Joi.string().valid('receipt') }).unknown(), { then: draftReceiptCampaignSchema })
    .when(Joi.object({ type: Joi.string().valid('token') }).unknown(), { then: draftTokenCampaignSchema })
    .when(Joi.object({ type: Joi.string().valid('barcode') }).unknown(), { then: draftBarcodeCampaignSchema })
    .concat(draftCampaignContentSchema);

