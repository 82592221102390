import React from "react";
import styled from "styled-components";

const CenteredSectionContent = styled.div`
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &.extra-margin-top {
    margin-top: 120px;
  }
`;

const CenteredSectionWrapper = styled.div`
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding-right: 18px;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 32px;
  margin-bottom: 16px !important;
  border-radius: 8px;
  position: relative;

`;


export const CenteredSection: React.FC<{}> = (props) => <CenteredSectionWrapper><CenteredSectionContent>{props.children}</CenteredSectionContent></CenteredSectionWrapper>